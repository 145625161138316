import {
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import { IntlMessages } from "react-platform-components/dist/Translations";
import StripeComponent from "react-platform-purchase/dist/Purchase/Stripe";
import { Elements } from "react-stripe-elements";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import classNames from "classnames";
import { UPDATE_PAYMENT_METHOD } from "../ProfileActions";

const useStyles = makeStyles((theme) =>({  
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
    alignItems: "center",
    [theme.breakpoints.down("md")]: { textAlign: "center" },
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  marged: {
    margin: "10px 10px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: { textAlign: "center" },
  },
}))

function UpdateStripePaymentMethod() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripeFormRef = React.createRef();
  const [open, setOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const stripeOptions = useSelector(
    (state) => state?.auth?.user?.stripe_extension
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onPayment = (token) => {
    setInProgress(true);
    dispatch({
      type: UPDATE_PAYMENT_METHOD,
      model: { payment_method_token: token.id },
      onSuccess: () => handleClose(),
      onFailure: () => setInProgress(false),
    });
  };

  return (
    <>
      {stripeOptions?.default_payment_method !== null && (
        <Paper className={classNames("m-4 md:m-8", classes.cardShadow)}>
            <Card>
              {stripeOptions?.default_payment_method.type === "card" && (
                <CardContent>
                  <Typography variant="h5" component="h2">
                    <IntlMessages
                      id="Your current payment card:"
                      defaultMessage="Your current payment card:"
                    />
                  </Typography>
                  <div className={classes.marged}>
                    <Typography className={classes.pos}>
                      <IntlMessages id="Brand: " defaultMessage="Brand: " />{" "}
                      {stripeOptions?.default_payment_method?.brand}
                    </Typography>
                    <Typography className={classes.pos}>
                      <IntlMessages
                        id="Expiration month: "
                        defaultMessage="Expiration month: "
                      />{" "}
                      {stripeOptions?.default_payment_method?.expiration_month}
                    </Typography>
                    <Typography className={classes.pos}>
                      <IntlMessages
                        id="Expiration year: "
                        defaultMessage="Expiration year: "
                      />{" "}
                      {stripeOptions?.default_payment_method?.expiration_year}
                    </Typography>
                    <Typography className={classes.pos}>
                      <IntlMessages id="Number: " defaultMessage="Number: " />{" "}
                      {stripeOptions?.default_payment_method?.number}
                    </Typography>
                  </div>
                </CardContent>
              )}
              {stripeOptions?.default_payment_method.type === "sepa_debit" && (
                <CardContent>
                  <Typography variant="h5" component="h2">
                    <IntlMessages
                      id="Your current bank account:"
                      defaultMessage="Your current bank account:"
                    />
                  </Typography>
                  <div className={classes.marged}>
                    <Typography className={classes.pos}>
                      <IntlMessages id="IBAN: " defaultMessage="IBAN: " />{" "}
                      {stripeOptions?.default_payment_method?.iban}
                    </Typography>
                  </div>
                </CardContent>
              )}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                className={classes.marged}
              >
                <IntlMessages id="Update" defaultMessage="Update" />
              </Button>
            </Card>
        </Paper>
      )}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <IntlMessages id="Update payment" defaultMessage="Update payment" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <IntlMessages
                id="Update your payment method"
                defaultMessage="Update your payment method"
              />
            </DialogContentText>
            <Elements>
              <StripeComponent
                handlePayment={onPayment}
                formRef={stripeFormRef}
              />
            </Elements>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={inProgress}>
              <IntlMessages id="Cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              onClick={() => stripeFormRef.current?.handleSubmit()}
              color="primary"
              disabled={inProgress}
            >
              <IntlMessages id="Submit" defaultMessage="Submit" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
export default UpdateStripePaymentMethod;
