import React, {useEffect} from "react";
import { usePrevious } from "react-use";
import _ from "lodash";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid, TextField, Button, Typography } from "@material-ui/core"
import { injectIntl } from "react-intl";
import { SALABLE_POINT_UUID } from "../../configs";
import PromoCode from "../SecondaryShoppingCart/components/PromoCode";
import { PaymentPoint } from './Components/PaymentPoint';
import { PacketList } from "./Components/PacketList"

const ShoppingCartOfPoint = (props) => {
  const dispatch = useDispatch();
  const [point, setPoint] = React.useState(null);
  const [selectedPoint, setSelectedPoint] = React.useState();
  const previousSelectedPoint = usePrevious(selectedPoint);

  const {
    fetchCartInProgress,
    cart,
    inProgress,
  } = useSelector(
    ({ secondaryShoppingCartReducer, purchasePointReducer }) => ({
      fetchCartInProgress: secondaryShoppingCartReducer.fetchCartInProgress,
      cart: secondaryShoppingCartReducer.cart,
      inProgress: purchasePointReducer.inProgress,
    })
  );

  const disabelCheckout = fetchCartInProgress || !selectedPoint || inProgress;

  const cartItemPoint = cart?.cart_items?.filter(
    (item) => item.salable_item_uuid === SALABLE_POINT_UUID
  )

  const handlePointFieldChange = (event) => {
    setPoint(event.target.value);
  };

  const addToCart = (quantity) => {
    dispatch({
      type: "ADD_TO_SECONDARY_CART",
      model: {
        salable_item_class: "SalablePoint",
        salable_item_uuid: SALABLE_POINT_UUID,
        quantity,
      },
    });
  };

  const updateCartItem = (item, quantity) => {
    dispatch({
      type: "UPDATE_ITEM_OF_SECONDARY_CARTITEM_START",
      id: item,
      model: {
        salable_item_class: "SalablePoint",
        salable_item_uuid: SALABLE_POINT_UUID,
        quantity,
      },
    });
  };

  useEffect(() => {
    if (selectedPoint) {
      if(_.isEmpty(cartItemPoint)){
        // we use this for delete old cart_items and add new cart_items
        dispatch({
          type: "DELETE_SECONDARY_CART_ITEMS",
          ids: cart.cart_items.map((item) => item.id),
          onConfirm: () => {
            addToCart(selectedPoint);
          }
        })
      } else if (!_.isUndefined(previousSelectedPoint)) {
      const id = cartItemPoint[0].id;
      updateCartItem(id, selectedPoint);
    }
   }
  }, [selectedPoint]);

  useEffect(() => {

    if (cartItemPoint && !selectedPoint) {
      const points = cartItemPoint[0]?.quantity;
      setSelectedPoint(points);
      setPoint(points);
    }
  }, [cart?.cart_items]);

  return (
    <div className="mx-4">
        <Typography variant="h2" className="pb-8 text-center">
          <IntlMessages
            id="à partir de cette page vous avez la possibilités d’acheter des roadpoins en détaille et en pack"
            defaultMes1sage="à partir de cette page vous avez la possibilités d’acheter des roadpoins en détaille et en pack"
          />
        </Typography>
        <Grid item={true} xs={12} md={12}>
          <Paper elevation={1} className="p-4 mx-2" style={{ marginBottom: "50px" }}>
          <Typography color='primary' variant='h5' className='text-center pb-8'>
            <IntlMessages id="achat en détaille" defaultMes1sage="achat en détaille" />
          </Typography>
            <Grid container={true} spacing={2}>
              <Grid
                container={true}
                item={true}
                xs={12}
                spacing={1}
                alignItems="center"
              >
                <Grid item={true} xs={8}>
                  <TextField
                    label={<IntlMessages id="Points" defaultMessage="Points" />}
                    placeholder={props.intl.formatMessage({
                      id: "Example: 10",
                      defaultMessage: "Example: 10",
                    })}
                    name="points"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={point}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth={true}
                    onChange={handlePointFieldChange}
                    disabled={selectedPoint}
                  />
                </Grid>
                {!selectedPoint && (
                  <Grid item={true} container={true} xs={4} justify="flex-end">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {setSelectedPoint(point)}}
                    >
                      <IntlMessages id="Get Price" defaultMessage="Get Price" />
                    </Button>
                  </Grid>
                )}
                {selectedPoint && (
                  <Grid item={true} container={true} xs={4} justify="flex-end">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {setSelectedPoint(null)}}
                    >
                      <IntlMessages id="Update" defaultMessage="Update" />
                    </Button>
                  </Grid>
                )}
              </Grid>
              {cart && (
                <Grid container item xs={12}>
                  <Grid item xs={8}>
                    <Typography variant="overline">
                      <IntlMessages
                        id="Montant à payer /purchase point"
                      />
                    </Typography>
                  </Grid>
                  {!disabelCheckout && 
                  <Grid container item xs={4} justify="flex-end">
                    <strong>{cart.total_price_with_coupon} <IntlMessages id="Euro" defaultMessage="Euro" /></strong>
                  </Grid>
                  }
                </Grid>
              )}
              {!_.isEmpty(cartItemPoint) && (
                <Grid container item xs={12}>
                  <PromoCode />
                </Grid>
              )}
              <PaymentPoint disabelCheckout={disabelCheckout} selectedPoint={selectedPoint} {...props}/>
            </Grid>
          </Paper>
          <Typography color='primary' variant='h5' className='text-center'>
                <IntlMessages id='achat par pack' defaultMes1sage='achat par pack' />
          </Typography>
          <PacketList disabelCheckout={disabelCheckout} selectedPoint={selectedPoint} setSelectedPoint={setSelectedPoint} {...props}/>
        </Grid>
    </div>
  );
};

export default injectIntl(ShoppingCartOfPoint);
