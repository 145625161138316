import React from "react";
import { withStyles } from "@material-ui/styles";
import _ from "lodash";
import { injectToBaseContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { styles } from "react-platform-product/dist/Product/ProductSyles";
import { connect } from "react-redux";
import CreateProductSalable from "./dialogs/CreateProductSalable";
import { ImageButtons } from "./components/ImageButtons";
import { TopChips } from "./components/TopChips";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { defaultConfig } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableContainer';

const filters = {ne_status: "archived", _embed: "tags", _fields: [
  "id",
  "category",
  "description",
  "name",
  "status",
  "price",
  "product_options",
  "images",
  "translated_status",
  "tags",
  "immatriculation_certificates",
  "insurance_certificates",
  "caution",
  "right",
  "has_availabilities"
]}

/**
 * We create out class by using injectToBaseContainer
 * this will return a React Component that is wrapped with the HOCs
 * inside flowRight, and hass all the props that are passed as options
 */
export default injectToBaseContainer(
  _.flowRight(
    withStyles(styles),
    connect((state) => {
      return {
        pagination: state.productSalableReducer.pagination,
      };
    })
  ),
  {
    ...defaultConfig,
    createComponent: CreateProductSalable,
    headerTitle: <IntlMessages id="Salable Products" defaultMessage="Salable Products" />,
    headerIcon: "directions_bus",
    filters,
    extraProps: {
      ...defaultConfig.extraProps,
      filters,
      imageButtonsComponent: ImageButtons,
      topChipsComponent: TopChips

    },
  }
);
