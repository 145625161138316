import Dashboard from "./Dashboard";

export const DashboardConfig = {
  auth: ["user"],
  routes: [
    {
      component: Dashboard,
      path: "/dashboard",
    },
  ],
  settings: {},
};
