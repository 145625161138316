import React from "react"
import {
    Grid,
    makeStyles,
    Typography,
    Paper,
    Icon,
    IconButton,
    useMediaQuery,
    Divider,
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { IntlMessages } from "react-platform-components/dist/Translations"
import { useCountries } from "react-platform-components/dist/hooks/useCountries"
import _ from "lodash"
import { withConfirmationDialog } from "react-platform-components/dist/components/ConfrimationDialog/ConfrimationDialog"
import { useDispatch } from "react-redux"

const useStyles = makeStyles((theme) => ({
    zoneCard: {
        padding: 16,
        display: "flex",
        border: "1px solid #d8dadf",
        borderRadius: 10,
        width: "100%",
        margin: 8,
        alignItems: "center",
        [theme.breakpoints.down("md")]: { textAlign: "center" },
        "&:hover": {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            backgroundColor: "#F5F5F5",
        },
    },
    zoneName: {
        lineHeight: "25px",
        fontWeight: "inherit",
        color: "#696969",
        marginBottom: "8px",
    },
    zoneContent: { [theme.breakpoints.down("sm")]: { width: "90%" }, justifyContent: "space-around" },
    ContentSkeleton: { width: "100%" },
    deleteIcon: {
        cursor: "pointer",
    },
}))

export const WarningZoneList = (props) => {
    const { countires } = useCountries()
    const classes = useStyles()
    const { onConfirm, inProgress, products: warningZones } = props
    const dispatch = useDispatch()
    const isMd = useMediaQuery("(max-width: 810px)", { noSsr: true })

    const handleDeleteWarinigZone = (zone) => {
        onConfirm(
            () =>
                dispatch({
                    type: "DELETE_WARNING_ZONE",
                    id: zone.id,
                    onSuccess: () => {
                        dispatch({
                            type: "HANDLE_SUCCESS_MESSAGE",
                            message: <IntlMessages id="Votre zone d'alert a bien été supprimer" />,
                        })
                    },
                }),
            {
                title: <IntlMessages id="Êtes-vous sûr de supprimer ce zone d'alerte ?" />,
            }
        )
    }

    return _.isEmpty(warningZones) ? (
        inProgress ? (
            <Grid container alignItems='center'>
                {_.map([1, 2, 3, 4, 5], (index) => (
                    <WarningZoneSkeleton key={index} />
                ))}
            </Grid>
        ) : (
            <div className='text-center justify-center mt-16'>
                <p className='text-2xl bg-transparent'>
                    <IntlMessages id="vous avez aucune zone d'alerte" />
                </p>
            </div>
        )
    ) : (
        warningZones.map((zone) => (
            <Grid container sm={12} md={12}>
                <Paper elevation={2} variant='elevation' className={classes.zoneCard}>
                    <Grid container={!isMd} className={classes.zoneContent}>
                        <Grid sm={12} md={1}>
                            <Typography variant='body1'>{zone?.name}</Typography>
                        </Grid>
                        <Divider />
                        <Divider orientation='vertical' flexItem />
                        <Grid sm={12} md={4}>
                            <Typography variant='body1' className={classes.zoneName}>
                                <IntlMessages id='Categories' />
                            </Typography>
                            <Typography variant='body1'>
                                {!_.isEmpty(zone?.categories) ? (
                                    zone?.categories.map(({ name }) => name).join(" / ")
                                ) : (
                                    <IntlMessages id='Tout' />
                                )}
                            </Typography>
                        </Grid>
                        <Divider />
                        <Divider orientation='vertical' flexItem />
                        <Grid sm={12} md={4}>
                            <Typography variant='body1' className={classes.zoneName}>
                                <IntlMessages id='Countries' />
                            </Typography>
                            <Typography variant='body1'>
                                {zone?.countries
                                    .map(
                                        (countryCode) =>
                                            countires.find(
                                                ({ code }) =>
                                                    code === countryCode
                                            )?.name || countryCode,
                                    )
                                    .join(' / ')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className={classes.deleteIcon}>
                        <IconButton onClick={() => handleDeleteWarinigZone(zone)}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Grid>
                </Paper>
            </Grid>
        ))
    )
}

const WarningZoneSkeleton = () => {
    const classes = useStyles()
    return (
        <Paper elevation={2} variant='elevation' className={classes.zoneCard}>
            <Grid className={classes.ContentSkeleton}>
                <Typography variant='h3' className="pb-2">
                    <Skeleton />
                </Typography>
                <Typography variant='h3'>
                    <Skeleton />
                </Typography>
            </Grid>
        </Paper>
    )
}

export default withConfirmationDialog(WarningZoneList)
