import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Button, Card, CardContent, Grid } from "@material-ui/core"
import { IntlMessages } from "react-platform-components/dist/Translations"

export const CardsSkeleton = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
        <Grid lg={3} md={6} sm={6} xs={12} className='flex p-2' key={index}>
            <Card className='w-full relative'>
                <CardContent>
                    <div className='flex justify-center items-center'>
                        <Skeleton width={250} />
                    </div>

                    <div className='flex justify-center items-center'>
                        <Skeleton width={250} height={200} />
                    </div>

                    <div className='flex justify-center items-center pb-2 pr-10 absolute bottom-0 w-full'>
                        <Button className='w-full' variant='outlined' color='secondary' size='small'>
                            <IntlMessages id='Buy' defaultMessage='Buy' />
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    ))
}
