import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import BaseForm from "react-platform-components/dist/BaseComponents/BaseForm";
import { injectToForm } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import {
  Checkbox,
  TextField,
  PhoneNumber,
} from "react-platform-components/dist/form-fields";
import {
  accept,
  email,
  equal,
  min,
  max,
  required,
  validators,
} from "react-platform-components/dist/form-validators";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { Link } from "react-router-dom";
import {
  SHOW_USER_CONDITIONS,
  BASE_URL,
  INLINE_STORE_IN_REGISTER,
  CUSTOM_REGISTER,
  DISPLAY_SAFARI_LOREM,
} from "react-platform-user/dist/config";
import qs from "qs";

const params = qs.parse(window.location.search.replace("?", ""));
let SocialAuthButtons = null;

try {
  SocialAuthButtons =
    require("react-platform-social/dist/SocialAuth/SocialAuthButtons").default;
} catch (error) {
  console.warn("SocialAuthButtons not found");
}

// Register form fields
const getFormExtraFields = (props, embeded) => {
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );
  const formExtraFields = [
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {DISPLAY_SAFARI_LOREM && isSafari && (
            <Typography color="error">
              <IntlMessages
                id="Oups il semblerait que vous utilisiez le navigateur Safari. Notre plateforme Swaptheroad est optimisée pour les navigateurs Chrome et Firefox"
                defaultMessage="Oups il semblerait que vous utilisiez le navigateur Safari. Notre plateforme Swaptheroad est optimisée pour les navigateurs Chrome et Firefox"
              />
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="fname"
            name="first_name"
            variant="outlined"
            required
            fullWidth
            id="firstName"
            label="First Name"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="last_name"
            autoComplete="lname"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            validate={validators([required(), email()])}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumber
            placeholder="Téléphone *"
            fullWidth
            name="phone"
            country="fr"
            disableAreaCodes={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="referral_code"
            label="Code d'affiliation"
            name="referral_code"
            validate={validators([
              max(
                20,
                "Le code d'affiliation est trop long, 20 caractères maximum"
              ),
            ])}
          />
        </Grid>
      </Grid>
    </>,
  ];

  formExtraFields.push(
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          validate={validators([
            required(),
            min(6, "Le mot de passe doit contenir au moins 6 caractères"),
          ])}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          name="confirm_password"
          label="Confirm Password"
          type="password"
          id="confirm_password"
          validate={validators([
            required(),
            min(6, "Le mot de passe doit contenir au moins 6 caractères"),
            equal(
              props.values.password,
              "Les mots de passe doivent être identiques"
            ),
          ])}
        />
      </Grid>
      <Grid item xs={SHOW_USER_CONDITIONS ? 10 : 12}>
        <Checkbox
          name="accept"
          required
          label="J'accept les conditions d'utilisations"
          validate={validators([required(), accept()])}
        />
      </Grid>
      {SHOW_USER_CONDITIONS && (
        <Grid container item xs={2} alignItems="center">
          <a
            target="_blank"
            href={`${BASE_URL}${props.intl.formatMessage({
              id: "cgu_url",
              defaultMessage: "cgu_url",
            })}`}
          >
            <IntlMessages id="CGU" />
          </a>
        </Grid>
      )}
    </Grid>
  );

  formExtraFields.push(
    <Button
      onClick={props.submitForm}
      disabled={props.inProgress}
      fullWidth
      variant="contained"
      color="primary"
      className={props.classes.submit}
    >
      <IntlMessages id="Sign Up" defaultMessage="Sign Up" />
    </Button>
  );

  INLINE_STORE_IN_REGISTER &&
    !CUSTOM_REGISTER &&
    formExtraFields.push(
      <Link className="no-underline my-4" to="/custom-register" variant="body2">
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          className={props.classes.submit}
        >
          <IntlMessages id="Custom Register" />
        </Button>
      </Link>
    );

  {
    SocialAuthButtons && formExtraFields.push(<SocialAuthButtons {...props} />);
  }

  formExtraFields.push(
    <Grid container justify="flex-end">
      <Grid item>
        <Link to="/login" variant="body2">
          <IntlMessages
            id="Already have an account? Sign in"
            defaultMessage="Already have an account? Sign in"
          />
        </Link>
      </Grid>
    </Grid>
  );

  return formExtraFields;
};
// Register form
export default injectToForm(BaseForm, {
  formExtraFields: (props) => getFormExtraFields(props),
  handleInitialValues: () => ({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    referral_code: params["referral-code"],
    password: "",
    accept: "",
    confirm_password: "",
  }),
});
