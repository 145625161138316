import React from "react";
import {
  Paper,
  Grid,
  Avatar,
  Typography,
  IconButton,
  Icon,
} from "@material-ui/core";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
// import EditProfile from 'react-platform-user/dist/Profile/dialogs/EditProfile';
import EditProfile from "../dialogs/EditProfile";
import { joinPaths } from "react-platform-components/dist/helpers";
import { BASE_URL } from "react-platform-user/dist/config";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import PersonIcon from "@material-ui/icons/Person";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import TodayIcon from "@material-ui/icons/Today";
import LanguageIcon from "@material-ui/icons/Language";
import GroupIcon from "@material-ui/icons/Group";
import HowToRegIcon from "@material-ui/icons/Face";

const styles = (theme) => ({
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
    width: "100%",
  },
  title: {
    paddingBottom: 24,
  },
  itemNameContainer: {
    display: "flex",
    marginLeft: 10,
  },
  itemNameAvatar: {
    backgroundColor: theme.palette.primary.main,
    height: 35,
    width: 35,
  },
  itemNameText: {
    marginLeft: 10,
  },
  itemValueText: {
    marginLeft: 60,
  },
  bigContainerMargin: {
    marginBottom: "80px",
  },
});

class UserInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editDialogOpen: false,
    };
  }

  closeEditDialog = () => {
    this.setState({
      editDialogOpen: false,
    });
  };

  openEditDialog = () => {
    this.setState({
      editDialogOpen: true,
    });
  };

  render() {
    const { user, classes } = this.props;
    const { editDialogOpen } = this.state;

    return (
      <Grid container xs={12} md={12} className={classes.bigContainerMargin}>
        <Paper className={classNames("m-4 md:m-8", classes.cardShadow)}>
          <Grid container xs={12} md={12}>
            <Grid
              container
              item
              xs={12}
              lg={4}
              direction="column"
              alignItems="center"
            >
              <Grid item>
                <Avatar
                  src={
                    user.avatar && user.avatar.original
                      ? joinPaths(BASE_URL, user.avatar.original)
                      : null
                  }
                  style={{ height: 150, width: 150, textAlign: "center" }}
                  className="m-16 mb-4"
                >
                  <IntlMessages
                    id="Photo de profil"
                    defaultMessage="Photo de profil"
                  />
                </Avatar>
              </Grid>
              <Grid item>
                <IconButton onClick={this.openEditDialog}>
                  <Icon>edit</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              lg={4}
              className="p-4"
              direction="row"
            >
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="space-between"
                className="py-2"
              >
                <Grid
                  item
                  alignItems="center"
                  className={classes.itemNameContainer}
                >
                  <Avatar className={classes.itemNameAvatar}>
                    <HowToRegIcon />
                  </Avatar>
                  <Typography variant="body1" className={classes.itemNameText}>
                    <IntlMessages id="Pseudo" defaultMessage="Pseudo" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.itemValueText} variant="body1">
                    {user.pseudo || (
                      <IntlMessages id="N/A" defaultMessage="N/A" />
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="space-between"
                className="py-2"
              >
                <Grid
                  item
                  alignItems="center"
                  className={classes.itemNameContainer}
                >
                  <Avatar className={classes.itemNameAvatar}>
                    <PersonIcon />
                  </Avatar>
                  <Typography variant="body1" className={classes.itemNameText}>
                    <IntlMessages id="Nom" defaultMessage="Nom" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.itemValueText} variant="body1">
                    {user.last_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="space-between"
                className="py-2"
              >
                <Grid
                  item
                  alignItems="center"
                  className={classes.itemNameContainer}
                >
                  <Avatar className={classes.itemNameAvatar}>
                    <PersonIcon />
                  </Avatar>
                  <Typography variant="body1" className={classes.itemNameText}>
                    <IntlMessages id="Prénom" defaultMessage="Prénom" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.itemValueText} variant="body1">
                    {user.first_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="space-between"
                className="py-2"
              >
                <Grid
                  item
                  alignItems="center"
                  className={classes.itemNameContainer}
                >
                  <Avatar className={classes.itemNameAvatar}>
                    <MailIcon />
                  </Avatar>
                  <Typography variant="body1" className={classes.itemNameText}>
                    <IntlMessages id="Email" defaultMessage="Email" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.itemValueText} variant="body1">
                    {user.email || (
                      <IntlMessages id="N/A" defaultMessage="N/A" />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={6}
              lg={4}
              className="p-4 pr-6"
              direction="row"
            >
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="space-between"
                className="py-2"
              >
                <Grid
                  item
                  alignItems="center"
                  className={classes.itemNameContainer}
                >
                  <Avatar className={classes.itemNameAvatar}>
                    <PhoneIcon />
                  </Avatar>
                  <Typography variant="body1" className={classes.itemNameText}>
                    <IntlMessages id="Téléphone" defaultMessage="Téléphone" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.itemValueText} variant="body1">
                    {user.phone || (
                      <IntlMessages id="N/A" defaultMessage="N/A" />
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="space-between"
                className="py-2"
              >
                <Grid
                  item
                  alignItems="center"
                  className={classes.itemNameContainer}
                >
                  <Avatar className={classes.itemNameAvatar}>
                    <TodayIcon />
                  </Avatar>
                  <Typography variant="body1" className={classes.itemNameText}>
                    <IntlMessages
                      id="Date de naissance"
                      defaultMessage="Date de naissance"
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.itemValueText} variant="body1">
                    {user.date_of_birth || (
                      <IntlMessages id="N/A" defaultMessage="N/A" />
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="space-between"
                className="py-2"
              >
                <Grid
                  item
                  alignItems="center"
                  className={classes.itemNameContainer}
                >
                  <Avatar className={classes.itemNameAvatar}>
                    <GroupIcon />
                  </Avatar>
                  <Typography variant="body1" className={classes.itemNameText}>
                    <IntlMessages id="Situations" defaultMessage="Situations" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.itemValueText} variant="body1">
                    {user.situation?.label || (
                      <IntlMessages id="N/A" defaultMessage="N/A" />
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="space-between"
                className="py-2"
              >
                <Grid
                  item
                  alignItems="center"
                  className={classes.itemNameContainer}
                >
                  <Avatar className={classes.itemNameAvatar}>
                    <LanguageIcon />
                  </Avatar>
                  <Typography variant="body1" className={classes.itemNameText}>
                    <IntlMessages id="Langages" defaultMessage="Langages" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.itemValueText} variant="body1">
                    {(user.languages &&
                      user.languages
                        .map((language) => language.name)
                        .join(", ")) || (
                      <IntlMessages id="N/A" defaultMessage="N/A" />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={3}
              direction="column"
              alignItems="center"
            />

            <Grid container item xs={12} md={9} className="p-4">
              {user.description && (
                <Grid item xs={12} md={12} className="py-2 md:px-16">
                  <Typography variant="button">
                    <IntlMessages
                      id="Description"
                      defaultMessage="Description"
                    />
                  </Typography>
                  <Typography variant="body1" className="py-2">
                    {user.description}
                  </Typography>
                </Grid>
              )}
              {user.destinations && (
                <Grid item xs={12} md={12} className="py-4 md:px-16">
                  <Typography variant="button">
                    <IntlMessages
                      id="Destinations"
                      defaultMessage="Destinations"
                    />
                  </Typography>
                  <Typography variant="body1" className="py-2">
                    {user.destinations}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {editDialogOpen && (
            <EditProfile
              isEdit={true}
              open={editDialogOpen}
              close={this.closeEditDialog}
            />
          )}
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(
  connect((state) => {
    return {
      user: state.auth.user,
    };
  })(UserInfo)
);
