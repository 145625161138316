import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Avatar,
  Icon,
} from "@material-ui/core";
import {
  IntlMessages,
  injectIntl,
} from "react-platform-components/dist/Translations";
import { TextField } from "react-platform-components/dist/form-fields";
import { useFormik, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#3D7B6C",
    width: "32px",
    height: "32px",
  },
  icon: {
    fontSize: "1rem",
  },
  input: {
    width: "80px",
  },
}));

function Quantity({ initialQuantity, id, intl }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showQuantityField, setShowQuantityField] = useState(false);
  const { updateItemInProgress } = useSelector(
    (state) => state.secondaryShoppingCartReducer
  );

  const formik = useFormik({
    initialValues: {
      quantity: initialQuantity,
    },
    onSubmit: (values) => {
      dispatch({
        type: "UPDATE_ITEM_OF_SECONDARY_CARTITEM_START",
        id,
        model: values,
      });
      setShowQuantityField(false);
    },
  });

  const { values } = formik;

  return (
    <Grid container item xs={8} alignItems="center">
      <Typography variant="overline" display="block" className="mr-2">
        <IntlMessages id="Quantité:" defaultMessage="Quantité :" />{" "}
        {!showQuantityField && !updateItemInProgress ? initialQuantity : null}
      </Typography>
      {!showQuantityField && !updateItemInProgress ? (
        <IconButton
          className="ml-2"
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setShowQuantityField(!showQuantityField)}
        >
          <Avatar className={classes.avatar} variant="rounded">
            <Icon className={classes.icon}>edit</Icon>
          </Avatar>
        </IconButton>
      ) : (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container alignItems="center">
              <TextField
                type="number"
                name="quantity"
                label={intl.formatMessage({
                  id: "Quantity",
                  defaultMessage: "Quantity",
                })}
                size="small"
                variant="outlined"
                className={classes.input}
                value={values.quantity}
                onChange={formik.handleChange}
              />
              <IconButton
                type="submit"
                className="ml-2"
                size="small"
                variant="contained"
                color="primary"
                disabled={values.quantity < 1 ? true : false}
              >
                <Avatar className={classes.avatar} variant="rounded">
                  <Icon className={classes.icon}>save</Icon>
                </Avatar>
              </IconButton>
            </Grid>
          </form>
        </FormikProvider>
      )}
    </Grid>
  );
}

export default injectIntl(Quantity);
