import React from "react";
import { SellerPurchaseDetails as DefaultSellerPurchaseDetails } from "react-platform-purchase/dist/PurchaseOfSeller/SellerPurchaseDetails/SellerPurchaseDetails";
import { ReservationDetailsOfSeller } from "../../components/ReservationDetailsOfSeller";

export const SellerPurchaseDetails = () => {
  return (
    <DefaultSellerPurchaseDetails
      reservationDetails={ReservationDetailsOfSeller}
    />
  );
};
