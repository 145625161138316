import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import IntlMessages from "react-platform-components/dist/Translations/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useFormik, FormikProvider } from "formik";
import { useTitle } from "react-use";
import { TextField } from "react-platform-components/dist/form-fields/index";
import Alert from "@material-ui/lab/Alert";
import {
  required,
  validators,
  email,
} from "react-platform-components/dist/form-validators";
import axios from "axios";
import { BASE_URL } from "../../configs/index";
import { injectIntl } from "react-platform-components/dist/Translations";

const useStyle = makeStyles((theme) => ({
  marged: {
    margin: "0 5px",
    marginTop: "4rem",
  },
  padded: {
    padding: "25px 25px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  successMessageContainer: {
    textAlign: "center",
  },
  formContainer: {
    textAlign: "center",
    marginBottom: "50px",
  },
}));
const Dashboard = (props) => {
  useTitle(props.intl.formatMessage({ id: 'Dashboard page title', defaultMessage: 'Dashboard page title' }))
  const classes = useStyle();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => ({ user: auth.user }));
  const [sendingInvitationInProgress, setSendingInvitationInProgress] =
    useState(false);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);

  useEffect(() => {
    dispatch({ type: "FETCH_USER", params: { _embed: "wallets" } });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    onSubmit: async (values) => {
      try {
        setSendingInvitationInProgress(true);
        await axios.post(`${BASE_URL}send-invitation`, values);
        setDisplaySuccessMessage(true);
        // dispatch({
        // 	type: "HANDLE_SUCCESS_MESSAGE",
        // 	message: (
        // 		<IntlMessages
        // 			id="Lien de parrainage envoyé"
        // 			defaultMessage="Lien de parrainage envoyé"
        // 		/>
        // 	),
        // });
      } catch (error) {
        dispatch({ type: "HANDLE_ERROR", message: error });
      }
      setSendingInvitationInProgress(false);
    },
  });

  const handleResetForm = () => {
    setDisplaySuccessMessage(false);
    formik.resetForm();
  };

  return (
    <Grid container xs={12} md={12} spacing={1} className={classes.flex}>
      <Grid item xs={12} md={6}>
        <Grid
          container
          xs={12}
          md={12}
          alignItems="center"
          justify="center"
          className={classes.marged}
        >
          <Grid item xs={12} md={12}>
            <Paper variant="outlined" className={classes.padded}>
              <Typography variant="h3">
                <IntlMessages id="title_of_dashboard_referral_left" />
              </Typography>
              <Typography variant="subtitle1" className="pt-8">
                <IntlMessages id="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Recusandae alias nobis repudiandae cum eos officiis ipsam natus nulla quas porro, adipisci nisi doloremque quod quia deleniti repellat mollitia veritatis! Delectus!" />
              </Typography>
              <Typography variant="subtitle1" className="pt-8">
                <IntlMessages id=" dolor sit amet consectetur, adipisicing elit. Recusandae alias nobis repudiandae cum " />
              </Typography>
              <Typography variant="subtitle1" className="pt-8">
                <IntlMessages id=" dolor sit amet consectetur, elit. Recusandae alias nobis" />
              </Typography>
              {user?.wallets && (
                <Typography
                  className="pt-8"
                  variant="subtitle1"
                  style={{ fontSize: "20px" }}
                  gutterBottom={true}
                >
                  <IntlMessages id="Balance: " defaultMessage="Balance: " />
                  {!_.isEmpty(user.wallets)
                    ? `${_.get(
                        user.wallets[0],
                        "available_balance",
                        0
                      )} RoadPoints`
                    : 0}
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} md={12} container style={{ marginTop: "4rem" }}>
            <Paper variant="outlined" className={classes.padded}>
              <Typography variant="h3">
                <IntlMessages
                  id="title_of_dashboard_referral"
                  defaultMessage="title_of_dashboard_referral"
                />
              </Typography>
              <Typography variant="subtitle1" className="pt-8">
                <IntlMessages
                  id="long text in the dashrboard that is wrapping the refferal code long text in the dashrboard that is wrapping the refferal code long text in the dashrboard that is wrapping the refferal code long text in the dashrboard that is wrapping the refferal code "
                  defaultMessage="long text in the dashrboard that is wrapping the refferal code long text in the dashrboard that is wrapping the refferal code long text in the dashrboard that is wrapping the refferal code long text in the dashrboard that is wrapping the refferal code "
                />
              </Typography>
              <Typography variant="subtitle1" className="pt-8">
                <IntlMessages
                  id="Votre code de parrainage: "
                  defaultMessage="Votre code de parrainage: "
                />
                <b>STR-{user?.id}</b>
              </Typography>
              <Typography variant="subtitle1" className="pt-8">
                <IntlMessages
                  id="another long text in the dashrboard that is wrapping the reffral code but is below the reffral code"
                  defaultMessage="another long text in the dashrboard that is wrapping the reffral code but is below the reffral code"
                />
              </Typography>
              <br />
              <br />
              {!displaySuccessMessage ? (
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      spacing={2}
                      className={classes.formContainer}
                    >
                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          type="email"
                          label="Email"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          required
                          validate={validators([required(), email()])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          disabled={sendingInvitationInProgress}
                        >
                          {" "}
                          <IntlMessages id="Inviter" defaultMessage="Inviter" />
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </FormikProvider>
              ) : (
                <Alert icon={false} severity="success">
                  <Grid
                    container
                    xs={12}
                    md={12}
                    spacing={2}
                    className={classes.successMessageContainer}
                  >
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        <IntlMessages
                          id="L'invitation à"
                          defaultMessage="L'invitation à"
                        />{" "}
                        <b>{formik.values?.email}</b>{" "}
                        <IntlMessages
                          id="a été envoyée."
                          defaultMessage="a été envoyée."
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <a href="javascript:void(0)" onClick={handleResetForm}>
                        <IntlMessages
                          id="Envoyer une autre invitation"
                          defaultMessage="Envoyer une autre invitation"
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Alert>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default injectIntl(Dashboard);
