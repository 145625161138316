import React from "react";
import { useTitle } from 'react-use';
import { Grid, Icon, Typography, Paper, useTheme } from "@material-ui/core";
import { IntlMessages, injectIntl } from "react-platform-components/dist/Translations";

export default injectIntl(function PendingResult(props) {
  useTitle(
      props.intl.formatMessage({
          id: 'Purchase pending page title',
          defaultMessage: 'Purchase pending page title',
      }),
  );
  const theme = useTheme();
  const color = theme?.palette?.warning?.main;

  return (
    <>
      <Paper className="p-12 m-12">
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Icon
              style={{
                color: color,
                fontSize: "7rem",
                border: `2px solid ${color}`,
                padding: 20,
                borderRadius: "100px",
              }}
            >
              hourglass_empty
            </Icon>
          </Grid>
          <Grid
            style={{ marginTop: "3rem" }}
            item
            xs={12}
            container
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography variant="h5">
              <IntlMessages
                id="Your purchase is processing !"
                defaultMessage="Your purchase is processing !"
              />
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography variant="body1">
              <IntlMessages
                id="Your purchase is being processed, and it takes approximately 48h"
                defaultMessage="Your purchase is being processed, and it takes approximately 48h"
              />
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
})
