import { CustomCreateProductSalable } from "./CreateProduct/CustomCreateProductSalable"

export const CreateProductSalableConfig = {
    auth: ["user"],
    routes: [
        {
            component: CustomCreateProductSalable,
            path: "/create-product-salable",
            exact: true,
        },
        {
            component: CustomCreateProductSalable,
            path: "/create-product-salable/:id",
            exact: true,
        },
    
    ],
    settings: {
        layout: {
            config: {
                bottomNavigation : false        
            }
        }
    },
}
