import _ from "lodash";
import { injectToBaseCreationDialog } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EditProfileForm from "./EditProfileForm";
import { FETCH_LANGUAGES } from "../ProfileActions";

// Edit profile dialog
export default injectToBaseCreationDialog(
  _.flowRight(
    withRouter,
    connect((state) => {
      return {
        user: state.auth.user,
        languages: state.profileReducer.languages,
      };
    })
  ),
  {
    createForm: EditProfileForm,
    editTitle: "Modifier profil",
    componentDidMount: function () {
      if (_.isEmpty(this.props.languages)) {
        this.props.dispatch({ type: FETCH_LANGUAGES });
      }
    },
    // Override submit action in BaseCreationDialog
    submitAction: function (formData) {
      this.props.dispatch({
        type: "EDIT_PROFILE",
        id: this.props.user.id,
        model: formData,
        history: this.props.history,
        redirectTo: "/profile",
        onSuccess: this.props.close,
      });
    },
  }
);
