import { all, put, takeEvery } from "redux-saga/effects"
import axios from "react-platform-components/dist/axios"
import { handleErrors } from "react-platform-components/dist/Utils"
import { BASE_API_URL } from "../../configs"

export function* fetchWarningZone() {
    yield put({ type: "FETCH_WARNING_ZONE_START" })

    try {
        const response = yield axios.get(`${BASE_API_URL}alerts`)

        yield put({ type: "FETCH_WARNING_ZONE_SUCCESS", payload: response.data })
    } catch (error) {
        yield put({ type: "HANDLE_ERROR", error: handleErrors(error) })
        yield put({ type: "FETCH_WARNING_ZONE_ERROR" })
    }
}

export function* createWarningZone(action) {
    yield put({ type: "CREATE_WARNING_ZONE_START" })

    try {
        yield axios.post(`${BASE_API_URL}alerts`, action.model)

        yield put({ type: "CREATE_WARNING_ZONE_SUCCESS" })
        yield put({ type: "FETCH_WARNING_ZONE" })
        action.onSuccess && action.onSuccess()
        action.redirect && action.redirect()
    } catch (error) {
        yield put({ type: "HANDLE_ERROR", error: handleErrors(error) })
        yield put({ type: "CREATE_WARNING_ZONE_ERROR" })
    }
}

export function* deleteWarningZone(action) {
    yield put({ type: "DELETE_WARNING_ZONE_START" })

    try {
        yield axios.delete(`${BASE_API_URL}alerts/${action.id}`)

        yield put({ type: "DELETE_WARNING_ZONE_SUCCESS" })
        yield put({ type: "FETCH_WARNING_ZONE" })
        action.onSuccess && action.onSuccess()
    } catch (error) {
        yield put({ type: "HANDLE_ERROR", error: handleErrors(error) })
        yield put({ type: "DELETE_WARNING_ZONE_ERROR" })
    }
}

export function* WarningZoneSaga() {
    yield all([
        takeEvery("FETCH_WARNING_ZONE", fetchWarningZone),
        takeEvery("CREATE_WARNING_ZONE", createWarningZone),
        takeEvery("DELETE_WARNING_ZONE", deleteWarningZone),
    ])
}
