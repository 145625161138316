import _ from "lodash";
import { connect } from "react-redux";
import { injectToBaseRegisterContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import RegisterForm from "./CustomRegisterForm";
import { injectIntl } from "react-intl";

export default injectToBaseRegisterContainer(
  _.flowRight(
    connect((state) => {
      return {
        inProgress: state.registerReducer.inProgress,
      };
    }),
    injectIntl
  ),
  {
    form: RegisterForm,
    handleSubmit: function (values) {
      const data = { ...values };
      this.dispatch({
        type: "REGISTER_USER",
        data,
        history: this.history,
        reload: true,
      });
    },
  }
);
