import { cautionSaga } from "./ProductSalableActions";
import ProductSalableContainer from "./ProductSalableContainer";
import customProductSalableReducer from "./ProductSalableReducer";

export const CustomProductSalableConfig = {
  auth: ["user"],
  routes: [
    {
      component: ProductSalableContainer,
      path: "/product-salable",
    },
  ],
  actions: cautionSaga,
  reducer: customProductSalableReducer,
};
