import React from "react";
import ExtensionIcon from "@material-ui/icons/Extension";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { ReservationDetails as DefaultReservationDetails } from "react-platform-reservation/dist/ReservationDetailsOfPurchase/ReservationDetails";
import { ReservationInfo } from "react-platform-reservation/dist/ReservationDetailsOfPurchase/components/ReservationInfo";

export const ReservationDetailsOfSeller = (props) => {
  const {
    purchaseItem: { type_of_exchange: typeOfExchange },
  } = props;

  return (
    <DefaultReservationDetails {...props}>
      <ReservationInfo
        title={
          <IntlMessages
            id="Type de réservation"
            defaultMessage="Type de réservation"
          />
        }
        value={typeOfExchange}
        icon={<ExtensionIcon />}
      />
    </DefaultReservationDetails>
  );
};
