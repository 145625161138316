import React, { useState } from "react";
import { Paper, List } from "@material-ui/core";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "react-platform-components/dist/ConfirmationDialog";
import SubscriptionPurchaseItem from "../component/SubscriptionPurchaseItem";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";

function ActiveSubscriptionsTab(props) {
  const dispatch = useDispatch();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const subscriptionPurchases = props.products
  const stripeOptions = useSelector(
    (state) => state?.auth?.user?.stripe_extension
  );

  return (
    <>
      {stripeOptions?.default_payment_method !== null && (
        <Alert severity="info">
          {stripeOptions?.default_payment_method?.type === "card" && (
            <>
              <IntlMessages
                id="The next renewal will be by the card"
                defaultMessage="The next renewal will be by the card"
              />{" "}
              {stripeOptions?.default_payment_method?.number}
              <IntlMessages
                id=" you can change it in the following link"
                defaultMessage=" you can change it in the following link"
              />
              <Link
                to="/profile?tab=update_stripe_payment_methods"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <IntlMessages id="Change" defaultMessage="Change" />
              </Link>
            </>
          )}
          {stripeOptions?.default_payment_method?.type === "sepa_debit" && (
            <>
              <IntlMessages
                id="The next renewal will be by the bank account"
                defaultMessage="The next renewal will be by the bank account"
              />{" "}
              {stripeOptions?.default_payment_method?.iban}
              <IntlMessages
                id=" you can change it in the following link"
                defaultMessage=" you can change it in the following link"
              />
              <Link
                to="/profile?tab=update_stripe_payment_methods"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <IntlMessages id="Change" defaultMessage="Change" />
              </Link>
            </>
          )}
        </Alert>
      )}
      <div className="pt-2">
        {subscriptionPurchases?.length > 0 && (
          <Paper className="">
            <List>
              {subscriptionPurchases.map((item) =>
                item.purchase_salable_items.map((subscription, index) => {
                  return (
                    <SubscriptionPurchaseItem
                      key={index}
                      item={item}
                      subscription={subscription}
                      index={index}
                      setDeleteOpen={setDeleteOpen}
                      setSelectedSubscription={setSelectedSubscription}
                    />
                  );
                })
              )}
            </List>
          </Paper>
        )}

        {deleteOpen && (
          <ConfirmationDialog
            closeLabel="Annuler"
            confirmLabel="Confirmer"
            title="Êtes-vous sûr de vouloir anuuler ce subscription?"
            open={deleteOpen}
            close={() => setDeleteOpen(false)}
            confirmAction={() => {
              dispatch({
                type: "UNSUBSCRIBE",
                id: selectedSubscription.purchase_id,
              });
              setDeleteOpen(false);
              setSelectedSubscription(null);
            }}
          />
        )}
      </div>
    </>
  );
}

export default ActiveSubscriptionsTab;
