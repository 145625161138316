import _ from "lodash";
import {
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import BaseForm from "react-platform-components/dist/BaseComponents/BaseForm";
import { injectToForm } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import {
  Select,
  TextField,
  PhoneNumber,
  ImageUploaderEditable,
} from "react-platform-components/dist/form-fields";
import { required } from "react-platform-components/dist/form-validators";
import { IntlMessages } from "react-platform-components/dist/Translations";

const isErrored = (props, key) => {
  return (
    props.touched && props.touched[key] && props.errors && props.errors[key]
  );
};

const userSituations = [
  {
    label: <IntlMessages id="Couple" defaultMessage="Couple" />,
    id: "Couple",
  },
  {
    label: <IntlMessages id="Family" defaultMessage="Family" />,
    id: "Family",
  },
  {
    label: <IntlMessages id="Friends" defaultMessage="Friends" />,
    id: "Friends",
  },
  {
    label: <IntlMessages id="Single" defaultMessage="Single" />,
    id: "Single",
  },
];

// EditProfile form fields
const getFormExtraFields = (props) => [
  <Grid container={true} spacing={2}>
    <Grid item xs={12}>
      {isErrored(props, "avatar") && (
        <>
          <Typography
            style={{
              color: isErrored(props, "avatar") ? "#f44336" : "#908f8f",
            }}
          >
            {
              <IntlMessages
                id="Photo de profil"
                defaultMessage="Photo de profil"
              />
            }
          </Typography>
          <Typography variant="caption" style={{ color: "#f44336" }}>
            {props.errors.avatar}
          </Typography>
        </>
      )}
    </Grid>
    <Grid item container xs={12} justify="center">
      <ImageUploaderEditable
        name="avatar"
        maxTotalSize={5000000}
        acceptedFiles="image/bmp,image/gif,image/jpeg,image/png,image/x-ms-bmp"
        // validate={required()}
      />
    </Grid>
    <Grid item={true} xs={12}>
      <TextField
        fullWidth={true}
        variant="outlined"
        margin="normal"
        type="text"
        name="p__pseudo"
        label="Pseudo"
      />
    </Grid>
    <Grid item={true} xs={12}>
      <TextField
        fullWidth={true}
        variant="outlined"
        type="text"
        name="last_name"
        margin="normal"
        label="Nom"
        required
        validate={required()}
      />
    </Grid>
    <Grid item={true} xs={12}>
      <TextField
        fullWidth={true}
        variant="outlined"
        type="text"
        name="first_name"
        margin="normal"
        label="Prénom"
        required
        validate={required()}
      />
    </Grid>
    <Grid item={true} xs={12}>
      <TextField
        fullWidth={true}
        variant="outlined"
        type="text"
        name="email"
        label="Email"
        margin="normal"
        required
        validate={required()}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        name="date_of_birth"
        label="Date de naissance"
        margin="normal"
        variant="outlined"
        type="date"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item={true} xs={12}>
      <PhoneNumber
        name="phone"
        country={"fr"}
        disableAreaCodes={true}
        fullWidth
        margin="normal"
        label="Téléphone"
      />
    </Grid>
    <Grid item xs={12}>
      <Select
        variant="outlined"
        fullWidth
        label="Situations"
        name="situation"
        margin="normal"
      >
        {userSituations.map((situation, index) => {
          return (
            <MenuItem key={index} value={situation.id}>
              {situation.label}
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
    <Grid item xs={12}>
      <Select
        variant="outlined"
        fullWidth
        label="Langages"
        name="languages"
        margin="normal"
        multiple
        renderValue={(selectedIds) =>
          selectedIds
            .map(
              (id) =>
                props.languages?.find((language) => language.id === id)?.name
            )
            .join(", ")
        }
      >
        {props.languages.map(({ id, name }) => {
          return (
            <MenuItem key={id} value={id}>
              <Checkbox checked={!!props.values.languages?.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
    <Grid item xs={12}>
      <TextField
        name="description"
        label="Description"
        margin="normal"
        variant="outlined"
        multiline
        rows="3"
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        name="destinations"
        label="Destinations"
        margin="normal"
        variant="outlined"
        multiline
        rows="3"
        fullWidth
      />
    </Grid>
  </Grid>,
];

// EditProfile form
export default injectToForm(BaseForm, {
  formExtraFields: (props) => getFormExtraFields(props),
  handleInitialValues: (props) => {
    const { user } = props;
    return {
      last_name: user.last_name,
      first_name: user.first_name,
      email: user.email,
      phone: user.phone,
      avatar: user.avatar ? user.avatar.original : "",
      p__pseudo: user.pseudo,
      date_of_birth: user.date_of_birth,
      situation: user.situation?.value,
      description: user.description,
      destinations: user.destinations,
      languages: _.map(user.languages || [], (language) => language.id),
    };
  },
});
