import { WarningZoneSaga } from './WarningZoneActions'
import WarningZoneReducer from './WarningZoneReducer'
import WarninigZoneContainer from './WarninigZoneContainer'

export const WarningZoneConfig = {
    auth: ['user'],
    routes: [
        {
            component: WarninigZoneContainer,
            path: '/warning-zone',
            exact: true,
        }
    ],
    actions: WarningZoneSaga,
    reducer: WarningZoneReducer
}