import React from "react";
import axios from "react-platform-components/dist/axios";
import { handleErrors } from "react-platform-components/dist/Utils";
import { all, put, takeEvery } from "redux-saga/effects";
import { BASE_API_URL, BASE_URL } from "../../configs";
import { IntlMessages } from "react-platform-components/dist/Translations";

// Handle making a purchase to Internal payment
export function* makeInternalPurchaseWithCaution(action) {
  yield put({ type: "INTERNAL_PURCHASE_START" });
  try {
    // Get purchase
    const purchaseResponse = yield axios.post(`${BASE_API_URL}purchases`);
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    const params = action.params || {};
    // Use purchase id to init the Mangopay transaction
    const paymentResponse = yield axios.get(
      `${BASE_URL}internalmarketplacepayment/init_payment_and_transaction/${purchaseResponse.data.id}`,
      {
        data: {}, // When creating a json request a json data is required or axios ignores the Content-Type
        headers,
        params,
      }
    );

    if (paymentResponse.data.success) {
      yield put({ type: "INTERNAL_PURCHASE_SUCCESS" });
      // we added this to refresh the user in the localstorage because some times when we purchase a stripe subscription
      // also we update the user rights and we need them in the frontend side to be updated to use them immediately.
      yield put({ type: "FETCH_USER" });
      yield put({
        type: "REDIRECT",
        to: "/purchase-success",
        history: action.history,
      });
    } else if (paymentResponse.data.url) {
      // handle 3D secure case here
      window.location.href = paymentResponse.data.url;
    }
  } catch (error) {
    yield put({ type: "INTERNAL_PURCHASE_ERROR" });
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

export function* shoppingCartOfReservationSaga() {
  yield all([
    takeEvery(
      "INTERNAL_PURCHASE_WITH_CAUTION",
      makeInternalPurchaseWithCaution
    ),
  ]);
}
