import React, { useEffect, useState } from "react"
import { WarningZoneForm } from "./WarningZoneForm"
import WarningZoneList from "./WarningZoneList"
import { useDispatch } from "react-redux"
import IntlMessages from "react-platform-components/dist/Translations/IntlMessages"
import { Paper, Tab, Tabs } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useTitle } from "react-use"

import qs from "qs"
import { injectIntl } from "react-platform-components/dist/Translations"

const WarningZone = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const currentTab = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
    }).tab

    const [tab, setTab] = useState(currentTab || "warning-zone")
    useTitle(
        tab === 'warning-zone'
            ? props.intl.formatMessage({
                  id: 'create warning zone page title',
                  defaultMessage: 'create warning zone page title',
              })
            : props.intl.formatMessage({
                  id: 'Warning zone list page title',
                  defaultMessage: 'Warning zone list page title',
              }),
    );

    const handleTabChange = (event, tab) => {
        setTab(tab)
        history.push({ search: `?tab=${tab}` })
    }

    const renderTab = () => {
        switch (tab) {
            case "warning-zone":
                return <WarningZoneForm handleTabChange={handleTabChange} />
            case "warning-zone-list":
                return <WarningZoneList {...props} />
            default:
                return <WarningZoneForm handleTabChange={handleTabChange} />
        }
    }

    useEffect(() => {
        dispatch({ type: "FETCH_CATEGORIES_OF_PRODUCT" })
    }, [])

    return (
        <div>
            <Paper>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    indicatorColor='primary'
                    textColor='primary'
                    scrollButtons='auto'
                    variant='scrollable'>
                    <Tab
                        className='h-16'
                        label={<IntlMessages id="Ajouter un zone d'alerte" />}
                        value='warning-zone'
                    />
                    <Tab
                        className='h-16'
                        label={<IntlMessages id="Liste Zone d'alerte" />}
                        value='warning-zone-list'
                    />
                </Tabs>
            </Paper>
            {renderTab()}
        </div>
    )
}

export default injectIntl(WarningZone)
