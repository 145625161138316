import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { injectToBaseContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { connect } from "react-redux";
import {
  KITTY_ENABLED,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
} from "react-platform-shoppingcart/dist/config";
import ShoppingCartList from "./ShoppingCartList";

const styles = (theme) => ({
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
  },
});

/**
 * We create our class by using injectToBaseContainer
 * this will return a React Component that is wrapped with the HOCs
 * inside flowRight, and hass all the props that are passed as options
 */
export default injectToBaseContainer(
  _.flowRight(
    withStyles(styles),
    connect((state) => {
      return {
        products: state.shoppingCartReducer.cart,
        kittyAmount: state.shoppingCartReducer.kittyAmount,
      };
    })
  ),
  {
    listComponent: ShoppingCartList,
    // Overrid componentDidMount of the BaseContainer to fetch the cart
    componentDidMount: (props) => {
      props.dispatch({
        type: "FETCH_CART",
        params: { _embed: ["cart_items.salable_item"] },
      });
    },
    headerTitle: "Mon Panier",
    headerIcon: "add_shopping_cart",
    confirmTitle: "Êtes-vous sûr de vouloir procéder au paiement?",
    // Overrid the confirmation action of the ConfirmationDialog
    // NOTE: this NEEDS to be a function expression and NOT an arrow function
    confirmAction: function () {
      let params;
      if (KITTY_ENABLED) {
        const kittyAmount = this.props.kittyAmount;
        params = kittyAmount ? { kitty_wallet_amount: kittyAmount } : null;
      }
      if (PAYMENT_METHOD === PAYMENT_METHODS.MANGOPAY) {
        this.props.dispatch({ type: "PURCHASE", params });
      }

      if (
        PAYMENT_METHOD === PAYMENT_METHODS.INTERNAL_PAYMENT ||
        PAYMENT_METHOD ===
          PAYMENT_METHODS.INTERNAL_PAYMENT_WITH_STRIPE_CARD_ONLY
      ) {
        this.props.dispatch({ type: "INTERNAL_PURCHASE", params });
      }

      this.closeConfirmDialog();
    },
  }
);
