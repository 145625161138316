import { getBackendConfig } from "react-platform-components/dist/Utils";

export const BASE_API_URL = getBackendConfig(
  "REACT_BASE_API_URL",
  process.env.REACT_APP_BASE_API_URL
);
export const BASE_URL = getBackendConfig(
  "REACT_BASE_URL",
  process.env.REACT_APP_BASE_URL
);

export const STATIC_PATH = process.env.PUBLIC_URL || "";
export const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;
export const LOGIN_PAGE = `${BASE_URL}${BASE_APP_URL}/login`;

export const STRIPE_KEY = getBackendConfig(
  "STRIPE_API_KEY",
  process.env.REACT_APP_STRIPE_KEY
);
export const ENV = getBackendConfig("ENV", "dev");

export const SPECIFICITIES_ORDERS = [
  {
    label: "Caractéristiques téchniques",
    icon: "build",
    orders: [
      "683b19d8-f004-4283-97b6-3a6020091650",
      "e3cd3ce8-7329-4886-85f1-d8c23d0cf659",
      "b3ad27a6-b1d9-4113-9c64-97eb46f9de5f",
      "429af46f-51fb-439d-803e-a4d983f72b81",
      "cbcfdc33-9978-4eb6-a0ef-4da5fc850af4",
      "35a699ed-bae8-4f24-837d-9f710451bf1a",
      "cd7c0284-adef-4e3d-b639-0ba6b70cc2b1",
      "1d8f5df0-fe3f-4b36-8751-eb0a1fb5679f",
      "9eec3a48-8556-42a9-b3e6-8dfd54e34daa",
      "adc78fce-8484-4ed3-a9a4-71650022752b",
      "6325f231-07f2-4649-b554-afe3ff9dec3d",
      "18b8e246-beb2-4207-b4db-34df0f5aa1d1",
      "79f3115f-c1ed-4467-a610-f63ee79f0eaf",
      "1f8fb395-980d-4f50-b694-0326ced42e1a",
    ],
  },
  {
    label: "Vie à bord",
    icon: "local_hotel",
    orders: [
      "819c5c05-02cf-47a2-8f9e-89b8f422913b",
      "8b7501ad-b724-4998-aa36-cf3d9cfce8d2",
      "6a6bfc5f-464b-433f-a4b6-2fb9e9cced06",
      "652a2851-beef-4c72-be5a-31047a6b642b",
      "71c8677b-0507-4e34-881d-bfd9b4ba668f",
      "19f11302-93f4-4b96-afbb-2a4d32c51717",
      "e3f4b199-8a90-4f08-8934-0bc11812e3de",
    ],
  },

  {
    label: "Autonomie",
    icon: "battery_charging_full",
    orders: [
      "02a6f460-eb68-421b-a672-edf00b2045fd",
      "49f1884f-f08b-467c-a5f8-88851d8fff74",
      "275aa143-9ba8-4b64-b4b4-4e5472024aaa",
      "d7be84dc-559d-45b3-88b2-d38879922ffb",
    ],
  },
  {
    icon: "outdoor_grill",
    label: "Equipement",
    orders: ["c9af2282-138b-4fe6-8e38-c525a9749c6d"],
  },
];
export const SPECIFICITIES_STEPONE = [
  {
    orders: [
      "f4fe4cc3-0997-4602-ad1e-ffa72d7b6bfc",
      "58627bef-8146-43b5-bd99-7a04af8cf681",
    ],
  },
];

export const SALABLE_POINT_UUID =
  process.env.REACT_APP_SALABLE_POINT_UUID ||
  "c136da39-ba01-4659-8b15-01fc9de7e85a";
export const DISPLAY_PURCHASES_FOR_CLASSES = getBackendConfig(
  "DISPLAY_PURCHASES_FOR_CLASSES",
  process.env.REACT_APP_DISPLAY_PURCHASES_FOR_CLASSES || []
);

export const RESERVATION_OPTION_UUID = getBackendConfig(
  "RESERVATION_OPTION_UUID",
  process.env.REACT_APP_RESERVATION_OPTION_UUID
);
export const CAUTION_RIGHT_UUID = getBackendConfig(
  "REACT_APP_CAUTION_RIGHT_UUID",
  process.env.REACT_APP_CAUTION_RIGHT_UUID || ""
);
export const ESSENTIAL_SUBSCRIPTION_UUID =
  process.env.REACT_APP_ESSENTIAL_SUBSCRIPTION_UUID ||
  "4de68dcc-e81c-4c82-91a5-b5e6b70c86ea";
export const RIGHT_RESERVATION_ACCESS_UUID =
  process.env.REACT_APP_RIGHT_RESERVATION_ACCESS_UUID ||
  "c639706e-be98-4a19-8007-6cf099d93f7e";

export const STRIPE_SUBSCRIPTION_SALABLE_ITEM_CLASS =
  process.env.REACT_APP_STRIPE_SUBSCRIPTION_SALABLE_ITEM_CLASS;

export const SPECIFICITY_ROADBOOK_UUID = process.env.REACT_APP_SPECIFICITY_ROADBOOK_UUID
export const TAG_CERTIFIE_UUID = process.env.REACT_APP_TAG_CERTIFIE_UUID