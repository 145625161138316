import React, { useEffect } from "react"
import { withPopoverMui } from "react-platform-components/dist/components/PopoverContainer"
import _ from "lodash"
import { Button, Typography, Grid, Icon } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { renderMultiFilesField } from "react-platform-components/dist/uploader"
import { Field, FormikProvider, useFormik } from "formik"
import { IntlMessages } from "react-platform-components/dist/Translations"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import Skeleton from '@material-ui/lab/Skeleton';
import { TooltipButton } from "react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/TooltipButton"

const useStyles = makeStyles((theme) => ({
    AlignItemsAndMargin: {
        display: "flex",
        alignItems: "center",
        marginTop: "8px",
        marginBottom: "16px",
        color: theme.palette.primary.main,
    },
}))

export function UploaderField({ name, field, label, textCheck, inProgress }) {
    const classes = useStyles()
    return (
    !inProgress ? (
        <Grid>
            {_.isEmpty(field) ? (
                <Grid item xs={12} className='pb-4'>
                    <Typography variant='subtitle2'>
                        <IntlMessages id={`${label} :`} />
                    </Typography>
                    <Field
                        type='file'
                        name={name}
                        label={<IntlMessages id={label} />}
                        maxSize={1000000}
                        acceptedFiles='application/pdf,image/jpeg,image/jpg,image/png'
                        component={renderMultiFilesField}
                    />
                </Grid>
            ) : (
                <Grid className={classes.AlignItemsAndMargin}>
                    <CheckBoxIcon className='mr-2' />
                    <IntlMessages id={textCheck} />
                </Grid>
            )}
        </Grid>
    ) : <Skeleton variant="text" width={'50vh'} height={60} />
    ) 
}

function AddDocumentsForm(props) {
    const history = useHistory()
    const dispatch = useDispatch()
    const { product, handleClose } = props
    const { user, productsalable, inProgress } = useSelector((state) => ({
        user: state.auth.user,
        productsalable: state.ProductReducer.product,
        inProgress: state.ProductReducer.isProductFetchingInProgress,
    }))
    useEffect(() => {
        dispatch({
            type: "FETCH_PRODUCT",
            redirectTo: "/product-salable",
            endpoint: "salable-products",
            id: product.id,
            history,
        })
    }, [])
    const formik = useFormik({
        initialValues: {
            drivers_licenses: "",
            identity_cards: "",
            immatriculation_certificates: "",
            insurance_certificates: "",
        },
        onSubmit: (data) => {
            //add document of user
            const modelUser = new FormData()
            if (!_.isEmpty(data.drivers_licenses))
                data.drivers_licenses.forEach((file, index) => {
                    if (file.file) modelUser.append(`drivers_licenses-${index + 9999}-filename`, file.file)
                })
            if (!_.isEmpty(data.identity_cards))
                data.identity_cards.forEach((file, index) => {
                    if (file.file) modelUser.append(`identity_cards-${index + 9999}-filename`, file.file)
                })
            if (!_.isEmpty(data.drivers_licenses) || !_.isEmpty(data.identity_cards)) {
                dispatch({
                    type: "EDIT_PROFILE",
                    id: user.id,
                    model: modelUser,
                    redirectTo: "/product-salable",
                    history,
                    onSuccess: () => {
                        dispatch({
                            type: "HANDLE_SUCCESS_MESSAGE",
                            message: "Success!",
                        })
                    },
                })
            }
            //add document of vehicule
            const modelVehicle = new FormData()
            if (!_.isEmpty(data.immatriculation_certificates))
                data.immatriculation_certificates.forEach((file, index) => {
                    if (file.file)
                        modelVehicle.append(
                            `immatriculation_certificates-${index + 9999}-filename`,
                            file.file
                        )
                })
            if (!_.isEmpty(data.insurance_certificates))
                data.insurance_certificates.forEach((file, index) => {
                    if (file.file)
                        modelVehicle.append(`insurance_certificates-${index + 9999}-filename`, file.file)
                })
            if (!_.isEmpty(data.immatriculation_certificates) || !_.isEmpty(data.insurance_certificates)) {
                dispatch({
                    type: "UPDATE_PRODUCT",
                    id: product.id,
                    model: modelVehicle,
                    redirectTo: "/product-salable",
                    endpoint: "salable-products",
                    history,
                    onSuccess: () => {
                        dispatch({
                            type: "HANDLE_SUCCESS_MESSAGE",
                            message: "Success!",
                        })
                    },
                })
            }
            handleClose()
        },
    })

    const isIdentified = () => {
        return (
            !_.isEmpty(user.driverse_licenses) &&
            !_.isEmpty(user.identity_cards) &&
            !_.isEmpty(productsalable?.immatriculation_certificates) &&
            !_.isEmpty(productsalable?.insurance_certificates)
        )
    }
    return (
        <div className='m-4 md:m-8'>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid>
                            <UploaderField
                                name='drivers_licenses'
                                field={user.drivers_licenses}
                                label='Permis de conduire'
                                textCheck='Le permis de conduire a été chargé avec succès'
                                inProgress={inProgress}
                            />
                            <UploaderField
                                name='identity_cards'
                                field={user.identity_cards}
                                label="Pièce d'identite"
                                textCheck="La pièce d'identité a été chargée avec succès"
                                inProgress={inProgress}
                            />
                            <UploaderField
                                name='immatriculation_certificates'
                                field={productsalable?.immatriculation_certificates}
                                label="certification d'immatriculation"
                                textCheck="La certification d'immatriculation a été chargée avec succès"
                                inProgress={inProgress}
                            />
                            <UploaderField
                                name='insurance_certificates'
                                field={productsalable?.insurance_certificates}
                                label="certification d'assurance"
                                textCheck="La certification d'assurance a été chargée avec succès"
                                inProgress={inProgress}
                            />
                        </Grid>
                        <Grid container item xs={12} justify='flex-end' className='pt-2'>
                            <Button
                                disabled={isIdentified()}
                                color='primary'
                                variant='contained'
                                type='submit'>
                                <IntlMessages id='Valider' />
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormikProvider>
        </div>
    )
}

export const UploadDocuments = withPopoverMui(AddDocumentsForm, ({ onClick }) => {
    return (
        <TooltipButton onClick={onClick} title='Certification'>
            <Icon>add</Icon>
        </TooltipButton>
    )
})
