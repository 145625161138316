import _ from "lodash";
import React from "react";
import { all, call, takeEvery } from "redux-saga/effects";
import { generateRoutesFromConfigs } from "react-platform-components/dist/helpers";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { MenuConfig } from 'react-platform-components/dist/Menu/MenuConfig';
import { NotificationsConfig } from 'react-platform-components/dist/Notifications/NotificationsConfig';
import { LocaleConfig } from "react-platform-components/dist/Translations/TranslationsConfig";
import { LoginConfig } from "react-platform-user/dist/Login/LoginConfig";
import { ForgotPasswordConfig } from "react-platform-user/dist/ForgotPassword/ForgotPasswordConfig";
import { ProfileConfig } from "react-platform-user/dist/Profile/ProfileConfig";
import { RegisterConfig } from "react-platform-user/dist/Register/RegisterConfig";
import { DashboardConfig } from "./containers/Dashboard/DashboardConfig";
import { CategoryOfProductConfig } from "react-platform-category-of-product/dist/CategoryOfProduct/CategoryOfProductConfig";
import { ProductConfig } from "react-platform-product/dist/Product/ProductConfig";
import { ProductDetailsConfig } from "react-platform-product/dist/ProductDetails/ProductDetailsConfig";
import { PurchaseConfig } from "react-platform-purchase/dist/Purchase/PurchaseConfig";
import { TransactionsConfig } from "react-platform-transactions/dist/Transactions/TransactionsConfig";
import { ProductSalableConfig } from "react-platform-productsalable/dist/ProductSalable/ProductSalableConfig";
import { AddressOfProductConfig } from "react-platform-address-of-product/dist/AddressOfProduct/AddressOfProductConfig";
import { OptionOfProductConfig } from "react-platform-option-of-product/dist/OptionOfProduct/OptionOfProductConfig";
import { AddressConfig } from "react-platform-address/dist/Address/AddressConfig";
import { SpecificitiesOfProductConfig } from "react-platform-specificity-of-product/dist/SpecificityOfProduct/SpecificitiesOfProductConfig";
import { CreateProductSalableConfig } from "./containers/ProductSalable/pages/CreateProductSalableConfig";
import { CustomProductSalableConfig } from "./containers/ProductSalable/ProductSalableConfig";
import {
  ChatConfig,
  ConversationConfig,
} from "react-platform-chat/dist/Chat/ChatConfig";
import { CustomRegisterConfig } from "./containers/CustomRegister/CustomRegisterConfig";
import { BookmarkOfProductConfig } from "react-platform-bookmark-of-product/dist/BookmarkOfProduct/BookmarkOfProductConfig";
import { ActiveSubscriptionsConfig } from "react-platform-stripesubscriptions/dist/ActiveSubscriptions/ActiveSubscriptionsConfig";
import { StripeSubscriptionsConfig as CustomStripeSubscriptionsConfig } from "./containers/StripeSubscriptions/SubscriptionsConfig";
import { ShoppingCartConfig } from "react-platform-shoppingcart/dist/ShoppingCart/ShoppingCartConfig";
import { ProfileConfig as InternalProfileConfig } from "./containers/Profile/ProfileConfig";
import { StripeSubscriptionsConfig } from "react-platform-stripesubscriptions/dist/Subscriptions/SubscriptionsConfig";
import { ReservationConfig } from "react-platform-reservation/dist/Reservation/ReservationConfig";
import { SecondaryShoppingCartConfig } from "./containers/SecondaryShoppingCart/SecondaryShoppingCartConfig";
import { ShoppingCartOfPointConfig } from "./containers/ShoppingCartOfPoint/ShppingCartOfPointConfig";
import { SecondaryPurchaseResultConfig } from "./containers/Purchase/SecondaryPurchaseResult/SecondaryPurchaseResultConfig";
import { BuyerPurchasePointConfig } from "./containers/Purchase/BuyerPurchasePoint/BuyerPurchasePointConfig";
import { PurchaseItemsReviewsConfig } from "react-platform-reviewofpurchaseitem/dist/Review/ReviewsConfig";
import { ShoppingCartOfReservationConfig } from "./containers/ShoppingCartOfReservation/ShoppingCartConfig";
import { RIGHT_RESERVATION_ACCESS_UUID } from "./configs";
import { ExternalPageConfig } from "react-platform-components/dist/ExternalPage/ExternalPageConfig";
import { PurchaseOfBuyerConfig } from "./containers/ReservationPurchase/PurchaseOfBuyer/PurchaseOfBuyerConfig";
import { PurchaseOfSellerConfig } from "./containers/ReservationPurchase/PurchaseOfSeller/PurchaseOfSellerConfig";
import { CautionConfig } from "react-platform-caution/dist/CautionField/CautionConfig"
import { WarningZoneConfig } from "./containers/WarningZone/WarningZoneConfig"

const routeConfigs = [
  MenuConfig,
  NotificationsConfig,
  DashboardConfig,
  LoginConfig,
  ForgotPasswordConfig,
  InternalProfileConfig,
  ProductConfig,
  ProductDetailsConfig,
  // ProductSalableConfig,
  TransactionsConfig,
  CreateProductSalableConfig,
  CustomProductSalableConfig,
  ChatConfig,
  ConversationConfig,
  CustomRegisterConfig,
  BookmarkOfProductConfig,
  ActiveSubscriptionsConfig,
  CustomStripeSubscriptionsConfig,
  // we disable this because we use shopping cart of reservation instead
  // ShoppingCartConfig,
  SecondaryShoppingCartConfig,
  ShoppingCartOfPointConfig,
  SecondaryPurchaseResultConfig,
  ShoppingCartOfReservationConfig,
  ExternalPageConfig,
  PurchaseOfBuyerConfig,
  PurchaseOfSellerConfig,
  WarningZoneConfig
];

export const navigationConfig = (props) => {
  const navigation = [
    {
      auth: DashboardConfig.auth,
      icon: "dashboard",
      id: "dashboard-component",
      title: <IntlMessages id="Espace membre" defaultMessage="Espace membre" />,
      type: "item",
      url: "/dashboard",
    },
    {
      auth: ProductConfig.auth,
      icon: "directions_bus",
      id: "product-component",
      title: <IntlMessages id="Mes véhicules" defaultMessage="Mes véhicules" />,
      type: "item",
      url: "/product-salable",
    },
    {
      auth: BookmarkOfProductConfig.auth,
      icon: "favorite",
      id: "prod-bookrmarks-component",
      title: <IntlMessages id="Mes favoris" defaultMessage="Mes favoris" />,
      type: "item",
      url: "/product-bookmarks",
    },
    {
      auth: CustomStripeSubscriptionsConfig.auth,
      icon: "rv_hookup",
      id: "stripe-subscriptions-component",
      title: <IntlMessages id="Abonnements" defaultMessage="Abonnements" />,
      type: "item",
      url: "/subscriptions",
    },
    {
      auth: WarningZoneConfig.auth,
      icon: "crisis_alert",
      id: "Warning-component",
      title: <IntlMessages id="Zone d'alerte" defaultMessage="Zone d'alerte" />,
      type: "item",
      url: "/warning-zone",
    },
    {
      auth: ShoppingCartOfReservationConfig.auth,
      icon: "add_shopping_cart",
      id: "shopping-component",
      title: (
        <IntlMessages
          id="Réservation en cours"
          defaultMessage="Réservation en cours"
        />
      ),
      type: "item",
      url: "/cart",
    },
    {
      auth: PurchaseOfBuyerConfig.auth,
      icon: "shopping_cart",
      id: "buyer-purchase-component",
      title: (
        <IntlMessages id="Mes réservations" defaultMessage="Mes réservations" />
      ),
      type: "item",
      url: "/buyer-purchases",
    },
    {
      auth: PurchaseOfSellerConfig.auth,
      icon: "event_available",
      id: "seller-shopping-component",
      title: <IntlMessages id="Mes prêts" defaultMessage="Mes prêts" />,
      type: "item",
      url: "/seller-purchases",
    },
    {
      auth: ShoppingCartOfPointConfig.auth,
      icon: "money",
      id: "stripe-purchase-points",
      title: (
        <IntlMessages
          id="Acheter des RoadPoints"
          defaultMessage="Acheter des RoadPoints"
        />
      ),
      type: "item",
      url: "/purchase-points",
    },

    // {
    //     'auth': TransactionsConfig.auth,
    //     'icon': 'sync_alt',
    //     'id': 'transactions-component',
    //     'title': <IntlMessages id="Transactions" defaultMessage="Transactions" />,
    //     'type': 'item',
    //     'url': '/transactions'
    // },
    // {
    //     'auth': SecondaryShoppingCartConfig.auth,
    //     'icon': 'add_shopping_cart',
    //     'id': 'secondary-shopping-component',
    //     'title': <IntlMessages id="Mon second panier" defaultMessage="Mon second panier" />,
    //     'type': 'item',
    //     'url': '/secondary-cart'
    // },
  ];
  if (
    props.user.rights &&
    props.user.rights.some(
      (right) => right.uuid === RIGHT_RESERVATION_ACCESS_UUID && right.hasAccess
    )
  ) {
    navigation.push({
      auth: ProfileConfig.auth,
      icon: "assignment",
      id: "fiche-component",
      title: (
        <IntlMessages id="Fiches pratiques" defaultMessage="Fiches pratiques" />
      ),
      type: "item",
      url: props.intl.formatMessage({
        id: "https://www.google.com",
        defaultMessage: "https://www.google.com",
      }),
      component: "a",
      target: "_blank",
    });
  }
  return navigation;
};

export const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    // eslint-disable-next-line react/display-name
    auth: ["user"],
    component: () => <div />,
    path: "/",
  },
];

function* redirect(action) {
  yield call(action.history.push, action.to);
}

function* sagas() {
  yield all([takeEvery("REDIRECT", redirect)]);
}

export function* rootSaga() {
  yield all([
    sagas(),
    LocaleConfig.actions(),
    LoginConfig.actions(),
    ForgotPasswordConfig.actions(),
    RegisterConfig.actions(),
    ProfileConfig.actions(),
    InternalProfileConfig.actions(),
    CategoryOfProductConfig.actions(),
    SpecificitiesOfProductConfig.actions(),
    ProductConfig.actions(),
    ProductDetailsConfig.actions(),
    ProductSalableConfig.actions(),
    AddressOfProductConfig.actions(),
    AddressConfig.actions(),
    OptionOfProductConfig.actions(),
    TransactionsConfig.actions(),
    PurchaseConfig.actions(),
    ChatConfig.actions(),
    BookmarkOfProductConfig.actions(),
    ActiveSubscriptionsConfig.actions(),
    CustomStripeSubscriptionsConfig.actions(),
    ShoppingCartConfig.actions(),
    ReservationConfig.actions(),
    SecondaryShoppingCartConfig.actions(),
    ShoppingCartOfPointConfig.actions(),
    PurchaseItemsReviewsConfig.actions(),
    CustomProductSalableConfig.actions(),
    ShoppingCartOfReservationConfig.actions(),
    CautionConfig.actions(),
    WarningZoneConfig.actions()
  ]);
}

function errorMessage(state = { error: null }, action) {
  switch (action.type) {
    case "HANDLE_ERROR": {
      return { ...state, error: action.error };
    }
    case "CLEAR_ERROR": {
      return { ...state, error: null };
    }
    default:
      return { ...state };
  }
}

function successMessage(state = { message: null }, action) {
  switch (action.type) {
    case "HANDLE_SUCCESS_MESSAGE": {
      return { ...state, message: action.message };
    }
    case "CLEAR_SUCCESS_MESSAGE": {
      return { ...state, message: null };
    }
    default:
      return { ...state };
  }
}

function auth(state = { roles: ["guest"] }, action) {
  switch (action.type) {
    case "USER_ALREAY_AUTHENTICATED": {
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
      return {
        user: {
          ...user,
          roles: user.roles.map((role) => role.name),
        },
      };
    }
    case "FETCH_USER_START": {
      return { ...state };
    }
    case "FETCH_USER_ERROR": {
      return { ...state };
    }
    case "FETCH_USER_SUCCESS": {
      return {
        user: {
          ...action.payload,
          roles: action.payload.roles.map((role) => role.name),
        },
      };
    }
    default: {
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
      return {
        user: {
          ...user,
          roles: user ? user.roles.map((role) => role.name) : ["guest"],
        },
      };
    }
  }
}

const defaultSettings = {
  layout: {
    config: {
      scroll: "content",
      navbar: {
        display: true,
        folded: false,
        position: "left",
      },
      toolbar: {
        display: false,
        style: "fixed",
        position: "below",
      },
      footer: {
        display: true,
        style: "fixed",
        position: "below",
      },
    },
  },
};

const initialState = {
  defaults: _.merge({}, defaultSettings),
  current: _.merge({}, defaultSettings),
};

function settings(state = initialState, action) {
  switch (action.type) {
    case "SET_SETTINGS": {
      return {
        ...state,
        current: _.merge({}, state.current, action.value),
      };
    }
    case "SET_DEFAULT_SETTINGS": {
      return {
        ...state,
        defaults: _.merge({}, state.defaults, action.value),
        current: _.merge({}, state.defaults, action.value),
      };
    }
    case "RESET_DEFAULT_SETTINGS": {
      return {
        ...state,
        defaults: _.merge({}, state.defaults),
        current: _.merge({}, state.defaults),
      };
    }
    default: {
      return state;
    }
  }
}

export const reducers = {
  auth,
  errorMessage,
  successMessage,
  settings,
  localeReducer: LocaleConfig.reducer,
  loginReducer: LoginConfig.reducer,
  forgotPasswordReducer: ForgotPasswordConfig.reducer,
  registerReducer: RegisterConfig.reducer,
  profileReducer: InternalProfileConfig.reducer,
  categoryOfProductReducer: CategoryOfProductConfig.reducer,
  specificityOfProductReducer: SpecificitiesOfProductConfig.reducer,
  ProductReducer: ProductConfig.reducer,
  ProductDetailsReducer: ProductDetailsConfig.reducer,
  productSalableReducer: ProductSalableConfig.reducer,
  addressOfProductReducer: AddressOfProductConfig.reducer,
  addressReducer: AddressConfig.reducer,
  optionOfProductReducer: OptionOfProductConfig.reducer,
  transactionsReducer: TransactionsConfig.reducer,
  purchaseReducer: PurchaseConfig.reducer,
  chatReducer: ChatConfig.reducer,
  bookmarkOfProductReducer: BookmarkOfProductConfig.reducer,
  activeSubscriptionsReducer: ActiveSubscriptionsConfig.reducer,
  stripeSubscriptionsReducer: StripeSubscriptionsConfig.reducer,
  shoppingCartReducer: ShoppingCartConfig.reducer,
  reservationReducer: ReservationConfig.reducer,
  secondaryShoppingCartReducer: SecondaryShoppingCartConfig.reducer,
  purchasePointReducer: ShoppingCartOfPointConfig.reducer,
  purchaseItemsReviewsReducer: PurchaseItemsReviewsConfig.reducer,
  customProductSalableReducer: CustomProductSalableConfig.reducer,
  CautionReducer: CautionConfig.reducer,
  WarningZoneReducer: WarningZoneConfig.reducer
};
