import React, { useEffect } from "react"
import { withPopoverMui } from "react-platform-components/dist/components/PopoverContainer"
import { withConfirmationDialog } from "react-platform-components/dist/components/ConfrimationDialog/ConfrimationDialog"
import { Button, Grid, Icon, IconButton, MenuItem, Tooltip } from "@material-ui/core"
import { FormikProvider, useFormik } from "formik"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Select } from "react-platform-components/dist/form-fields"
import * as actions from "react-platform-caution/dist/CautionField/CautionActions"
import IntlMessages from "react-platform-components/dist/Translations/IntlMessages"
import { CAUTION_RIGHT_UUID } from '../../../configs'
import { TooltipButton } from "react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/TooltipButton"
import { FETCH_PRODUCT_SALABLES_SUCCESS } from "react-platform-productsalable/dist/ProductSalable/ProductSalableActions"


export const CautionForm = (props) => {
    const { product, handleClose } = props
    const history = useHistory()
    const dispatch = useDispatch()
    const { pagination, products } = useSelector((state) => ({
        products: state.productSalableReducer.productSalables,
        pagination: state.productSalableReducer.pagination,
    }))

    useEffect(() => {
        dispatch({ type: actions.FETCH_CAUTION_VALUES })
    }, [])

    const formik = useFormik({
        initialValues: { caution: product?.caution?.id || null },
        onSubmit: (data) => {
            const model = new FormData()
            model.append("caution", data.caution)
            dispatch({
                type: "UPDATE_PRODUCT",
                id: product.id,
                model: model,
                redirectTo: "/product-salable",
                endpoint: "salable-products",
                history,
                onSuccess: (updatedProduct) => {
                    dispatch({
                        type: FETCH_PRODUCT_SALABLES_SUCCESS,
                        payload: products.map((product) =>
                            +product.id === +updatedProduct.id
                                ? {
                                      ...product,
                                      caution: updatedProduct.caution,
                                  }
                                : product,
                        ),
                        pagination,
                    });
                }
            })
            handleClose()
        },
    })
    const { cautions } = useSelector(({ CautionReducer }) => ({
        cautions: CautionReducer.cautionValues,
    }))
    return (
        <div className='m-8 md:m-16'>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Select
                                variant='outlined'
                                fullWidth
                                label='Select Caution'
                                placeholder='Caution'
                                name='caution'
                                value={formik.values?.caution}
                                margin='normal'>
                                {cautions.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.id}>
                                            {item.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color='primary' variant='contained' type='submit'>
                                <IntlMessages id='Valider' />
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormikProvider>
        </div>
    )
}

export const CautionView = withPopoverMui(
    CautionForm,
    withConfirmationDialog((props) => {
        const { onClick, onConfirm } = props;

        const { user } = useSelector(({ auth }) => ({
            user: auth.user,
        }));
        const dispatch = useDispatch();
        const history = useHistory();
        const cautionRight = user.rights.some(
            (right) => right.uuid === CAUTION_RIGHT_UUID && right.hasAccess,
        );
        return cautionRight ? (
            <span>
                <TooltipButton
                    onClick={onClick}
                    color="primary"
                    title="Gestion de caution"
                >
                    <Icon>euro</Icon>
                </TooltipButton>
            </span>
        ) : (
            <Tooltip
                arrow
                title={
                    <IntlMessages id="doit payer un abonnement pour attribuer une caution à cette annonce" />
                }
            >
                <span>
                    <IconButton
                        onClick={() =>
                            onConfirm(
                                () => {
                                    dispatch({
                                        type: 'REDIRECT',
                                        to: '/subscriptions',
                                        history,
                                    });
                                },
                                {
                                    title: 'vous de devez avoir un abonnement caution afin d’assigner une caution a votre annonce ?',
                                    confirmLabel: 'allez dans la page caution',
                                },
                            )
                        }
                        color="gray"
                    >
                        <Icon>euro</Icon>
                    </IconButton>
                </span>
            </Tooltip>
        );
    }),
    { width: '500px' },
);
