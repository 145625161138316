import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export default function IdentificationDialog(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { close, open } = props;

  const handleConfirm = () => {
    dispatch({ type: "REDIRECT", to: "/profile?tab=identification", history });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      PaperProps={{ style: { margin: 8, width: "100%" } }}
    >
      <DialogTitle>
        <Typography variant="inherit">
          <IntlMessages id="Identification" defaultMessage="Identification" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <IntlMessages
          id="You need to identify yourself in order to subscribe."
          defaultMessage="You need to identify yourself in order to subscribe."
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>
          <IntlMessages id="Annuler" defaultMessage="Annuler" />
        </Button>
        <Button onClick={handleConfirm}>
          <IntlMessages id="Identifier" defaultMessage="Identifier" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
