import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { injectToBaseContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { BuyerPurchaseDetails } from "./BuyerPurchaseDetails";

const styles = () => ({
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
  },
});

/**
 * We create our class by using injectToBaseContainer
 * this will return a React Component that is wrapped with the HOCs
 * inside flowRight, and hass all the props that are passed as options
 */
export const BuyerPurchaseDetailsContainer = injectToBaseContainer(
  _.flowRight(withStyles(styles)),
  {
    listComponent: BuyerPurchaseDetails,
    headerTitle: "Mes Achats",
    headerIcon: "shopping_cart",
    extraProps: {
      canFinishPurchase: true,
    },
  }
);
