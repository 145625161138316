import CustomRegister from "./CustomRegister";

export const CustomRegisterConfig = {
  auth: ["guest"],
  routes: [
    {
      component: CustomRegister,
      path: "/register",
    },
  ],
  settings: {
    layout: {
      config: {
        footer: {
          display: false,
        },
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
      },
    },
  },
};
