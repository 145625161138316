import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
  Box,
  GridList,
  GridListTile,
  ListSubheader,
  Hidden,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useState, useEffect, useMemo } from "react";
import { useTitle } from "react-use";
import { injectIntl } from "react-intl";
import { IntlMessages } from "react-platform-components/dist/Translations";
import StripeComponent from "react-platform-purchase/dist/Purchase/Stripe";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import {
  BASE_URL,
  USE_STRIPE_PURSHACE,
  SHIPPING_OPTION_UUID,
  SHIPPING_CHOICE_ASK_ADDRESS_UUID,
  RESERVATION_OPTION_UUID,
} from "react-platform-shoppingcart/dist/config";
import _ from "lodash";
import PromoCode from "./components/PromoCode";
import { joinPaths } from "react-platform-components/dist/helpers";
import { useSelector } from "react-redux";
import Quantity from "./components/Quantity";

let Colissimo = null;
let ShippingFields = null;
let ReservationPresentation;
try {
  Colissimo =
    require("react-platform-colissimo/dist/Colissimo/ColissimoField").default;
} catch (error) { }

try {
  ShippingFields =
    require("react-platform-shipping/dist/components/ShoppingCartShipping").default;
} catch (error) { }

try {
  ReservationPresentation =
    require("react-platform-reservation/dist/Presentation/ReservationPresentation").default;
} catch (error) { }

const useStyles = makeStyles((theme) => ({
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
  },
  title: {
    marginLeft: "2rem",
    marginTop: "2rem",
    borderLeftStyle: "solid",
    borderColor: "#37abd9",
  },
  titleText: {
    marginLeft: "1rem",
    fontSize: "1rem",
  },
  itemImage: {
    height: "40px",
    width: "40px",
    marginRight: ".5rem",
    borderRadius: "5px",
  },
  tooltipImage: {
    height: "180px",
    width: "180px",
    marginTop: "5px",
    borderRadius: "5px",
    objectFit: "contain",
  },
}));

function ShoppingCartList(props) {
  useTitle(
    props.intl.formatMessage({
      id: 'Secondary shopping cart list page title',
      defaultMessage: 'Secondary shopping cart list page title',
    }),
  );
  const { user } = props;
  // Define the states and their handlers
  const [promoCode, setPromoCode] = useState(null);
  const [isConditionsChecked, handleConditionsChecked] = useState(false);
  const [isCharteChecked, setIsCharteChecked] = useState(false);
  const [isShippingChecked, setIsShippingChecked] = useState(
    ShippingFields ? false : true
  );
  const [shippingAddresses, setShippingAddresses] = useState([]);

  const [showPromoCodeField, handlePromoCodeField] = useState(false);
  const [addedPromoCode, handleAddedPromoCode] = useState(false);
  const stripeFormRef = React.createRef();
  const [shippongSelectionInProgress, setShippongSelectionInProgress] =
    useState(false);
  const [disableBeforeValidation, setDisable] = useState(false);
  useEffect(() => {
    if (
      props.user.id &&
      _.isEmpty(props.user.mpay_extension) &&
      !USE_STRIPE_PURSHACE
    ) {
      props.dispatch({
        type: "REDIRECT",
        to: "/mangopay-info",
        history: props.history,
      });
    }

    return () => {
      props.dispatch({ type: "RESET_SECONDARY_CART_TO_DEFAULT" });
    };
  }, []);

  // Define style classes
  const classes = useStyles();

  const { openConfirmDialog, dispatch, history, inProgress } = props;

  const { fetchCartInProgress, cart } = useSelector(
    ({ secondaryShoppingCartReducer }) => ({
      fetchCartInProgress: secondaryShoppingCartReducer.fetchCartInProgress,
      cart: secondaryShoppingCartReducer.cart,
    })
  );

  useEffect(() => {
    if (ShippingFields) {
      let tempShippingAddresses = [];
      // Create and array of the addresses that need to be selected for each cart item

      if (_.get(cart, "cart_items")) {
        cart.cart_items.forEach((item) => {
          const itemShippingOption = item.salable_item?.product_options?.map(
            (item) => {
              return item.option.uuid === SHIPPING_OPTION_UUID
                ? item.choice
                : [];
            }
          );

          const selectedShipping = item.options_linked_to_cart_item?.find(
            (opt) => opt.option_uuid === SHIPPING_OPTION_UUID
          );
          const selectedShippingChoiceUUID =
            selectedShipping?.extra_data?.choices[0].choice_uuid;

          if (itemShippingOption?.length > 0) {
            // We added this check because when we select an address we commit change (PATCH) to backend and because of that
            // we query also the cart to update it values (total price ....)
            // also because of the query of the cart if we select another shipping method for another cart item before the query
            // finish all the changes that we did will disappear.
            // to handle this problem we check if an address (method of shipping) already exist and save there old value.
            const addressAlreadySelected = shippingAddresses.find((address) => {
              return address.item.id === item.id;
            });

            if (!addressAlreadySelected) {
              tempShippingAddresses.push({
                item,
                choice_uuid: selectedShippingChoiceUUID,
                address: null,
                changed: false,
              });
            } else {
              // We needed to update the shipping address state and take the item new value
              // because the old value of the item has outdated info (the id of the item is changed)
              tempShippingAddresses.push({
                ...addressAlreadySelected,
                item,
                address: null,
                changed: false,
              });
            }
          }
        });
      }

      setShippingAddresses(tempShippingAddresses);
      setShippongSelectionInProgress(false);
    }
  }, [cart]);

  // Handle stripe payment
  const handlePayment = (response) => {
    if (!response) {
      // if there is no response show error snackbar
      dispatch({
        type: "HANDLE_ERROR",
        error: (
          <IntlMessages
            id="Something went wrong!"
            defaultMessage="Something went wrong!"
          />
        ),
      });
    } else if (response.error) {
      // if there are errors in the response show them in snackbar
      dispatch({ type: "HANDLE_ERROR", error: response.error.message });
    } else {
      // if there are no errors, make the purchase with or whithout the coupon
      if (addedPromoCode) {
        dispatch({
          type: "SECONDARY_STRIPE_PURCHASE",
          history,
          params: {
            payment_method_token: response.id,
            coupon: promoCode,
          },
        });
      } else {
        dispatch({
          type: "SECONDARY_STRIPE_PURCHASE",
          history,
          params: {
            payment_method_token: response.id,
          },
        });
      }
    }
  };

  const handlePaymentByDefaultPaymentMethod = () => {
    // we use this for make payment by default payment method
    if (addedPromoCode) {
      dispatch({
        type: "SECONDARY_STRIPE_PURCHASE",
        history,
        params: { coupon: promoCode },
      });
    } else {
      dispatch({ type: "SECONDARY_STRIPE_PURCHASE", history });
    }
  };

  const createFormData = (address) => {
    const formData = new FormData();
    const options = [];

    let colissimo_data = {};

    if (Colissimo) {
      colissimo_data = {
        pick_up_location_id: address.pick_up_location_id,
        colissimo_address: address.colissimo_address,
      };
    }

    if (_.get(address, "item.options_linked_to_cart_item")) {
      address.item.options_linked_to_cart_item.forEach((option) => {
        if (option.option_uuid === SHIPPING_OPTION_UUID) {
          options.push(option);
        }
      });
    }

    options.length > 0 &&
      options.forEach((option, index) => {
        formData.append(
          `options_linked_to_cart_item-${index}-inline_delete`,
          "true"
        );
        formData.append(`options_linked_to_cart_item-${index}-id`, option.id);
      });

    formData.append(
      `options_linked_to_cart_item-${options.length + 1}-option_class`,
      "OptionOfProduct"
    );
    formData.append(
      `options_linked_to_cart_item-${options.length + 1}-option_uuid`,
      SHIPPING_OPTION_UUID
    );
    formData.append(
      `options_linked_to_cart_item-${options.length + 1}-type`,
      "list"
    );
    formData.append(
      `options_linked_to_cart_item-${options.length + 1}-extra_data`,
      JSON.stringify({
        ...colissimo_data,
        address: address.address?.address_01 ? address.address : "",
        choices: [{ choice_uuid: address.choice_uuid }],
      })
    );

    return formData;
  };

  const checkAllitemsHaveShipping = (address) => {
    if (address.choice_uuid === SHIPPING_CHOICE_ASK_ADDRESS_UUID) {
      // If shpping needs address check if it's selected
      if (address.address) {
        return Boolean(address.address);
        // or that it already has been selected before in the cartitem
      } else {
        const itemShipping = address.item?.options_linked_to_cart_item?.find(
          (item) => item.option_uuid === SHIPPING_OPTION_UUID
        );
        return Boolean(itemShipping?.extra_data?.address);
      }
    } else {
      return Boolean(address.choice_uuid);
    }
  };

  const calculateAmountToBePayed = () => {
    const total_amount = cart?.total_price_with_coupon;
    return total_amount;
  };

  const onPayment = () => {
    openConfirmDialog()();
  };

  useEffect(() => {
    // Check that all the addresses have been selected
    const shippingAddressesAreSelected = shippingAddresses.every((adr) =>
      checkAllitemsHaveShipping(adr)
    );
    const shippingAddressesToUpdate = shippingAddresses.filter(
      (adr) => adr.changed
    );
    // Update the cart items that had their address changed
    if (shippingAddressesToUpdate?.length > 0) {
      dispatch({
        type: "SET_CARTITEM_SHIPPING",
        items: shippingAddressesToUpdate.map((adr) => ({
          id: adr.item.id,
          model: createFormData(adr),
        })),
        onSuccess: () => setIsShippingChecked(true),
      });

      setShippongSelectionInProgress(true);

      // this is for removing the duplicated requests by making the updated addresse status to not changed
      // because the changes already committed (PTACHED)
      setShippingAddresses(
        shippingAddresses.map((adr) => ({ ...adr, changed: false }))
      );
    }

    if (shippingAddressesAreSelected) {
      setIsShippingChecked(true);
    } else {
      setIsShippingChecked(false);
    }
  }, [shippingAddresses]);

  const disabelCheckout =
    inProgress || fetchCartInProgress || !isShippingChecked;

  const shippingAmount = useMemo(() => {
    return cart?.cart_items
      ?.filter((item) => {
        return (
          !item.is_primary_product &&
          item.salable_item_uuid === SHIPPING_OPTION_UUID
        );
      })
      .map((item) => item.total_price)
      .reduce((a, b) => a + b, 0);
  }, [cart]);

  const ItemsAmount = useMemo(() => {
    return cart?.cart_items
      ?.filter((item) => {
        return item.is_primary_product === true;
      })
      .map((item) => item.total_price)
      .reduce((a, b) => a + b, 0);
  }, [cart]);

  const cart_items = useMemo(() => {
    return cart?.cart_items?.filter((item) => {
      return item.is_primary_product === true;
    });
  });

  return (
    <React.Fragment>
      {cart_items && (
        <Paper className={classNames("", classes.cardShadow)}>
          {/* Shopping-cart Section  */}
          <Grid container>
            {/* ListItem Section */}
            <Box clone order={{ xs: 1, md: 1, lg: 1 }}>
              <Grid item md={12} lg={8} className="p-4 pb-8">
                <Card className={classes.cardShadow}>
                  <CardContent
                    style={{
                      padding: "0px",
                    }}
                  >
                    <GridList
                      cellHeight={"auto"}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                        overflow: "hidden",
                        backgroundColor: "primary",
                      }}
                    >
                      {/* ListItem Title */}
                      <GridListTile
                        key="Subheader"
                        cols={2}
                        style={{ height: "auto" }}
                      >
                        <Grid className={classes.title} item xs={12}>
                          <Typography
                            className={classes.titleText}
                            variant="overline"
                          >
                            <ListSubheader component="div">
                              <IntlMessages
                                id="Cart items :"
                                defaultMessage="Cart items"
                              />
                            </ListSubheader>
                          </Typography>
                        </Grid>
                      </GridListTile>
                      {/* END ListItem Title */}

                      {/* ListItem List */}
                      <List
                        sm={12}
                        style={{
                          backgroundColor: "#ffff",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        {cart_items.map((item, index) => {
                          // Item
                          return (
                            <React.Fragment key={item.id}>
                              <Divider style={{ height: "3px" }} />
                              <ListItem
                                key={item.id}
                                divider={
                                  index + 1 < cart_items.length ? true : false
                                }
                              >
                                <Grid
                                  container
                                  alignItems="center"
                                  className={
                                    index + 1 < cart_items.length ? "mb-2" : ""
                                  }
                                >
                                  <Hidden lgUp>
                                    <Grid item xs={12} className="py-8">
                                      <Divider />
                                    </Grid>
                                  </Hidden>

                                  {/* Item-content Grid */}
                                  <Grid container item xs={12} lg={12}>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      alignItems="center"
                                    >
                                      {/* Item-name */}
                                      <Grid item xs={12} md={8}>
                                        <Typography variant="h6">
                                          {
                                            item.salable_item
                                              .salable_item_mxn_name
                                          }
                                        </Typography>
                                      </Grid>
                                      {/* END Item-name */}

                                      {/* Item-total_price */}
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        md={4}
                                        justify="flex-end"
                                      >
                                        <Typography
                                          variant="overline"
                                          className="text-lg"
                                        >
                                          {item.total_price} <IntlMessages id="Euro" defaultMessage="Euro" />
                                        </Typography>
                                      </Grid>
                                      {/* END Item-total_price */}
                                    </Grid>

                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      className="pb-4"
                                      justify={
                                        !item.can_update_quantity && "flex-end"
                                      }
                                    >
                                      {item.can_update_quantity && (
                                        <Quantity
                                          {...item}
                                          initialQuantity={item.quantity}
                                        />
                                      )}
                                      <Grid
                                        item
                                        container
                                        xs={12}
                                        md={4}
                                        justify="flex-end"
                                      >
                                        <Button
                                          variant="outlined"
                                          size="small"
                                          color="secondary"
                                          onClick={() =>
                                            props.dispatch({
                                              type: "DELETE_ITEM_FROM_SECONDARY_CART",
                                              id: item.id,
                                            })
                                          }
                                        >
                                          <IntlMessages
                                            id="Retirer"
                                            defaultMessage="Retirer"
                                          />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                      {ShippingFields && (
                                        <ShippingFields
                                          disabled={shippongSelectionInProgress}
                                          shippingAddresses={shippingAddresses}
                                          setShippingAddresses={
                                            setShippingAddresses
                                          }
                                          item={item}
                                          enableCheckout={() =>
                                            setIsShippingChecked(true)
                                          }
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                      {!!ReservationPresentation &&
                                        !_.isEmpty(
                                          item.options_linked_to_cart_item
                                        ) &&
                                        item.options_linked_to_cart_item.map(
                                          (option, index) =>
                                            option.option_uuid ===
                                            RESERVATION_OPTION_UUID && (
                                              <ReservationPresentation
                                                key={index}
                                                startDate={
                                                  option.extra_data.start_date
                                                }
                                                periodicityId={
                                                  option.extra_data.periodicity
                                                }
                                                numPeriodicity={
                                                  option.extra_data
                                                    .num_periodicity
                                                }
                                              />
                                            )
                                        )}
                                    </Grid>
                                  </Grid>
                                  {/* END Item-content Grid */}
                                </Grid>
                              </ListItem>
                              {/* {index + 1 < cart_items.length ? (
                                                          <Divider style={{ height: "3px" }} />
                                                        ) : null} */}
                            </React.Fragment>
                          );
                          // END Item
                        })}
                      </List>
                      {/* END ListItem List */}
                    </GridList>

                    {USE_STRIPE_PURSHACE && (
                      <>
                        {user?.stripe_extension?.default_payment_method ? (
                          <>
                            <Grid
                              item={true}
                              xs={12}
                              md={12}
                              style={{ paddingLeft: 20 }}
                            >
                              {user.stripe_extension.default_payment_method
                                .type === "card" && (
                                  <h4>
                                    <IntlMessages
                                      id="La carte de paiement utilisée"
                                      defaultMessage="La carte de paiement utilisée"
                                    />
                                  </h4>
                                )}
                              {user.stripe_extension.default_payment_method
                                .type === "sepa_debit" && (
                                  <h4>
                                    <IntlMessages
                                      id="Le compte bancaire utilisée"
                                      defaultMessage="Le compte bancaire utilisée"
                                    />
                                  </h4>
                                )}
                            </Grid>
                            <Grid item={true} xs={12} md={12}>
                              <Paper elevation={6}>
                                <Grid container={true}>
                                  <Grid
                                    item={true}
                                    xs={12}
                                    md={9}
                                    style={{ paddingLeft: 20, paddingTop: 12 }}
                                  >
                                    {user.stripe_extension
                                      .default_payment_method.type ===
                                      "card" && (
                                        <p>
                                          {
                                            user.stripe_extension
                                              .default_payment_method.number
                                          }
                                        </p>
                                      )}
                                    {user.stripe_extension
                                      .default_payment_method.type ===
                                      "sepa_debit" && (
                                        <p>
                                          {
                                            user.stripe_extension
                                              .default_payment_method.iban
                                          }
                                        </p>
                                      )}
                                  </Grid>
                                  <Grid
                                    item={true}
                                    xs={12}
                                    md={3}
                                    style={{ padding: "20px 20px 20px 20px" }}
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      disableElevation
                                      fullWidth
                                      onClick={() =>
                                        history.push(
                                          "/profile?tab=update_stripe_payment_methods"
                                        )
                                      }
                                    >
                                      <IntlMessages
                                        id="Changer"
                                        defaultMessage="Changer"
                                      />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </>
                        ) : (
                          <div>
                            <Grid className={classes.title} item xs={12}>
                              <Typography
                                className={classes.titleText}
                                variant="overline"
                              >
                                <IntlMessages
                                  id="Payment method details :"
                                  defaultMessage="Payment method details :"
                                />
                              </Typography>
                            </Grid>
                            <Elements>
                              {/*
                                                                    Disable the sumbit button of stripe if the user condition are not checked
                                                                    or if the user is adding a coupon but didn't save it yet
                                                                */}

                              <StripeComponent
                                handlePayment={handlePayment}
                                formRef={stripeFormRef}
                              />
                            </Elements>
                          </div>
                        )}
                      </>
                    )}
                    {/* {Colissimo && <div className='px-4 my-10'><Colissimo cart={cart} enableCheckout={() => setIsShippingChecked(true)} /></div>} */}
                  </CardContent>
                </Card>
              </Grid>
            </Box>
            {/* END ListItem Section */}

            {/* Checkout Section */}
            <Box clone order={{ xs: 2, md: 2, lg: 2 }}>
              <Grid item md={12} lg={4} className="p-4">
                <Card className={classes.cardShadow}>
                  <CardContent
                    style={{
                      padding: "0px",
                    }}
                  >
                    {/*  */}
                    <GridList
                      cellHeight={"auto"}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                        overflow: "hidden",
                        backgroundColor: "primary",
                      }}
                    >
                      {/* Checkout Title */}
                      <GridListTile
                        key="Subheader"
                        cols={2}
                        style={{ height: "auto" }}
                      >
                        <Grid className={classes.title} item xs={12}>
                          <Typography
                            className={classes.titleText}
                            variant="overline"
                          >
                            <ListSubheader component="div">
                              <IntlMessages
                                id="Checkout :"
                                defaultMessage="Checkout "
                              />{" "}
                              {/* <strong>{cart_items.length}</strong> */}
                            </ListSubheader>
                          </Typography>
                        </Grid>
                      </GridListTile>
                      {/* END Checkout Title */}

                      {/*  */}
                      <Grid
                        container
                        style={{
                          backgroundColor: "#ffff",
                          width: "100%",
                          padding: "20px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography variant="overline">
                            <IntlMessages
                              id="Articles :"
                              defaultMessage="Articles :"
                            />{" "}
                            <strong>{cart_items.length}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="py-8">
                          <Divider />
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={8}>
                            <Typography variant="overline">
                              <IntlMessages
                                id="Subtotal :"
                                defaultMessage="Subtotal :"
                              />
                            </Typography>
                          </Grid>
                          <Grid container item xs={4} justify="flex-end">
                            <strong>{ItemsAmount} <IntlMessages id="Euro" defaultMessage="Euro" /></strong>
                          </Grid>
                        </Grid>
                        {ShippingFields && (
                          <Grid container item xs={12}>
                            <Grid item xs={8}>
                              <Typography variant="overline">
                                <IntlMessages
                                  id="Livraison :"
                                  defaultMessage="Livraison :"
                                />
                              </Typography>
                            </Grid>
                            <Grid container item xs={4} justify="flex-end">
                              <strong>{shippingAmount} <IntlMessages id="Euro" defaultMessage="Euro" /></strong>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container item xs={12}>
                          <Grid item xs={8}>
                            <Typography variant="overline">
                              <IntlMessages
                                id="Coût total :"
                                defaultMessage="Coût total :"
                              />
                            </Typography>
                          </Grid>
                          <Grid container item xs={4} justify="flex-end">
                            <strong>{cart.total_amount} <IntlMessages id="Euro" defaultMessage="Euro" /></strong>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={8}>
                            <Typography variant="overline">
                              <IntlMessages
                                id="Montant à payer : / secondary cart"
                              />
                            </Typography>
                          </Grid>
                          <Grid container item xs={4} justify="flex-end">
                            <strong>{calculateAmountToBePayed()} <IntlMessages id="Euro" defaultMessage="Euro" /></strong>
                          </Grid>
                        </Grid>
                        {/* TODO unhide promo and cash back when they are ready */}
                        <PromoCode />
                        {/* adding checkbox*/}
                        <Grid
                          container
                          item
                          className="mb-6 pt-6"
                          justify="center"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="accept CGU"
                                required
                                checked={isConditionsChecked}
                                onChange={(event) =>
                                  handleConditionsChecked(event.target.checked)
                                }
                              />
                            }
                            label={
                              <a
                                target="_blank"
                                href={`${BASE_URL}${props.intl.formatMessage({
                                  id: "cgu_url",
                                  defaultMessage: "cgu_url",
                                })}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <IntlMessages id="J'accepte les CGV" />
                              </a>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="accept charte"
                                required
                                checked={isCharteChecked}
                                onChange={(event) =>
                                  setIsCharteChecked(event.target.checked)
                                }
                              />
                            }
                            label={
                              <a
                                target="_blank"
                                href={`${BASE_URL}${props.intl.formatMessage({
                                  id: "charte_url",
                                  defaultMessage: "charte_url",
                                })}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <IntlMessages id="J'accepte la charte" />
                              </a>
                            }
                          />
                          {USE_STRIPE_PURSHACE ? (
                            <Button
                              className="w-40"
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setDisable(true);
                                if (
                                  user?.stripe_extension?.default_payment_method
                                ) {
                                  handlePaymentByDefaultPaymentMethod();
                                } else {
                                  stripeFormRef.current &&
                                    stripeFormRef.current.handleSubmit();
                                }
                              }}
                              disabled={
                                (showPromoCodeField && !addedPromoCode) ||
                                !isConditionsChecked ||
                                !isCharteChecked ||
                                inProgress ||
                                disableBeforeValidation
                              }
                            >
                              <IntlMessages id="Pay" defaultMessage="Pay" />
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={onPayment}
                              disabled={
                                disabelCheckout ||
                                !isConditionsChecked ||
                                !isCharteChecked
                              }
                            >
                              <IntlMessages
                                id="Paiement"
                                defaultMessage="Paiement"
                              />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                      {/*  */}
                    </GridList>
                    {/*  */}
                  </CardContent>
                </Card>
              </Grid>
            </Box>
            {/* END Sheckout Section */}
          </Grid>
          {/* END Shopping-cart Section  */}
        </Paper>
      )}
    </React.Fragment>
  );
}

export default withRouter(
  connect((state) => {
    return {
      user: state.auth.user,
      inProgress:
        state.purchaseReducer.inProgress ||
        state.secondaryShoppingCartReducer.inProgress,
      cart: state.secondaryShoppingCartReducer.cart,
    };
  })(injectIntl(ShoppingCartList))
);
