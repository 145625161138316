import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
} from "@material-ui/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { create } from "jss";
import jssExtend from "jss-extend";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Router } from "react-router-dom";
import Authorization from "react-platform-user/dist/Authorization/Authorization";
import { BASE_APP_URL, ENV } from "./configs";
import { withTranslationProvider } from "react-platform-components/dist/Translations/hoc";
import { App } from "./containers";
import history from "./history";
import { runSaga, store } from "./redux";
import { routes } from "./init";
import * as serviceWorker from "./serviceWorker";
import "./styles/helpers.css";
import "./styles/styles.css";
import { STRIPE_KEY } from "./configs";
import { StripeProvider } from "react-stripe-elements";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { PreloadApplication } from "react-platform-components/dist/PreloadApplication/PreloadApplication";

if (ENV != "dev") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: ENV,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00AABF",
      contrastText: "#ffff",
    },
    secondary: {
      main: "#91BB54",
      contrastText: "#ffff",
    },
    third: {
      main: "#00aabf87",
      secondary: "#00aabf9c",
      third: "#f5f5f5",
      dark: "rgb(0, 150, 168)",
      contrastText: "#000000",
      noContrastText: "#FFFFFF",
    },
    custom: {
      headerOfBaseContainer: {
        backgroundColor: "#f5f5f5",
        color: "white",
      },
    },
  },
  typography: {
    fontFamily: "Comfortaa",
    primary: {
      main: "#7A7A7A",
    },
    secondary: {
      main: "#91BB54",
    },
    h1: {
      fontSize: "1,875rem",
      fontWeight: 600,
      color: "#3B3C3E",
    },
    h2: {
      fontSize: "1.375rem",
      fontWeight: 400,
    },
    h3: {
      lineHeight: "21px",
      fontSize: "20px",
      fontWeight: "bold",
    },
    h5: {
      color: 'unset',
      textTransform: 'unset',
      letterSpacing: 'unset',
    }
  },
  spacing: 10,
  shape: {
      borderRadius: 8,
  }
});

const jss = create({
  ...jssPreset(),
  insertionPoint: "jss-insertion-point",
  plugins: [...jssPreset().plugins, jssExtend()],
});

jss.options.createGenerateClassName = createGenerateClassName;

const generateClassName = createGenerateClassName();

runSaga();

const RootComponent = withTranslationProvider(() => {
  // we use React router here becuse we need it in the root component
  return (
    <Router history={history}>
      <BrowserRouter basename={BASE_APP_URL}>
        <PreloadApplication>
          <Authorization routes={routes}>
            <App />
          </Authorization>
        </PreloadApplication>
      </BrowserRouter>
    </Router>
  );
});

ReactDOM.render(
  <StylesProvider injectFirst jss={jss} generateClassName={generateClassName}>
    <StripeProvider apiKey={STRIPE_KEY}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <RootComponent />
        </Provider>
      </MuiThemeProvider>
    </StripeProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
