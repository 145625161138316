import Profile from "./Profile";
import { profileSaga } from "./ProfileActions";
import profileReducer from "./ProfileReducer";

export const ProfileConfig = {
  auth: ["user"],
  routes: [
    {
      component: Profile,
      path: "/profile",
    },
    {
      component: Profile,
      path: "/mangopay-info",
    },
  ],
  actions: profileSaga,
  reducer: profileReducer,
};
