import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { IntlMessages } from "react-platform-components/dist/Translations";

export const purchaseStatus = (status) => {
  switch (status) {
    case "waiting":
      return <IntlMessages id="waiting" />;
    case "async_waiting":
      return <IntlMessages id="async_waiting" />;
    case "aborted":
      return <IntlMessages id="aborted" />;
    case "reserved":
      return <IntlMessages id="reserved" />;
    case "cancelled":
      return <IntlMessages id="cancelled" />;
    case "failed":
      return <IntlMessages id="failed" />;
    case "finished":
      return <IntlMessages id="finished" />;
    default:
      return "";
  }
};
// Using purchaseStatus function because IntlMesssages need static values this is why we need to add a translation for each value.
function PurchasePointList(props) {
  const [expanded, handleExpanded] = useState([]);

  function isSelected(id) {
    return expanded.indexOf(id) !== -1;
  }

  function handlerClickExpandDemand(purchase) {
    const selectedIndex = expanded.indexOf(purchase.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(expanded, purchase.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(expanded.slice(1));
    } else if (selectedIndex === expanded.length - 1) {
      newSelected = newSelected.concat(expanded.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        expanded.slice(0, selectedIndex),
        expanded.slice(selectedIndex + 1)
      );
    }

    handleExpanded(newSelected);
  }

  const { classes } = props;
  const purchases = _.filter(props.products, (purchase) =>
    _.find(purchase.purchase_salable_items, ({is_primary_product,salable_item_class}) => 
      is_primary_product === true && ["SalablePoint", "SalablePack"].includes(salable_item_class),
    )
  );

  return (
    <Grid container className="mb-8">
      {purchases.map((purchase) => {
        return (
          <Grid item xs={12} className="px-4 pt-4" key={purchase.id}>
            <Card className={classes.cardShadow}>
              <CardContent>
                <Grid container>
                  <Box display="flex" flexDirection="column" flexGrow="1">
                    <Grid container>
                      <Typography
                        variant="overline"
                        style={{ fontSize: "0.65rem" }}
                      >{`${moment(purchase.created_at).fromNow()} (${
                        purchase.created_at
                      })`}</Typography>
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <Tooltip
                          title={
                            <IntlMessages
                              id="Commande"
                              defaultMessage="Commande"
                            />
                          }
                          placement="top"
                        >
                          <Chip
                            className="mr-2"
                            label={
                              <>
                                <IntlMessages
                                  id="Commande N°:"
                                  defaultMessage="Commande N°:"
                                />{" "}
                                {purchase.id}
                              </>
                            }
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title={
                            <IntlMessages
                              id="Coût total"
                              defaultMessage="Coût total"
                            />
                          }
                          placement="top"
                        >
                          <Chip
                            className="mr-2"
                            icon={
                              <Icon style={{ fontSize: "18px" }}>receipt</Icon>
                            }
                            label={<>{purchase.total_price} €</>}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    display="flex"
                    flexGrow="1"
                    className="pt-2 md:pt-0"
                    style={{ justifyContent: "flex-end", alignItems: "center" }}
                  >
                    <Grid item className="pr-2">
                      <Chip
                        label={purchaseStatus(purchase.status)}
                        color="primary"
                      />
                    </Grid>
                    {purchase.purchase_salable_items
                      .filter((item) => item.is_primary_product)
                      .map((item, index) => (
                        <Grid item>
                          <Typography
                            component="span"
                            variant="overline"
                            color="textPrimary"
                          >
                            <IntlMessages
                              id="Quantity :"
                              defaultMessage="Quantity :"
                            />
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            component="span"
                          >
                            {item.salable_item_class === "SalablePack" ? item.salable_item.roadpoints_number : item.quantity} RP
                          </Typography>
                        </Grid>
                      ))}
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default PurchasePointList;
