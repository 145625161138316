import React, { Component } from "react";
import { useTitle } from "react-use";
import { Grid, Icon, Typography, Paper, Button } from "@material-ui/core";
import { IntlMessages, injectIntl } from "react-platform-components/dist/Translations";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function SecondaryPurchaseResult(props) {
  const params = useParams();
  const isSuccess = params.type === "success" ? true : false;
  const color = isSuccess ? "#29a538" : "#ef3e3e";

  useTitle(
      isSuccess
          ? props.intl.formatMessage({
                id: 'Payment successful page title',
                defaultMessage: 'Payment successful page title',
            })
          : props.intl.formatMessage({
                id: 'Payment failure page title',
                defaultMessage: 'Payment failure page title',
            }),
  );

  return (
    <Paper className="p-12 m-12">
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Icon
            style={{
              color: color,
              fontSize: "8rem",
              border: `2px solid ${color}`,
              borderRadius: "100px",
            }}
          >
            {isSuccess ? "check" : "close"}
          </Icon>
        </Grid>
        <Grid
          style={{ marginTop: "3rem" }}
          item
          xs={12}
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="h5">
            {isSuccess ? (
              <IntlMessages
                id="Payment Successful!"
                defaultMessage="Payment Successful!"
              />
            ) : (
              <IntlMessages
                id="Payment failed!"
                defaultMessage="Payment failed !"
              />
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="body1">
            {isSuccess ? (
              <IntlMessages
                id="payment sucess description"
                defaultMessage="payment sucess description"
              />
            ) : (
              <IntlMessages
                id="payment failed description"
                defaultMessage="payment failed description"
              />
            )}
          </Typography>
        </Grid>
        {!isSuccess && (
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Link to="/cart" variant="body2" className="mt-8">
              <Button variant="contained" color="primary">
                <IntlMessages id="Réessayer" defaultMessage="Réessayer" />
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

export default injectIntl(SecondaryPurchaseResult);
