import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { injectToBaseContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { connect } from "react-redux";
import SecondaryShoppingCartList from "./SecondaryShoppingCartList";

const styles = (theme) => ({
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
  },
});

/**
 * We create our class by using injectToBaseContainer
 * this will return a React Component that is wrapped with the HOCs
 * inside flowRight, and hass all the props that are passed as options
 */
export default injectToBaseContainer(
  _.flowRight(
    withStyles(styles),
    connect((state) => {
      return {
        products: state.secondaryShoppingCartReducer.cart,
      };
    })
  ),
  {
    listComponent: SecondaryShoppingCartList,
    // Overrid componentDidMount of the BaseContainer to fetch the cart
    componentDidMount: (props) => {
      props.dispatch({
        type: "FETCH_SECONDARY_CART",
        params: { _embed: ["cart_items.salable_item"] },
      });
    },
    headerTitle: "Mon Second Panier",
    headerIcon: "add_shopping_cart",
    confirmTitle: "Êtes-vous sûr de vouloir procéder au paiement?",
    // Overrid the confirmation action of the ConfirmationDialog
    // NOTE: this NEEDS to be a function expression and NOT an arrow function
    confirmAction: function () {
      this.props.dispatch({ type: "PURCHASE" });
      this.closeConfirmDialog();
    },
  }
);
