import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { injectToBaseContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { connect } from "react-redux";
import BuyerPurchasePointList from "./BuyerPurchasePointList";
import { FETCH_BUYER_PURCHASES } from "react-platform-purchase/dist/Purchase/PurchaseActions";

const styles = (theme) => ({
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
  },
});

/**
 * We create our class by using injectToBaseContainer
 * this will return a React Component that is wrapped with the HOCs
 * inside flowRight, and hass all the props that are passed as options
 */
export default injectToBaseContainer(
  _.flowRight(
    withStyles(styles),
    connect((state) => {
      return {
        products: state.purchaseReducer.purchases,
      };
    })
  ),
  {
    listComponent: BuyerPurchasePointList,
    FETCH_ACTION: FETCH_BUYER_PURCHASES,
    hasHeader: false,
  }
);
