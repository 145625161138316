import ShoppingCart from "./ShoppingCartContainer";
import { shoppingCartOfReservationSaga } from "./ShoppingCartActions";

export const ShoppingCartOfReservationConfig = {
  auth: ["user"],
  routes: [
    {
      component: ShoppingCart,
      path: "/cart",
    },
  ],
  actions: shoppingCartOfReservationSaga,
  // we use also the actions and reducer of shoppgin cart
};
