import { all, put, takeEvery } from "redux-saga/effects";
import axios from "react-platform-components/dist/axios";
import {
  BASE_API_URL,
  BASE_APP_URL,
  BASE_URL,
  SALABLE_POINT_UUID,
} from "../../configs";
import { handleErrors } from "react-platform-components/dist/Utils";

export function* purchasePoints(action) {
  yield put({ type: "PURCHASE_POINT_START" });
  try {
    // Get purchase
    const purchaseResponse = yield axios.post(
      `${BASE_API_URL}secondary-purchases`
    );
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    const params = action.params || {};
    // Use purchase id to init the Mangopay transaction
    const paymentResponse = yield axios.get(
      `${BASE_URL}stripe/init_payment_and_transaction/${purchaseResponse.data.id}`,
      {
        data: {}, // When creating a json request a json data is required or axios ignores the Content-Type
        headers,
        params,
      }
    );

    if (paymentResponse.data.success || paymentResponse.data.processing) {
      // processing apear when we use sepa_debit payment method
      yield put({ type: "PURCHASE_POINT_SUCCESS" });
      yield put({ type: "FETCH_USER" });
      if (action.onSuccess)
        action.onSuccess({
          status: paymentResponse.data.success ? "success" : "pending",
        });
    } else if (paymentResponse.data.url) {
      // redirect to handle 3D secure
      window.location.href = paymentResponse.data.url;
    }
  } catch (error) {
    yield put({ type: "PURCHASE_POINT_ERROR" });
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

export function* fetchPacket() {
  yield put({ type: "FETCH_PACKETS_START" })
  try {
      const response = yield axios.get(`${BASE_API_URL}packs`)
      yield put({ type: "FETCH_PACKETS_SUCCESS", payload: response.data })
  } catch (error) {
      yield put({ type: "FETCH_PACKETS_ERROR" })
  }
}

export function* purchasePointeSaga() {
  yield all([takeEvery("PURCHASE_POINT", purchasePoints)]);
  yield all([takeEvery("FETCH_PACKETS", fetchPacket)]);
}
