import { all, put, takeEvery } from "redux-saga/effects";
import axios from "react-platform-components/dist/axios";
import { handleErrors } from "react-platform-components/dist/Utils";
import { BASE_API_URL } from "../../configs";
import {
  findExpireDate,
  dateIsExpired,
} from "react-platform-stripesubscriptions/dist/helpers";

export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const FETCH_SUBSCRIPTIONS_START = "FETCH_SUBSCRIPTIONS_START";
export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";
export const PAY_SUBSCRIPTION = "PAY_SUBSCRIPTION";
export const PAY_SUBSCRIPTIONS_SUCCESS = "PAY_SUBSCRIPTIONS_SUCCESS";
export const PAY_SUBSCRIPTIONS_START = "PAY_SUBSCRIPTIONS_START";
export const UPDATE_IDENTIFICATION_START = "UPDATE_IDENTIFICATION_START";
export const UPDATE_IDENTIFICATION_SUCCESS = "UPDATE_IDENTIFICATION_SUCCESS";

export function* fetchSubscriptions(action) {
  yield put({ type: FETCH_SUBSCRIPTIONS_START });
  try {
    const purchasedSubscriptionsUUIDs = [];
    const params = action.params || {};
    const response = yield axios.get(`${BASE_API_URL}stripe-subscriptions`, {
      params,
    });
    const purchasesResponse = yield axios.get(`${BASE_API_URL}purchases`, {
      params,
    });

    purchasesResponse.data.forEach((purchase) => {
      if (purchase.status === "finished") {
        purchase.purchase_salable_items.forEach((item) => {
          // here we check if we have an expired cancelled subscription
          // if we found one we show it in the list of subscriptions.
          const expireDate = findExpireDate(item.started_at, item.duration);
          const subscriptionExpired = dateIsExpired(expireDate);
          if (subscriptionExpired || item.is_cancelled) {
            return;
          }

          purchasedSubscriptionsUUIDs.push(item.salable_item.uuid);
        });
      }
    });

    const filteredResponse = response.data.filter(
      (subscription) => subscription.can_be_purchased_more_than_once || !purchasedSubscriptionsUUIDs.includes(subscription.uuid)
    );

    yield put({ type: FETCH_SUBSCRIPTIONS_SUCCESS, payload: filteredResponse });
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

export function* paySubscription(action) {
  yield put({ type: PAY_SUBSCRIPTIONS_START });
  try {
    if (action.subscription.type === "costum") {
      yield put({ type: PAY_SUBSCRIPTIONS_SUCCESS });
      yield put({
        type: "REDIRECT",
        to: `/subscriptions-products/${action.subscription.uuid}/${action.option_uuid}`,
        history: action.history,
      });
    } else {
      const options = action.right_ids && [
        {
          option_uuid: action.option_uuid,
          option_class: "OptionOfSalableOfPlatform",
          type: "str",
          extra_data: JSON.stringify({
            right_ids: action.right_ids,
          }),
        },
      ];
      yield axios.post(`${BASE_API_URL}secondary-cartitems`, {
        salable_item_class: "INTERNALStripeSubscription",
        salable_item_uuid: action.subscription.uuid,
        options: options,
      });
      yield put({ type: PAY_SUBSCRIPTIONS_SUCCESS });
      yield put({
        type: "REDIRECT",
        to: `/secondary-cart/`,
        history: action.history,
      });
    }
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error, true) });
  }
}

export function* addPickedProducts(action) {
  const right_ids = action.products.map((element) => {
    return element.right.id;
  });
  yield put({
    type: PAY_SUBSCRIPTION,
    subscription: { uuid: action.uuid },
    option_uuid: action.option_uuid,
    right_ids,
    history: action.history,
  });
  // yield put({ type: 'ADD_PICKED_PRODUCTS', products: action.products })
  // yield put({ type: 'REDIRECT', to: `/secondary-cart`, history: action.history })
}

export function* updateIdentification(action) {
  yield put({ type: UPDATE_IDENTIFICATION_START });
  try {
    const response = yield axios.patch(
      `${BASE_API_URL}users/${action.id}`,
      action.model,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    yield put({ type: UPDATE_IDENTIFICATION_SUCCESS, payload: response.data });
    yield put({
      type: "REDIRECT",
      to: action.redirectTo,
      history: action.history,
    });
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

export function* subscriptionsSaga() {
  yield all([
    takeEvery(FETCH_SUBSCRIPTIONS, fetchSubscriptions),
    takeEvery(PAY_SUBSCRIPTION, paySubscription),
    takeEvery("PICKED_PRODUCTS", addPickedProducts),
    takeEvery("UPDATE_IDENTIFICATION", updateIdentification),
  ]);
}
