import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { injectToBaseContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { SellerPurchaseDetails } from "./SellerPurchaseDetails";

const styles = () => ({
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
  },
});

/**
 * We create our class by using injectToBaseContainer
 * this will return a React Component that is wrapped with the HOCs
 * inside flowRight, and hass all the props that are passed as options
 */
export const SellerPurchaseDetailsContainer = injectToBaseContainer(
  _.flowRight(withStyles(styles)),
  {
    listComponent: SellerPurchaseDetails,
    headerTitle: "Mes Commandes",
    headerIcon: "shopping_cart",
  }
);
