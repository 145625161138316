import SecondaryShoppingCart from "./SecondaryShoppingCartContainer";
import { secondaryShoppingCartSaga } from "./SecondaryShoppingCartActions";
import secondaryShoppingCartReducer from "./SecondaryShoppingCartReducer";

export const SecondaryShoppingCartConfig = {
  auth: ["user"],
  routes: [
    {
      component: SecondaryShoppingCart,
      path: "/secondary-cart",
    },
  ],
  reducer: secondaryShoppingCartReducer,
  actions: secondaryShoppingCartSaga,
};
