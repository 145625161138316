import React from "react"
import StripeComponent from "react-platform-purchase/dist/Purchase/Stripe"
import { IntlMessages } from "react-platform-components/dist/Translations"
import { Elements } from "react-stripe-elements"
import { Paper, Grid, Button } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"

export const UpdateCreditCard = (props) => {
    const { user, stripeFormRef } = props
    const history = useHistory()
    const dispatch = useDispatch()

    const handlePayment = (response) => {
        // we use this for make payment by new payment method generated form the frontend
        if (!response) {
            // if there is no response show error snackbar
            dispatch({
                type: "HANDLE_ERROR",
                error: <IntlMessages id='Something went wrong!' defaultMessage='Something went wrong!' />,
            })
        } else if (response.error) {
            // if there are errors in the response show them in snackbar
            dispatch({ type: "HANDLE_ERROR", error: response.error.message })
        } else {
            // if there are no errors, make the purchase with or whithout the coupon
            dispatch({
                type: "PURCHASE_POINT",
                params: { payment_method_token: response.id },
                onSuccess: ({ status }) => {
                    if (status === "success") {
                        history.replace("/purchase-success")
                    } else {
                        // case when we use sepa_debit payment method
                        history.replace("/purchase-pending")
                    }
                },
            })
        }
    }

    return (
        <Grid item={true} xs={12}>
            {user?.stripe_extension?.default_payment_method ? (
                <>
                    <Grid item={true} xs={12} md={12}>
                        <Paper elevation={6}>
                            <Grid container={true}>
                                <Grid item={true} xs={12} md={3} style={{ paddingLeft: 20, paddingTop: 12 }}>
                                    {user.stripe_extension.default_payment_method.type === "card" && (
                                        <p>{user.stripe_extension.default_payment_method.number}</p>
                                    )}
                                    {user.stripe_extension.default_payment_method.type === "sepa_debit" && (
                                        <p>{user.stripe_extension.default_payment_method.iban}</p>
                                    )}
                                </Grid>
                                <Grid item={true} xs={12} md={5}>
                                    {user.stripe_extension.default_payment_method.type === "card" && (
                                        <h4>
                                            <IntlMessages
                                                id='La carte de paiement utilisée'
                                                defaultMessage='La carte de paiement utilisée'
                                            />
                                        </h4>
                                    )}
                                    {user.stripe_extension.default_payment_method.type === "sepa_debit" && (
                                        <h4>
                                            <IntlMessages
                                                id='Le compte bancaire utilisée'
                                                defaultMessage='Le compte bancaire utilisée'
                                            />
                                        </h4>
                                    )}
                                </Grid>
                                <Grid item={true} xs={12} md={4} style={{ padding: "20px 20px 20px 20px" }}>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        disableElevation
                                        fullWidth
                                        onClick={() =>
                                            history.push("/profile?tab=update_stripe_payment_methods")
                                        }>
                                        <IntlMessages id='Changer' defaultMessage='Changer' />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </>
            ) : (
                <Paper elevation={1}>
                    <Elements>
                        <StripeComponent formRef={stripeFormRef} handlePayment={handlePayment} />
                    </Elements>
                </Paper>
            )}
        </Grid>
    )
}
