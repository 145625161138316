import React from "react";
import _ from "lodash";
import { Link } from "@material-ui/core";
import ExtensionIcon from "@material-ui/icons/Extension";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { ReservationDetails as DefaultReservationDetails } from "react-platform-reservation/dist/ReservationDetailsOfPurchase/ReservationDetails";
import { ReservationInfo } from "react-platform-reservation/dist/ReservationDetailsOfPurchase/components/ReservationInfo";

export const ReservationDetailsOfBuyer = (props) => {
  const {
    purchaseItem: {
      type_of_exchange: typeOfExchange,
      salable_item: { roadbooks },
    },
  } = props;

  return (
    <DefaultReservationDetails {...props}>
      <ReservationInfo
        title={
          <IntlMessages
            id="Type de réservation"
            defaultMessage="Type de réservation"
          />
        }
        value={typeOfExchange}
        icon={<ExtensionIcon />}
      />
      {!_.isEmpty(roadbooks) && (
        <ReservationInfo
          title={<IntlMessages id="Roadbook" defaultMessage="Roadbook" />}
          value={_.map(roadbooks, (roadbook, index) => {
            return (
              <>
                <Link
                  href={roadbook.url}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IntlMessages id={"Document_"} defaultMessage={"Document_"} />{" "}
                  {roadbooks.length === 1 ? "" : index + 1}
                </Link>
                &nbsp;&nbsp;
              </>
            );
          })}
          icon={<MenuBookIcon />}
        />
      )}
    </DefaultReservationDetails>
  );
};
