import React from "react"
import { Grid, Chip, Tooltip } from "@material-ui/core"
import {
    LocationPicker,
    AddressAutocomplete,
} from "react-platform-components/dist/form-fields"
import { required } from "react-platform-components/dist/form-validators"
import { useCountries } from "react-platform-components/dist/hooks/useCountries"
import { useSelector } from "react-redux"
import CloseIcon from "@material-ui/icons/Close"
import { useFormikContext } from "formik"
import { MenuItem, Checkbox, ListItemText } from "@material-ui/core"
import { Select } from "react-platform-components/dist/form-fields"
import IntlMessages from "react-platform-components/dist/Translations/IntlMessages"
import { TextField } from "react-platform-components/dist/form-fields/index"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Skeleton from "@material-ui/lab/Skeleton"

export const Fields = (props) => {
    const { initialValues, classes } = props
    const { values, setFieldValue, initialErrors } = useFormikContext()

    const { categories, InProgress } = useSelector(({ categoryOfProductReducer }) => ({
        categories: categoryOfProductReducer.categories,
        InProgress: categoryOfProductReducer.fetchCategoriesInProgress,
    }))
    const { countires } = useCountries()
    const handleDeleteCountry = (countryToDelete) => () => {
        const countryIndex = values.countries.findIndex((country) => country === countryToDelete)
        setFieldValue(
            "countries",
            values.countries.filter((country) => country !== countryToDelete)
        )
        setFieldValue(
            "countriesCodes",
            values.countriesCodes.filter((_, idx) => idx !== countryIndex)
        )
    }
    return (
        <Grid container spacing={2} xs={12} md={12}>
            <Grid item xs={12}>
                <NameField />
            </Grid>
            <Grid item xs={12}>
                <MListField categories={categories} InProgress={InProgress} />
            </Grid>
            <Grid item xs={12}>
                <AddressAutocomplete
                    fullWidth
                    required={false}
                    label='Address'
                    name='country'
                    updateAfterTyping
                    isForced={true}
                    filterCountries={true}
                />
                {values.countries.map((country) => (
                    <Chip
                        key={country}
                        label={country}
                        color='primary'
                        variant='outlined'
                        onDelete={handleDeleteCountry(country)}
                        deleteIcon={<CloseIcon />}
                        className={classes.chip}
                    />
                ))}
            </Grid>
            <Grid item xs={12}>
                <LocationPicker
                    initialValues={initialValues}
                    values={values}
                    setFieldValue={setFieldValue}
                    initialErrors={initialErrors}
                    countries={countires}
                    dispatch={()=>{}}
                />
            </Grid>
        </Grid>
    )
}
export const MListField = ({ categories, InProgress }) => {
    const { setFieldValue, values } = useFormikContext()
    const value = values["categories"]
    return (
        <Select
            variant='outlined'
            fullWidth
            margin='normal'
            value={value || []}
            label='Select Categories'
            name='categories'
            onChange={(event) => {
                setFieldValue("categories", event.target.value)
            }}
            renderValue={(selectedValues) =>
                selectedValues
                    .map((value) => categories?.find((choice) => choice.id === value)?.name)
                    .join(", ")
            }
            multiple>
            {InProgress
                ? [1, 2, 3, 4].map((choice) => (
                      <MenuItem key={choice.id} value={choice.id}>
                          <ListItemText primary={<Skeleton animation='wave' variant='body1' />} />
                      </MenuItem>
                  ))
                : categories &&
                  categories.map((choice) => (
                      <MenuItem key={choice.id} value={choice.id}>
                          <Checkbox checked={value.includes(choice.id)} />
                          <ListItemText primary={choice.name} />
                      </MenuItem>
                  ))}
        </Select>
    )
}

export const NameField = () => {
    return (
        <TextField
            name='name'
            label="Nom de zone d'alerte"
            margin='normal'
            variant='outlined'
            fullWidth
            required={true}
            validate={required()}
            placeholder="Nom de zone d'alerte"
            endAdornment={
                <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' edge='end'>
                        <Tooltip title={<IntlMessages id="Citez simplement le nom de votre zone d'alerte" />}>
                            <HelpOutlineIcon />
                        </Tooltip>
                    </IconButton>
                </InputAdornment>
            }
        />
    )
}
