import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Icon,
} from "@material-ui/core";
import qs from "qs";
import { useTitle } from 'react-use'
import { useHistory } from "react-router-dom";
import IntlMessages from "react-platform-components/dist/Translations/IntlMessages";
import { STATIC_PATH } from "../../configs";
import ShoppingCartPointContainer from "./ShoppingCartPointContainer";
import BuyerPurchasePointContainer from "../Purchase/BuyerPurchasePoint/BuyerPurchasePointContainer";
import { injectIntl } from "react-platform-components/dist/Translations";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#3b3b3b",
    color: "#fff",
    fontSize: 55,
    height: 96,
    textDecoration: "none",
    width: 96,
  },
  header: {
    display: "flex",
    minHeight: "10vh",
    padding: theme.spacing(5, 4, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
  },
  heading: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium
  },
  },
}));

export default injectIntl(function PurchasePoints(props) {
  const classes = useStyles();
  const history = useHistory();

  const [tab, setTab] = useState(history.location.pathname);
  useTitle(
      tab === '/purchase-points'
          ? props.intl.formatMessage({
                id: 'Purchase points title page',
                defaultMessage: 'Purchase points title page',
            })
          : props.intl.formatMessage({
                id: 'Buyer purchase points title page',
                defaultMessage: 'Buyer purchase points title page',
            }),
  );

  const handlerTabChanged = (event, tab) => {
    setTab(tab);
    history.push({ pathname: tab });
  };

  const renderTab = () => {
    switch (tab) {
      case "/purchase-points":
        return <ShoppingCartPointContainer />;
      case "/buyer-purchases-points":
        return <BuyerPurchasePointContainer />;
      default:
        return <ShoppingCartPointContainer />;
    }
  };
  return (
    <div>
      <div
        className={`pt-12 pb-8 flex flex-1 flex-col items-center justify-center md:flex-row md:items-end ${classes.header}`}
      >
        <div className="flex flex-1 items-center justify-center flex-row md:items-center md:justify-start white">
          <Icon fontSize="default" className="mr-2">
            money
          </Icon>
          <Typography className={classes.heading} variant="h4">
            <IntlMessages id='Acheter des RoadPoints' />
          </Typography>
        </div>
        <div className="flex items-center justify-end" />
      </div>
      <Paper>
        <Tabs
          value={tab}
          onChange={handlerTabChanged}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab
            className="h-16"
            label={
              <IntlMessages
                id="Acheter"
              />
            }
            value="/purchase-points"
          />
          <Tab
            className="h-16"
            label={
              <IntlMessages
                id="Mes Achats (Points)"
              />
            }
            value="/buyer-purchases-points"
          />
        </Tabs>
      </Paper>
      {renderTab()}
    </div>
  );
})
