import React from "react";
import {
  Icon,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Chip,
  Divider,
  useMediaQuery
} from "@material-ui/core";
import {
  findExpireDate,
  dateIsExpired,
} from "react-platform-stripesubscriptions/dist/helpers";
import { IntlMessages } from "react-platform-components/dist/Translations";

export default function SubscriptionPurchaseItem(props) {
  const {
    item,
    subscription,
    index,
    setDeleteOpen,
    setSelectedSubscription,
    setModifyOpen,
  } = props;
  const expireDate = findExpireDate(
    subscription.started_at,
    subscription.duration
  );
  const isExpired = dateIsExpired(expireDate);
  const isMd = useMediaQuery("(max-width: 810px)");

  return (
    !isExpired && (
      <ListItem
        button
        divider={index + 1 < item.purchase_salable_items.length ? true : false}
        key={index}
      >
        <ListItemText
          primary={
            <>
              <Grid container alignItems="center">
                <Grid item md={1} xs={2}>
                  <Typography variant="subtitle2">
                    {`${item.total_price} €`}
                  </Typography>
                </Grid>
                <Grid container item md={11} xs={10}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {subscription.salable_item.name}
                      {subscription.is_cancelled && !isMd && (
                        <Chip
                          label={
                            <IntlMessages
                              id="Cancelled"
                              defaultMessage="Cancelled"
                            />
                          }
                          color="primary"
                          size="small"
                          className="ml-4"
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="overline">
                      <IntlMessages
                        id="Expires at :"
                        defaultMessage="Expires at :"
                      />
                      <span className="ml-2 mr-4">
                        {expireDate.format("D MMMM YYYY")}
                      </span>
                    </Typography>
                    {subscription.is_cancelled && isMd && (
                        <Chip
                          label={
                            <IntlMessages
                              id="Cancelled"
                              defaultMessage="Cancelled"
                            />
                          }
                          color="primary"
                          size="small"
                          className="ml-4"
                        />
                      )}
                  </Grid>
                </Grid>
              </Grid>
              {isMd && <Divider />}
            </>
          }
        />
        {!subscription.is_cancelled && (
          <ListItemSecondaryAction>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                setDeleteOpen(true);
                setSelectedSubscription(subscription);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    )
  );
}
