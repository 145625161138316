import React from "react"

import { ImageButtons as ImageButtonsDefault } from "react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/ImageButtons"
import { CautionView } from "./CautionView"
import { UploadDocuments } from "./UploadDocuments"

export const ImageButtons = (props) => {
  const renderExtraActionsButtons = (product) =>
    [CautionView, UploadDocuments].map((ExtraButtons, index) => (
      <ExtraButtons key={index} product={product} />
    ))

  return <ImageButtonsDefault {...props} renderExtraActionsButtons={renderExtraActionsButtons} />
}
