import React from 'react'
import { Grid } from '@material-ui/core'

import { IntlMessages } from "react-platform-components/dist/Translations"
import { TopChips as DefaultTopChips } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/TopChips'
import { ProductStatus } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/ProductStatus'
import { TAG_CERTIFIE_UUID } from '../../../configs'

export const TopChips = (props) => {
    const findTagByUUID = (UUID) => {
        return props.product.tags.find((tag) => tag.uuid === UUID)
    }
    const renderExtraChips = () => {
        return (
            !findTagByUUID(TAG_CERTIFIE_UUID) && (
                <Grid item>
                    <ProductStatus
                        product={{
                            status: 'waiting',
                            translated_status: <IntlMessages id="non-certifié" defaultMessage="non-certifié" />,
                        }}
                    />
                </Grid>
            )
        )
    }

    return <DefaultTopChips {...props} renderExtraChips={renderExtraChips} />
}
