const defaultState = {
  cart: {},
  fetchCartInProgress: false,
  couponCart: null,
  updateItemInProgress: false,
  isCouponLinkingInProgress: false,
  isDeletingCartItemsInProgress: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "FETCH_SECONDARY_CART_START": {
      return { ...state, fetchCartInProgress: true };
    }
    case "FETCH_SECONDARY_CART_SUCCESS": {
      return { ...state, cart: action.payload, fetchCartInProgress: false };
    }
    case "RESET_SECONDARY_CART_TO_DEFAULT": {
      return defaultState;
    }
    case "ADD_TO_SECONDARY_CART_START": {
      return { ...state };
    }
    case "ADD_TO_SECONDARY_CART_SUCCESS": {
      return { ...state };
    }
    case "DELETE_ITEM_FROM_SECONDARY_CART_START": {
      return { ...state };
    }
    case "DELETE_ITEM_FROM_SECONDARY_CART_SUCCESS": {
      return { ...state };
    }
    case "FETCH_LINKED_COUPON_TO_SECONDARY_CART_SUCCESS": {
      // For know we use only one coupon per cart.
      return { ...state, couponCart: action.payload[0] };
    }
    case "LINK_COUPON_TO_SECONDARY_CART_START": {
      return { ...state, isCouponLinkingInProgress: true };
    }
    case "LINK_COUPON_TO_SECONDARY_CART_SUCCESS": {
      return {
        ...state,
        couponCart: action.payload,
        isCouponLinkingInProgress: false,
      };
    }
    case "LINK_COUPON_TO_SECONDARY_CART_ERROR": {
      return { ...state, isCouponLinkingInProgress: false };
    }
    case "REMOVE_LINKED_COUPON_TO_SECONDARY_CART_SUCCESS": {
      return { ...state, couponCart: null };
    }
    case "INITIALIZE_COUPON_STATE":
      return { ...state, couponCart: null };
    case "UPDATE_ITEM_OF_SECONDARY_CARTITEM_START": {
      return { ...state, updateItemInProgress: true };
    }
    case "UPDATE_ITEM_OF_SECONDARY_CARTITEM_SUCCESS": {
      return { ...state, updateItemInProgress: false };
    }
    case "UPDATE_ITEM_OF_SECONDARY_CARTITEM_ERROR": {
      return { ...state, updateItemInProgress: false };
    }
    case "SECONDARY_STRIPE_PURCHASE_START": {
      return { ...state, inProgress: true };
    }
    case "SECONDARY_STRIPE_PURCHASE_SUCCESS": {
      return { ...state, inProgress: false };
    }
    case "SECONDARY_STRIPE_PURCHASE_ERROR": {
      return { ...state, inProgress: false };
    }
    case "DELETE_SECONDARY_CART_ITEMS_START_": {
      return { ...state, isDeletingCartItemsInProgress: true };
    }
    case "DELETE_SECONDARY_CART_ITEMS_SUCCESS_": {
      return { ...state, isDeletingCartItemsInProgress: false };
    }
    case "DELETE_SECONDARY_CART_ITEMS_ERROR_": {
      return { ...state, isDeletingCartItemsInProgress: false };
    }
    default:
      return { ...state };
  }
}
