import { SellerPurchaseListContainer } from "react-platform-purchase/dist/PurchaseOfSeller/SellerPurchaseList/SellerPurchaseListContainer";
import { SellerPurchaseDetailsContainer } from "./SellerPurchaseDetails/SellerPurchaseDetailsContainer";

export const PurchaseOfSellerConfig = {
  auth: ["user"],
  routes: [
    {
      component: SellerPurchaseDetailsContainer,
      path: "/seller-purchases-details/:id",
    },

    {
      component: SellerPurchaseListContainer,
      path: "/seller-purchases",
    },
  ],
};
