import { BuyerPurchaseListContainer } from "react-platform-purchase/dist/PurchaseOfBuyer/BuyerPurchaseList/BuyerPurchaseListContainer";
import { BuyerPurchaseDetailsContainer } from "./BuyerPurchaseDetails/BuyerPurchaseDetailsContainer";

export const PurchaseOfBuyerConfig = {
  auth: ["user"],
  routes: [
    {
      component: BuyerPurchaseDetailsContainer,
      path: "/buyer-purchases-details/:id",
    },
    {
      component: BuyerPurchaseListContainer,
      path: "/buyer-purchases",
    },
  ],
};
