const defaultState = {
  caution: [],
};

export default function customProductSalableReducer(
  state = defaultState,
  action
) {
  switch (action.type) {
    case "FETCH_CAUTION_START": {
      return { ...state, inProgress: true };
    }
    case "FETCH_CAUTION_SUCCESS": {
      return { ...state, caution: action.payload };
    }

    default:
      return state;
  }
}
