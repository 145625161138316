import React, { useState } from "react";
import {
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import { IntlMessages, injectIntl } from "react-platform-components/dist/Translations";
import ActiveSubscriptionsTab from "./tabs/ActiveSubscriptionsTab";
import SubscriptionsTab from "./tabs/SubscriptionsTab";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { useTitle } from "react-use"


function Subscriptions(props) {
  const history = useHistory();

  const currentTab = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  }).tab;
  const [tab, setTab] = useState(currentTab || "subscriptions");
  useTitle(
      tab === 'subscriptions'
          ? props.intl.formatMessage({
                id: 'Subscriptions page title',
                defaultMessage: 'Subscriptions page title',
            })
          : props.intl.formatMessage({
                id: 'Active subscriptions page title',
                defaultMessage: 'Active subscriptions page title',
            }),
  );

  const handleTabChange = (event, tab) => {
    setTab(tab);
    history.push({ search: `?tab=${tab}` });
  };

  const renderTab = () => {
    switch (tab) {
      case "subscriptions":
        return <SubscriptionsTab />;
      case "active_subscriptions":
        return <ActiveSubscriptionsTab {...props} />;
      default:
        return <SubscriptionsTab />;
    }
  };

  return (
    <div>
      <Paper>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab
            className="h-16"
            label={
              <IntlMessages
                id="Les Abonnements"
                defaultMessage="Les Abonnements"
              />
            }
            value="subscriptions"
          />
          <Tab
            className="h-16"
            label={
              <IntlMessages
                id="Mes Abonnements"
                defaultMessage="Mes Abonnements"
              />
            }
            value="active_subscriptions"
          />
        </Tabs>
      </Paper>
      {renderTab()}
    </div>
  );
}

export default injectIntl(Subscriptions);
