import React from "react";
import _ from "lodash";
import { Paper, Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { renderMultiFilesField } from "react-platform-components/dist/uploader";
import { Field, FormikProvider, useFormik } from "formik";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
  AlignItemsAndMargin: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "16px",
    color: theme.palette.primary.main,
  },
}));

export default function Identification() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { user, inProgress } = useSelector((state) => ({
    user: state.auth.user,
    inProgress: state.profileReducer.inProgress,
  }));

  const formik = useFormik({
    initialValues: {
      drivers_licenses: "",
      identity_cards: "",
    },
    onSubmit: (data) => {
      if (_.isEmpty(data.drivers_licenses) && _.isEmpty(data.identity_cards))
        return;

      const model = new FormData();
      if (!_.isEmpty(data.drivers_licenses))
        data.drivers_licenses.forEach((file, index) => {
          if (file.file)
            model.append(
              `drivers_licenses-${index + 9999}-filename`,
              file.file
            );
        });
      if (!_.isEmpty(data.identity_cards))
        data.identity_cards.forEach((file, index) => {
          if (file.file)
            model.append(`identity_cards-${index + 9999}-filename`, file.file);
        });

      dispatch({
        type: "EDIT_PROFILE",
        id: user.id,
        model,
        redirectTo: "/profile",
        history,
      });
    },
  });

  const isIdentified = () => {
    return !_.isEmpty(user.driverse_license) && !_.isEmpty(user.identity_cards);
  };

  return (
    <div className="m-4 md:m-8">
      <Paper
        elevation={3}
        className="p-4 md:p-16"
        style={{ boxShadow: "4px 3px 20px #c7c7c7" }}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid>
                {_.isEmpty(user.drivers_licenses) ? (
                  <Grid item xs={12} className="pb-4">
                    <Typography variant="subtitle2">
                      <IntlMessages
                        id="Permis de conduire :"
                        defaultMessage="Permis de conduire :"
                      />
                    </Typography>
                    <Field
                      type="file"
                      name="drivers_licenses"
                      label={
                        <IntlMessages
                          id="Permis de conduire"
                          defaultMessage="Permis de conduire"
                        />
                      }
                      maxSize={1000000}
                      acceptedFiles="application/pdf,image/jpeg,image/jpg,image/png"
                      component={renderMultiFilesField}
                    />
                  </Grid>
                ) : (
                  <Grid className={classes.AlignItemsAndMargin}>
                    <CheckBoxIcon className="mr-2" />
                    <IntlMessages
                      id="Le permis de conduire a été chargé avec succès"
                      defaultMessage="Le permis de conduire a été chargé avec succès"
                    />
                  </Grid>
                )}
                {_.isEmpty(user.identity_cards) ? (
                  <Grid item xs={12} className="pb-4">
                    <Typography variant="subtitle2">
                      <IntlMessages
                        id="Pièce d'identité :"
                        defaultMessage="Pièce d'identité :"
                      />
                    </Typography>
                    <Field
                      name="identity_cards"
                      label={
                        <IntlMessages
                          id="Pièce d'identité"
                          defaultMessage="Pièce d'identité"
                        />
                      }
                      maxSize={1000000}
                      acceptedFiles="application/pdf,image/jpeg,image/jpg,image/png"
                      component={renderMultiFilesField}
                    />
                  </Grid>
                ) : (
                  <Grid className={classes.AlignItemsAndMargin}>
                    <CheckBoxIcon className="mr-2" />
                    <IntlMessages
                      id="La pièce d'identité a été chargée avec succès"
                      defaultMessage="La pièce d'identité a été chargée avec succès"
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12} justify="flex-end" className="pt-2">
                <Button
                  disabled={inProgress || isIdentified()}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  <IntlMessages id="Valider" />
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </Paper>
    </div>
  );
}
