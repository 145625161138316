import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import DOMPurify from "dompurify";
import {
  ESSENTIAL_SUBSCRIPTION_UUID,
  STRIPE_SUBSCRIPTION_SALABLE_ITEM_CLASS,
} from "../../../configs";
import IdentificationDialog from "../pages/IdentificationDialog";
import { CardsSkeleton } from './components/CardsSkeleton'

function SubscriptionsTab(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { user, subscriptions, cart } = useSelector((state) => ({
    user: state.auth.user,
    subscriptions: state.stripeSubscriptionsReducer.subscriptions,
    cart: state.secondaryShoppingCartReducer.cart,
  }));

  useEffect(() => {
    dispatch({
      type: "FETCH_SUBSCRIPTIONS",
      params: { salable_item_class: STRIPE_SUBSCRIPTION_SALABLE_ITEM_CLASS },
    });
    dispatch({
      type: "FETCH_SECONDARY_CART",
      params: { _embed: ["cart_items"] },
    });
  }, [dispatch]);

  const paySubscription = (subscription) => () => {
    if (
      subscription.salable_item_options &&
      subscription.salable_item_options.length > 0
    ) {
      history.push(
        `/subscriptions-products/${subscription.uuid}/${subscription.salable_item_options[0].uuid}`,
        { subscription }
      );
    } else {
      dispatch({ type: "CLEAR_SECONDARY_CART", cart: cart });
      dispatch({
        type: "PAY_SUBSCRIPTION",
        subscription: subscription,
        history,
      });
    }
  };

  const isIdentified = () => {
    return !_.isEmpty(user.drivers_licenses) && !_.isEmpty(user.identity_cards);
  };

  const openEditDialog = () => {
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
  };

  return (
    <>
      <div className="flex items-center justify-center flex-wrap p-4">
        {editDialogOpen && (
          <IdentificationDialog
            isEdit={true}
            open={editDialogOpen}
            close={closeEditDialog}
          />
        )}
      </div>
      <div className="flex flex-wrap p-4">
        {subscriptions?.length > 0 ?
          subscriptions.map((item, index) => {
            return (
              <Grid key={index} lg={3} md={6} sm={6} xs={12} className="flex p-4">
                <Card elevation={4} className="w-full relative">
                  <CardHeader
                    title={
                      <Typography
                        color='primary'
                        variant="h5"
                        align="center"
                      >
                        {item.name}
                      </Typography>
                    }
                  />
                  <CardContent>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.description),
                      }}
                    />
                    {item.uuid !== ESSENTIAL_SUBSCRIPTION_UUID && !!item.price && (
                      <div className="flex justify-center items-center pb-2 pr-10 absolute bottom-0 w-full">
                        <Button
                          className="w-full"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={
                            isIdentified()
                              ? paySubscription(item)
                              : openEditDialog
                          }
                        >
                          <IntlMessages id="Buy /Subscription" />
                        </Button>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          }) : <CardsSkeleton  />}
      </div>
    </>
  );
}

export default SubscriptionsTab;
