import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import {
    SORT_CATEGORIES_BY,
    CATEGORIES_OF_PRODUCT_FIELDS_TO_FETCH_IN_CREATION_OF_PRODUCT,
} from 'react-platform-category-of-product/dist/config';
 import { CreateProductSalable } from "react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalable"
import { CustomCreateProductSalableForm } from "./CustomCreateProductSalableForm"

export const CustomCreateProductSalable = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: 'FETCH_CATEGORIES_OF_PRODUCT',
            params: {
                _sort: SORT_CATEGORIES_BY,
                _fields: CATEGORIES_OF_PRODUCT_FIELDS_TO_FETCH_IN_CREATION_OF_PRODUCT,
            },
        });
    }, []);
    return <CreateProductSalable useDefaultFetchCategories={false} createProductSalableForm={CustomCreateProductSalableForm} />
}
