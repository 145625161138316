import SecondaryPurchaseResult from "./SecondaryPurchaseResult";
import FailureResult from "./FailureResult";
import SuccessResult from "./SuccessResult";
import PendingResult from "./PendingResult";

export const SecondaryPurchaseResultConfig = {
  auth: ["user"],
  routes: [
    {
      component: SecondaryPurchaseResult,
      path: "/payment-result/:type",
    },
    {
      component: SuccessResult,
      path: "/purchase-success",
    },
    {
      component: FailureResult,
      path: "/purchase-failure",
    },
    {
      component: PendingResult,
      path: "/purchase-pending",
    },
  ],
};
