import React from "react";
import { connect } from "react-redux";
import { Layout } from "react-platform-components/dist";
import { routes } from "../../init";
import { injectIntl } from "react-platform-components/dist/Translations";

class App extends React.Component {
  render() {
    return (
      <Layout
        routes={routes}
        navigationConfig={[]}
        {...this.props}
      />
    );
  }
}

export default connect((state) => {
  return {
    user: state.auth.user,
  };
})(injectIntl(App));
