import { withStyles } from "@material-ui/core/styles";
import { injectToBaseContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { connect } from "react-redux";
import ShoppingCartOfPoint from "./ShoppingCartOfPoint";
import * as _ from "lodash";

const styles = (theme) => ({
  cardShadow: {
    boxShadow: "4px 3px 20px #c7c7c7",
  },
});

/**
 * We create our class by using injectToBaseContainer
 * this will return a React Component that is wrapped with the HOCs
 * inside flowRight, and hass all the props that are passed as options
 */
export default injectToBaseContainer(
  _.flowRight(
    withStyles(styles),
    connect((state) => {
      return {};
    })
  ),
  {
    listComponent: ShoppingCartOfPoint,
    // Overrid componentDidMount of the BaseContainer to fetch the cart
    componentDidMount: (props) => {
      props.dispatch({
        type: "FETCH_SECONDARY_CART",
        params: { _embed: ["cart_items"] },
      });
    },
    hasHeader: false,
    confirmTitle: "Êtes-vous sûr de vouloir procéder au paiement?",
    // Overrid the confirmation action of the ConfirmationDialog
    // NOTE: this NEEDS to be a function expression and NOT an arrow function
    confirmAction: function () {
      this.props.dispatch({ type: "PURCHASE_POINT" });
      this.closeConfirmDialog();
    },
  }
);
