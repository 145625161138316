import { all, put, takeEvery } from "redux-saga/effects";
import axios from "react-platform-components/dist/axios";
import { handleErrors } from "react-platform-components/dist/Utils";
import { BASE_API_URL, BASE_URL } from "../../configs";

export const FETCH_LANGUAGES = "FETCH_LANGUAGES";
export const FETCH_LANGUAGES_START = "FETCH_LANGUAGES_START";
export const FETCH_LANGUAGES_SUCCESS = "FETCH_LANGUAGES_SUCCESS";
export const FETCH_LANGUAGES_ERROR = "FETCH_LANGUAGES_ERROR";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_START = "UPDATE_PAYMENT_METHOD_START";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR";

export function* fetchLanguages(action) {
  yield put({ type: FETCH_LANGUAGES_START });
  try {
    const params = action.params || {};
    const response = yield axios.get(`${BASE_API_URL}languages`, { params });
    yield put({ type: FETCH_LANGUAGES_SUCCESS, payload: response.data });
  } catch (error) {
    yield all([
      put({ type: FETCH_LANGUAGES_ERROR }),
      put({ type: "HANDLE_ERROR", error: handleErrors(error) }),
    ]);
  }
}
export function* updatePaymentMethod(action) {
  yield put({ type: UPDATE_PAYMENT_METHOD_START });
  try {
    const params = action.params || {};
    yield axios.post(`${BASE_URL}stripe/change-payment-method`, action.model);
    yield put({ type: UPDATE_PAYMENT_METHOD_SUCCESS });
    action.onSuccess && action.onSuccess();
    yield put({ type: "FETCH_USER" });
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

export function* profileSaga() {
  yield all([
    takeEvery(FETCH_LANGUAGES, fetchLanguages),
    takeEvery(UPDATE_PAYMENT_METHOD, updatePaymentMethod),
  ]);
}
