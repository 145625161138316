
const defaultState = {
    warningZones: [],
    fetchInProgress: false,
    deleteInProgress: false,
    createInProgress: false
}


export default function WarningZoneReducer(state = defaultState, action){
    switch (action.type) {
        case "FETCH_WARNING_ZONE_START" : {
            return { ...state, fetchInProgress: true}
        }
        case "FETCH_WARNING_ZONE_SUCCESS" : {
            return { ...state, fetchInProgress: false, warningZones: action.payload}
        }
        case "FETCH_WARNING_ZONE_ERROR" : {
            return { ...state, fetchInProgress: false}
        }
        case "CREATE_WARNING_ZONE_START" : {
            return { ...state, createInProgress: true}
        }
        case "CREATE_WARNING_ZONE_SUCCESS" : {
            return { ...state, createInProgress: false}
        }
        case "CREATE_WARNING_ZONE_ERROR" : {
            return { ...state, createInProgress: false}
        }
        case "DELETE_WARNING_ZONE_START" : {
            return { ...state, deleteInProgress: true}
        }
        case "DELETE_WARNING_ZONE_SUCCESS" : {
            return { ...state, deleteInProgress: false}
        }
        case "DELETE_WARNING_ZONE_ERROR" : {
            return { ...state, deleteInProgress: false}
        }
        default: return state
    }
}