import React from "react"
import { Grid } from "@material-ui/core"
import AddressOfProductFields from "react-platform-address-of-product/dist/AddressOfProduct/AddressOfProductFields"
import { useFormikContext } from "formik"

export const AddressStepFields = (props) => {
    const { initialValues, initialErrors, values, setFieldValue } = useFormikContext()
    return [
        <Grid container item xs={12} md={12}>
            <Grid item xs={12} md={12}>
                <AddressOfProductFields
                    {...props}
                    values={values}
                    unusedFields={["state", "zipcode"]}
                    isForced={true}
                    // Paris coordinate
                    defaultLatLng={{ lat: 48.0, lng: 2.0 }}
                    zoom={5}
                    initialErrors={initialErrors}
                    initialValues={initialValues}
                    setFieldValue={setFieldValue}
                />
            </Grid>
        </Grid>,
    ]
}
