import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { injectToForm } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { TextField } from "react-platform-components/dist/form-fields";
import {
  required,
  validators,
} from "react-platform-components/dist/form-validators";
import CreateProductForm from "react-platform-product/dist/Product/dialogs/CreateProductForm";
import { Field } from "formik";
import { renderMultiFilesField } from "react-platform-components/dist/uploader";
import IntlMessages from "react-platform-components/dist/Translations/IntlMessages";

export const IMMATRICULATION_CERTIFICATE_KEY = "immatriculation_certificates";
export const INSURANCE_CERTIFICATE_KEY = "insurance_certificates";
export const ROADBOOK_KEY = "roadbooks";

// Create a form component from CreateProductForm
export default injectToForm(CreateProductForm, {
  // Pass the function that will return the array of fields
  isFormExtended: true,
  embedFieldsWithIndex: 2,
  formExtraFields: (props) => [
    <Grid item xs={12}>
      <Grid item xs={12}>
        <TextField
          name="price"
          label="Price"
          margin="normal"
          variant="outlined"
          fullWidth
          required
          validate={validators([required()])}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ color: "#777777" }}>
            <IntlMessages
              id="Certificat d'immatriculation*"
              defaultMessage="Certificat d'immatriculation*"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <Field
            name={IMMATRICULATION_CERTIFICATE_KEY}
            value={props.values[IMMATRICULATION_CERTIFICATE_KEY]}
            label={
              <IntlMessages
                id="Certificat d'immatriculation*"
                defaultMessage="Certificat d'immatriculation*"
              />
            }
            acceptedFiles="application/pdf,image/jpeg,image/png"
            component={renderMultiFilesField}
            validate={required()}
            required
            onDelete={(id) =>
              props.setFieldValue(
                "immatriculationCertificateToDelete",
                props.values.immatriculationCertificateToDelete
                  ? [...props.values.immatriculationCertificateToDelete, id]
                  : [id]
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ color: "#777777" }}>
            <IntlMessages
              id="Certification d'assurance*"
              defaultMessage="Certification d'assurance*"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <Field
            name={INSURANCE_CERTIFICATE_KEY}
            value={props.values[INSURANCE_CERTIFICATE_KEY]}
            label={
              <IntlMessages
                id="Certification d'assurance*"
                defaultMessage="Certification d'assurance*"
              />
            }
            acceptedFiles="application/pdf,image/jpeg,image/png"
            component={renderMultiFilesField}
            validate={required()}
            required
            onDelete={(id) =>
              props.setFieldValue(
                "insuranceCertificateToDelete",
                props.values.insuranceCertificateToDelete
                  ? [...props.values.insuranceCertificateToDelete, id]
                  : [id]
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography style={{ color: "#777777" }}>
            <IntlMessages id="Roadbook" defaultMessage="Roadbook" />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <Field
            name={ROADBOOK_KEY}
            value={props.values[ROADBOOK_KEY]}
            label={<IntlMessages id="Roadbook" defaultMessage="Roadbook" />}
            acceptedFiles="application/pdf,image/jpeg,image/png"
            component={renderMultiFilesField}
            onDelete={(id) =>
              props.setFieldValue(
                "roadbookToDelete",
                props.values.roadbookToDelete
                  ? [...props.values.roadbookToDelete, id]
                  : [id]
              )
            }
          />
        </Grid>
      </Grid>
    </Grid>,
  ],
  // Pass function that will create and object of key/value pairs from the
  // array of field keys
  handleInitialValues: (props) => {
    const { product } = props;

    return {
      price: product?.price || "100",
      [IMMATRICULATION_CERTIFICATE_KEY]: "",
      [INSURANCE_CERTIFICATE_KEY]: "",
      [ROADBOOK_KEY]: "",
    };
  },
});
