import profileReducer from "react-platform-user/dist/Profile/ProfileReducer";
import {
  FETCH_LANGUAGES_ERROR,
  FETCH_LANGUAGES_START,
  FETCH_LANGUAGES_SUCCESS,
} from "./ProfileActions";

const defaultState = {
  languagesIsFetchinginProgress: false,
  languages: [],
};

export default function internalProfileReducer(state = defaultState, action) {
  const mergedState = {
    ...state,
    ...profileReducer(undefined, action),
  };

  switch (action.type) {
    case FETCH_LANGUAGES_START:
      return {
        ...mergedState,
        languagesIsFetchinginProgress: true,
      };
    case FETCH_LANGUAGES_SUCCESS:
      return {
        ...mergedState,
        languages: action.payload,
        languagesIsFetchinginProgress: false,
      };
    case FETCH_LANGUAGES_ERROR:
      return {
        ...mergedState,
        languagesIsFetchinginProgress: false,
      };
    default:
      return mergedState;
  }
}
