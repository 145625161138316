import PurchasePoints from "./PurchasePoints";
import { purchasePointeSaga } from "./PurchasePointActions";
import purchasePointReducer from "./PurchasePointReducer";

export const ShoppingCartOfPointConfig = {
  auth: ["user"],
  routes: [
    {
      component: PurchasePoints,
      path: "/purchase-points",
    },
    {
      component: PurchasePoints,
      path: "/buyer-purchases-points",
    },
  ],
  actions: purchasePointeSaga,
  reducer: purchasePointReducer,
};
