import React from "react";
import { BuyerPurchaseDetails as DefaultBuyerPurchaseDetails } from "react-platform-purchase/dist/PurchaseOfBuyer/BuyerPurchaseDetails/BuyerPurchaseDetails";
import { ReservationDetailsOfBuyer } from "../../components/ReservationDetailsOfBuyer";

export const BuyerPurchaseDetails = () => {
  return (
    <DefaultBuyerPurchaseDetails
      reservationDetails={ReservationDetailsOfBuyer}
    />
  );
};
