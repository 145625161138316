import SubscriptionsContainer from "./SubscriptionsContainer";
import SubscriptionsProducts from "./pages/SubscriptionsProducts";
import { subscriptionsSaga } from "./SubscriptionsActions";

export const StripeSubscriptionsConfig = {
  auth: ["user"],
  routes: [
    {
      component: SubscriptionsContainer,
      path: "/subscriptions",
    },
  ],
  actions: subscriptionsSaga,
};
