import React, { useEffect } from "react"
import { FormikProvider } from "formik"
import { Grid, Paper } from "@material-ui/core"
import IntlMessages from "react-platform-components/dist/Translations/IntlMessages"
import { Button } from "@material-ui/core"
import { useFormik } from "formik"
import { makeStyles } from "@material-ui/core"
import { Fields } from "./Components/Fields"
import { useDispatch } from "react-redux"

const useStyle = makeStyles((theme) => ({
    "@global": {
        body: {
            backgroundColor: "rgb(195 195 195 / 16%)",
        },
    },
    componentWrapper: {
        paddingLeft: "15px",
    },
    SubmitButtom: {
        marginTop: 30,
        padding: "0 35px 50px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "@media (max-width: 600px)": {
            justifyContent: "center",
        },
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const initialValues = {
    "address-country": "",
    "address-country_code": "",
    name: "",
    categories: [],
    country: "",
    countries: [],
    countriesCodes: [],
}

export const WarningZoneForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyle()
    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            const model = {
                name: values.name,
                categories: values.categories,
                countries: [values.countriesCodes.join("|")]
            }
            dispatch({
                type: "CREATE_WARNING_ZONE",
                model,
                onSuccess: () => {
                    dispatch({
                        type: "HANDLE_SUCCESS_MESSAGE",
                        message: <IntlMessages id="Votre zone d'alert a bien été enregistrée" />,
                    })
                },
                redirect: () => {
                    props.handleTabChange(null, "warning-zone-list")
                },
            })
        },
    })
    const { setFieldValue, values } = formik
    useEffect(() => {
        if (!values.countries.includes(values["address-country"]) && values["address-country"]) {
            const countries = [...values.countries, values["address-country"]]
            setFieldValue("countries", countries)
        }
        setFieldValue("country", "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values["address-country"]])
    useEffect(() => {
        if (!values.countriesCodes?.includes(values["address-country_code"]) && values["address-country_code"]) {
            const countriesCodes = [...values.countriesCodes, values["address-country_code"]]
            setFieldValue("countriesCodes", countriesCodes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values["address-country_code"]])
    return (
        <FormikProvider value={formik}>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Paper elevation={0} variant='elevation' className={classes.componentWrapper}>
                    <Fields initialValues={initialValues} classes={classes} />
                    <Grid className={classes.SubmitButtom}>
                        <Button variant='contained' color='primary' type='submit'>
                            <IntlMessages id='Submit warning zone' />
                        </Button>
                    </Grid>
                </Paper>
            </form>
        </FormikProvider>
    )
}
