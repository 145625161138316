import React, { Component } from "react";
import { useTitle } from "react-use";
import { Grid, Icon, Typography, Paper, useTheme } from "@material-ui/core";
import { IntlMessages, injectIntl } from "react-platform-components/dist/Translations";

function FailureResult(props) {
  useTitle(
      props.intl.formatMessage({
          id: 'Purchase failure page title',
          defaultMessage: 'Purchase failure page title',
      }),
  );
  const theme = useTheme();
  const color = theme?.palette?.error?.main;

  return (
    <Paper className="p-12 m-12">
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Icon
            style={{
              color: color,
              fontSize: "8rem",
              border: `2px solid ${color}`,
              borderRadius: "100px",
            }}
          >
            close
          </Icon>
        </Grid>
        <Grid
          style={{ marginTop: "3rem" }}
          item
          xs={12}
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="h5">
            <IntlMessages
              id="Your purchase failed"
              defaultMessage="Your purchase failed"
            />{" "}
            :
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="body1">
            <IntlMessages
              id="lorem ipsum failure!"
              defaultMessage="lorem ipsum!"
            />
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default injectIntl(FailureResult);
