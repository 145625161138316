import {
  Button,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { useSelector, useDispatch } from "react-redux";

// TODO: re-implement this later when we fix the coupon module.

function PromoCode(props) {
  const [showPromoCodeField, setPromoCodeField] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [addedPromoCode, setAddedPromoCode] = useState(false);
  const [cachedCoupon, setCachedCoupon] = useState(null);
  const dispatch = useDispatch();
  const { couponCart, isCouponLinkingInProgress } = useSelector(
    ({ secondaryShoppingCartReducer }) => ({
      couponCart: secondaryShoppingCartReducer.couponCart,
      isCouponLinkingInProgress:
        secondaryShoppingCartReducer.isCouponLinkingInProgress,
    })
  );

  React.useEffect(() => {
    setTimeout(() => {
      dispatch({ type: "FETCH_LINKED_COUPON_TO_SECONDARY_CART" });
    }, 1000);
  }, []);

  useEffect(() => {
    if (couponCart?.coupon?.token) {
      setPromoCode(couponCart?.coupon?.token);
      setAddedPromoCode(true);
      setPromoCodeField(false);
      setCachedCoupon(couponCart);
      dispatch({ type: "INITIALIZE_COUPON_STATE" });
    }
  }, [couponCart]);

  const handlePromoCodeDeleted = () => {
    if (cachedCoupon) {
      dispatch({
        type: "REMOVE_LINKED_COUPON_TO_SECONDARY_CART",
        id: cachedCoupon.id,
      });
    }
  };

  const handlePromoCodeAdded = () => {
    dispatch({
      type: "LINK_COUPON_TO_SECONDARY_CART",
      model: { token: promoCode },
      onSuccess: () => setAddedPromoCode(true),
    });
  };

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid container item xs={10} alignItems="center" justify="flex-start">
          {addedPromoCode ? (
            <Typography variant="overline">
              <IntlMessages id="Promo code :" defaultMessage="Promo code :" />
            </Typography>
          ) : (
            <Typography variant="overline">
              <IntlMessages
                id="Add promo code"
                defaultMessage="Add promo code"
              />
            </Typography>
          )}
        </Grid>
        {!addedPromoCode && (
          <Grid container item xs={2} alignItems="center" justify="flex-end">
            <IconButton onClick={() => setPromoCodeField(!showPromoCodeField)}>
              <Icon style={{ fontSize: "1.2rem" }}>
                {showPromoCodeField ? "remove" : "add"}
              </Icon>
            </IconButton>
          </Grid>
        )}
        {addedPromoCode && (
          <>
            <Grid
              container
              item
              xs={10}
              alignItems="center"
              justify="flex-start"
            >
              <Typography style={{ fontWeight: 500 }} variant="overline">
                {promoCode}
              </Typography>
            </Grid>
            <Grid container item xs={2} alignItems="center" justify="flex-end">
              <IconButton
                onClick={() => {
                  setAddedPromoCode(false);
                  setPromoCode(null);
                  setPromoCodeField(false);
                  handlePromoCodeDeleted();
                }}
              >
                <Icon style={{ fontSize: "1.2rem" }}>delete</Icon>
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
      {showPromoCodeField && !addedPromoCode && (
        <>
          <Grid container item xs={8}>
            <TextField
              name="price"
              label={
                <IntlMessages id="Promo code" defaultMessage="Promo code" />
              }
              value={promoCode}
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(event) => setPromoCode(event.target.value)}
            />
          </Grid>
          <Grid container item xs={4} justify="flex-end">
            <Button
              className="mt-4"
              size="small"
              variant="contained"
              color="primary"
              disabled={isCouponLinkingInProgress}
              onClick={() => promoCode && handlePromoCodeAdded()}
            >
              <IntlMessages id="Save code" defaultMessage="Save code" />
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default PromoCode;
