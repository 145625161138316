import _ from "lodash";
import { injectToBaseCreationDialog } from "react-platform-components/dist/BaseComponents/BaseInjectors";
import { connect } from "react-redux";
import CreateProductSalableForm, {
  ROADBOOK_KEY,
  IMMATRICULATION_CERTIFICATE_KEY,
  INSURANCE_CERTIFICATE_KEY,
} from "./CreateProductSalableForm";
import { FETCH_PRODUCT_SALABLES } from "react-platform-productsalable/dist/ProductSalable/ProductSalableActions";
import moment from "moment";

const INDEX_POWER = 100;

const handleFileSubmitting = (data, formData, key, deletionKey) => {
  data[deletionKey] &&
    data[deletionKey].forEach((item, index) => {
      formData.append(`${key}-${index}-id`, item);
      formData.append(`${key}-${index}-inline_delete`, "true");
    });

  data[key] &&
    data[key].forEach((item, index) => {
      if (item.file) {
        const _indx =
          data[deletionKey]?.length > 0 ? data[deletionKey]?.length : 0;
        formData.append(
          `${key}-${index + _indx + INDEX_POWER * 999}-filename`,
          item.file
        );
      } else {
        formData.append(`${key}-${index}-id`, item.id);
        formData.append(`${key}-${index}-filename`, item.name);
      }
    });
};

export default injectToBaseCreationDialog(
  // Wrap the component with the needed HOCs
  _.flowRight(
    connect((state) => {
      return {
        user: state.auth.user,
        pagination: state.productSalableReducer.pagination,
        categories: state.categoryOfProductReducer
          ? state.categoryOfProductReducer.categories
          : null,
        specificities: state.specificityOfProductReducer
          ? state.specificityOfProductReducer.specificities
          : null,
      };
    })
  ),
  {
    // Pass the form
    createForm: CreateProductSalableForm,
    // Override componentDidMount to fetch categories and specificities
    componentDidMount: function () {
      this.props.dispatch({ type: "FETCH_CATEGORIES_OF_PRODUCT" });
      this.props.dispatch({ type: "FETCH_SPECIFICITIES_OF_PRODUCT" });
    },
    endpoint: "salable-products",
    postUpdateAction: FETCH_PRODUCT_SALABLES,
    // Define the fields that are excluded in the handleSubmit
    excludedFields: [
      "images",
      "specificities",
      "options",
      INSURANCE_CERTIFICATE_KEY,
      IMMATRICULATION_CERTIFICATE_KEY,
      ROADBOOK_KEY,
      "immatriculationCertificateToDelete",
      "insuranceCertificateToDelete",
      "roadbookToDelete",
    ],
    // Handle the excluded field inside the customHandleSubmit
    customHandleSubmit: (data, formData) => {
      if (data.specificities && !_.isEmpty(data.specificities)) {
        data.specificities.forEach((item, index) => {
          if (item.choice) {
            if (item.type === "date") {
              formData.append(
                `product_specificities-${index}-specificity`,
                item.specificity.id
              );
              formData.append(
                `product_specificities-${index}-date`,
                moment(item.choice, "DD-MM-YYYY").format("YYYY-MM-DD")
              );
            } else if (item.type === "m_list") {
              formData.append(
                `product_specificities-${index}-specificity`,
                item.specificity.id
              );
              formData.append(
                `product_specificities-${index}-choices`,
                item.choice.join("|")
              );
            } else if (item.type === "free") {
              formData.append(
                `product_specificities-${index}-specificity`,
                item.specificity.id
              );
              formData.append(
                `product_specificities-${index}-free`,
                item.choice
              );
            } else {
              formData.append(
                `product_specificities-${index}-specificity`,
                item.specificity.id
              );
              formData.append(
                `product_specificities-${index}-choice`,
                item.choice
              );
            }
          }
        });
        delete data.specificities;
      }

      if (data["images-to-delete"] && data["images-to-delete"].length > 0) {
        data["images-to-delete"].forEach((img, index) => {
          formData.append(
            `images-${index + INDEX_POWER * 999}-inline_delete`,
            "true"
          );
          formData.append(`images-${index + INDEX_POWER * 999}-id`, img);
        });
      }

      data.images.forEach((file, index) => {
        if (file.file) {
          formData.append(
            `images-${index + INDEX_POWER * 999}-name`,
            file.file
          );
          formData.append(
            `images-${index + INDEX_POWER * 999}-type`,
            "standard"
          );
        } else {
          formData.append(`images-${index}-id`, file.id);
          formData.append(`images-${index}-type`, "standard");
        }
      });

      handleFileSubmitting(data, formData, ROADBOOK_KEY, "roadbookToDelete");
      handleFileSubmitting(
        data,
        formData,
        INSURANCE_CERTIFICATE_KEY,
        "insuranceCertificateToDelete"
      );
      handleFileSubmitting(
        data,
        formData,
        IMMATRICULATION_CERTIFICATE_KEY,
        "immatriculationCertificateToDelete"
      );
    },
  }
);
