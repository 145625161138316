import { Paper, Tab, Tabs, Typography, Icon, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { IntlMessages, injectIntl } from "react-platform-components/dist/Translations";
import { connect } from "react-redux";
import {
  STATIC_PATH,
  USE_CREDIT_CARDS,
  USE_STRIPE_PURSHACE,
} from "react-platform-user/dist/config";
import ChangePasswordTab from "react-platform-user/dist/Profile/tabs/ChangePasswordTab";
import CreditCards from "react-platform-user/dist/Profile/tabs/CreditCards";
import UserInfo from "./tabs/UserInfo";
import Identification from "./tabs/Identification";
import CompleteMangopayInfo from "react-platform-user/dist/Profile/tabs/CompleteMangopayInfo";
import qs from "qs";
// import CardsTab from "./tabs/CardsTab";
import UpdateStripePaymentMethod from "./tabs/UpdateStripePaymentMethod";

let Purchase;
try {
  Purchase = require("react-platform-purchase/dist/Purchase/PurchaseConfig");
} catch (error) {}

let AddressOfUser;
try {
  AddressOfUser =
    require("react-platform-address-of-user/dist/AddressOfUser/AddressOfUser").default;
} catch (error) {}

const styles = (theme) => ({
  avatar: {
    backgroundColor: "#3b3b3b",
    color: "#fff",
    fontSize: 55,
    height: 96,
    textDecoration: "none",
    width: 96,
  },
  header: {
    padding: theme.spacing(5, 4, 4),
    display: "flex",
    minHeight: "10vh",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium
  },
  paper: {
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  }
});

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true,
      }).tab || 'userinfo',
    };
  }

  componentDidMount() {
    this.setTabTitle(this.state.tab)
    if (this.props.match.path === "/mangopay-info") {
      this.setState({ tab: "mangopay_info" });
    }
  }

  setTabTitle = (tab) => {
    let title = this.props.intl.formatMessage({id: "User info page title", defaultMessage: "User info page title"})
    switch (tab) {
      case 'userinfo':
        title = this.props.intl.formatMessage({ id: 'User info page title', defaultMessage: 'User info page title' })
        break;
      case 'password':
        title = this.props.intl.formatMessage({ id: 'Change password page title', defaultMessage: 'Change password page title' })
        break;
      case 'cards':
        title = this.props.intl.formatMessage({ id: 'Credit cards page title', defaultMessage: 'Credit cards page title' })
        break;
      case 'mangopay_info':
        title = this.props.intl.formatMessage({ id: 'Mangopay info page title', defaultMessage: 'Mangopay info page title' })
        break;
      case 'address_of_user':
        title = this.props.intl.formatMessage({ id: 'Address-of-user page title', defaultMessage: 'Address-of-user page title' })
        break;
      case 'identification':
        title = this.props.intl.formatMessage({ id: 'Identification page title', defaultMessage: 'Identification page title' })
        break;
      case 'update_stripe_payment_methods':
        title = this.props.intl.formatMessage({ id: 'Update stripe payment page title', defaultMessage: 'Update stripe payment page title' })
        break;
    
      default:
        break;
    }
    document.title = title
  }

  handlerTabChanged = (event, tab) => {
    this.setState({ tab });
    this.props.history.push({ search: `?tab=${tab}` });
    this.setTabTitle(tab)
  };

  renderTab = () => {
    switch (this.state.tab) {
      case "userinfo":
        return <UserInfo />;
      case "password":
        return <ChangePasswordTab history={this.props.history} />;
      case "cards":
        return <CreditCards />;
      case "mangopay_info":
        return <CompleteMangopayInfo />;
      case "address_of_user":
        return <AddressOfUser />;
      case "identification":
        return <Identification />;
      case "update_stripe_payment_methods":
        return <UpdateStripePaymentMethod />;
      default:
        return <UserInfo />;
    }
  };

  render() {
    const { classes, user } = this.props;
    return (
      <div>
        <div
          className={`pt-12 pb-8 flex flex-1 flex-col items-center justify-center md:flex-row md:items-end ${classes.header}`}
        >
          <div className="flex flex-1 items-center justify-center md:flex-row md:justify-start gap-2">
            <Icon fontSize='default' className='md:mr-4'>portrait</Icon>
            <Typography className={classes.heading} variant="h4">
              {user.name
                ? user.name
                : user.store && user.store.name
                ? user.store.name
                : null}
            </Typography>
          </div>
          <div className="flex items-center justify-end" />
        </div>
        <Paper className={classes.paper}>
          <Tabs
            value={this.state.tab}
            onChange={this.handlerTabChanged}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            variant="scrollable"
            className="ml-0 lg:ml-10"
          >
            <Tab
              className="h-16"
              label={
                <IntlMessages
                  id="Informations utilisateur"
                  defaultMessage="Informations utilisateur"
                />
              }
              value="userinfo"
            />
            <Tab
              className="h-16"
              label={
                <IntlMessages id="Mot de passe" defaultMessage="Mot de passe" />
              }
              value="password"
            />
            {Purchase && !USE_STRIPE_PURSHACE && (
              <Tab
                className="h-16"
                label={
                  <IntlMessages
                    id="Mangopay Info"
                    defaultMessage="Mangopay Info"
                  />
                }
                value="mangopay_info"
              />
            )}
            {USE_CREDIT_CARDS && (
              <Tab
                className="h-16"
                label={
                  <IntlMessages
                    id="Cartes de crédit"
                    defaultMessage="Cartes de crédit"
                  />
                }
                value="cards"
              />
            )}
            {AddressOfUser && (
              <Tab
                className="h-16"
                label={
                  <IntlMessages id="Addresses" defaultMessage="Addresses" />
                }
                value="address_of_user"
              />
            )}
            <Tab
              className="h-16"
              label={
                <IntlMessages
                  id="Identification"
                  defaultMessage="Identification"
                />
              }
              value="identification"
            />
            {user?.stripe_extension?.default_payment_method && (
              <Tab
                className="h-16"
                label={
                  <IntlMessages
                    id="Update payement"
                    defaultMessage="Update payement"
                  />
                }
                value="update_stripe_payment_methods"
              />
            )}
          </Tabs>
        </Paper>
        <Box px="8px">
          {this.renderTab()}
        </Box>
      </div>
    );
  }
}

const ProfileComponent = connect((state) => {
  return {
    user: state.auth.user,
  };
})(Profile);

export default withStyles(styles)(injectIntl(ProfileComponent));
