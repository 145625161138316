import React, { useEffect } from "react"
import { withPopoverMui } from "react-platform-components/dist/components/PopoverContainer"
import { Button, Grid, Typography, Box, Paper, FormControlLabel, Checkbox } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import IntlMessages from "react-platform-components/dist/Translations/IntlMessages"
import { UpdateCreditCard } from "./UpdateCreditCard"
import { BASE_URL, SALABLE_POINT_UUID } from "../../../configs"
import _ from 'lodash'

const PaymentPointView = (props) => {
    const { selectedPoint } = props
    const [isConditionsChecked, handleConditionsChecked] = React.useState(false)

    const { cart, user, couponCart } = useSelector(({ secondaryShoppingCartReducer, auth }) => ({
        cart: secondaryShoppingCartReducer.cart,
        user: auth?.user,
        couponCart: secondaryShoppingCartReducer.couponCart,
    }))

    useEffect(() => {
        if (props.informationOfPacket) {
            props.setSelectedPoint(null)
            const cartItemPack = cart?.cart_items?.filter(
                (item) => item.salable_item_uuid !== SALABLE_POINT_UUID
                )
                // we use this for not update a pack if i open this same pack (for not apply the same request)
            const isSamePack = cart?.cart_items?.some(
                (item) => item.salable_item_uuid === props.packUUID
                )
            if(_.isEmpty(cartItemPack)){
            // we use this for delete old cartItemPack and create a new cartItemPack ( when i chose a pack)
            dispatch({
                type: "DELETE_SECONDARY_CART_ITEMS",
                ids: cart.cart_items.map((item) => item.id),
                onConfirm: () => {
                    dispatch({
                        type: "ADD_TO_SECONDARY_CART",
                        model: {
                            salable_item_class: "SalablePack",
                            salable_item_uuid: props.packUUID,
                            quantity: 1,
                        },
                    })
                }
            })
            } else if (!isSamePack) {
            // we use this for update cartItemPack if is existe
                dispatch({
                    type: "UPDATE_ITEM_OF_SECONDARY_CARTITEM_START",
                    id: cartItemPack[0].id,
                    model: {
                        salable_item_class: "SalablePack",
                        salable_item_uuid: props.packUUID,
                        quantity: 1,
                    },
                })
            }
        }
    }, [])

    const dispatch = useDispatch()
    const history = useHistory()
    const stripeFormRef = React.createRef()

    const handlePaymentByDefaultPaymentMethod = () => {
        // we use this for make payment by default payment method
        dispatch({
            type: "PURCHASE_POINT",
            onSuccess: ({ status }) => {
                if (status === "success") {
                    history.replace("/purchase-success")
                } else {
                    // case when we use sepa_debit payment method
                    history.replace("/purchase-pending")
                }
            },
        })
    }

    const informationOfRP = props.informationOfPacket || [
        { value: selectedPoint, label: "Quantite RP :" },
        { value: cart.total_price_with_coupon / selectedPoint, label: "Prix unitaire :" },
        { value: cart.total_price_with_coupon, label: "Net a Payer :" },
    ]

    return (
        <Box paddingTop={2} margin={2}>
            <Typography variant='h5' align='center'>
                <IntlMessages id='Paiement des RP' />
            </Typography>
            <Box overflow='hidden' style={{ padding: 10 }}>
                <UpdateCreditCard stripeFormRef={stripeFormRef} user={user} />
                <Grid
                    container
                    xs={12}
                    md={12}
                    justifyContent='space-between'
                    spacing={2}
                    style={{ marginTop: 10 }}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={6}>
                            {informationOfRP.map((item,index) => (
                                <Grid container item xs={12} style={{ padding: 10 }} key={index}>
                                    <Grid item xs={8}>
                                        <Typography>
                                            <IntlMessages id={item.label} />
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={4} justify='flex-end'>
                                        <strong className={props.informationOfPacket && index === 1 ? "line-through": ""}>
                                            {item.value} {index === 0 ? "RP" : <IntlMessages id="Euro" defaultMessage="Euro" />}
                                        </strong>
                                    </Grid>
                                </Grid>
                            ))}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {couponCart && (
                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                <Paper elevation={6}>
                                    <Grid container item xs={12} style={{ padding: 10 }}>
                                        <Grid item xs={8}>
                                            <Typography variant='overline'>
                                                <IntlMessages id={"Code Promo"} />
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={4} justify='flex-end'>
                                            <strong>{couponCart?.coupon?.token} <IntlMessages id="Euro" defaultMessage="Euro" /></strong>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        <Grid container item xs={12} style={{ marginTop: 16 }}>
                            <Grid item xs={8}>
                                <Paper elevation={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='accept CGU'
                                                required
                                                checked={isConditionsChecked}
                                                onChange={(event) =>
                                                    handleConditionsChecked(event.target.checked)
                                                }
                                                style={{ marginLeft: 10 }}
                                            />
                                        }
                                        label={
                                            <a
                                                target='_blank'
                                                href={`${BASE_URL}${props.intl.formatMessage({
                                                    id: "cgu_url",
                                                    defaultMessage: "cgu_url",
                                                })}`}
                                                style={{ fontSize: 13 }}
                                                rel='noopener noreferrer'>
                                                <IntlMessages id="J'accepte les CGV" />
                                            </a>
                                        }
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justifyContent='flex-end' spacing={4}>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            disabled={!isConditionsChecked}
                                            onClick={() => {
                                                if (user?.stripe_extension?.default_payment_method) {
                                                    handlePaymentByDefaultPaymentMethod()
                                                } else {
                                                    stripeFormRef.current &&
                                                        stripeFormRef.current.handleSubmit()
                                                }
                                            }}>
                                            <IntlMessages id='Payer' />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export const PaymentPoint = withPopoverMui(
    PaymentPointView,
    (props) =>
        props.informationOfPacket ? (
            <Button
                className='w-full'
                variant='outlined'
                color='secondary'
                size='small'
                onClick={props.onClick}>
                <IntlMessages id="Buy /PurchaseOfPoint" />
            </Button>
        ) : (
            <Button
                onClick={props.onClick}
                disabled={props.disabelCheckout}
                color='primary'
                variant='contained'>
                <IntlMessages id='Paiement' />
            </Button>
        ),
    { width: "700px" }
)
