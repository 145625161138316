import React from "react"
import { Grid, useMediaQuery } from "@material-ui/core"
import TutorialDialog from "react-platform-components/dist/Hints/TutorialDialog"
import CategoryOfProductSingleLevelField from 'react-platform-category-of-product/dist/CategoryOfProduct/CategoryOfProductSingleLevelField';
import { SpecificitiesOfProductFields } from "react-platform-specificity-of-product/dist/SpecificityOfProductWithSections/SpecificitiesOfProductFields"
import { useSelector } from "react-redux"
import { PriceField, ImagesField, DescriptionField, NameField } from "react-platform-productsalable/dist/CreateProductSalable/forms/steps/InformationStep"
import { useFormikContext } from "formik"

const fieldsDependToValueOfSpecificities = []

export const conditionalSpecificities = []

export const InformationsStepFields = (props) => {
    const isMd = useMediaQuery("(max-width: 810px)", { noSsr: true })
    const { values, submitCount } = useFormikContext()
    const state = useSelector((state) => ({
        product: state.ProductReducer.product,
        categories: state.categoryOfProductReducer.categories,
    }))
    if (isMd)
        return (
            <Grid item xs={12} md={12} spacing={2}>
                <Grid item xs={12} md={12}>
                    <NameField />
                </Grid>
                <Grid item xs={12} md={12}>
                    <DescriptionField />
                </Grid>
                <Grid item xs={12} md={12}>
                    <PriceField />
                </Grid>

                <Grid item xs={12} md={12} style={{ marginTop: 15, marginBottom: 20 }}>
                    <CategoryOfProductSingleLevelField
                        categories={state.categories}
                    />
                </Grid>

                {SpecificitiesOfProductFields && (
                    <SpecificitiesOfProductFields
                        {...props}
                        submitCount={submitCount}
                        isMd={isMd}
                        product={state.product}
                        fieldsDependToValueOfSpecificities={fieldsDependToValueOfSpecificities}
                        conditionalSpecificities={conditionalSpecificities}
                    />
                )}
                <Grid container item xs={12} md={12} style={{ marginTop: 20 }}>
                    <Grid item xs={12} md={12}>
                        <TutorialDialog
                            title='Title_photos_tutorial_dialog'
                            showText='Voir astuces'
                            openText="Ajoute jusqu'à 5 photos."
                            type='create_products_images'
                        />
                        <ImagesField values={values} {...props} />
                    </Grid>
                </Grid>
            </Grid>
        )
    return (
        <>
            <Grid item xs={12} md={12}>
                <TutorialDialog
                    title='Title_photos_tutorial_dialog'
                    showText='Voir astuces'
                    openText="Ajoute jusqu'à 5 photos."
                    type='create_products_images'
                />
                <ImagesField values={values} {...props} />
            </Grid>
            <Grid container item xs={6} md={6}>
                <Grid item xs={12} md={12}>
                    <NameField />
                </Grid>
                <Grid item xs={12} md={12}>
                    <PriceField />
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: "12px", marginBottom: 12 }}>
                    <CategoryOfProductSingleLevelField
                        categories={state.categories}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={6} md={6}>
                <Grid item xs={12} md={12}>
                    <DescriptionField rows={9} />
                </Grid>
            </Grid>
            {SpecificitiesOfProductFields && (
                <Grid item xs={12} md={12}>
                    <SpecificitiesOfProductFields
                        {...props}
                        product={state.product}
                        submitCount={submitCount}
                        isMd={isMd}
                        fieldsDependToValueOfSpecificities={fieldsDependToValueOfSpecificities}
                        conditionalSpecificities={conditionalSpecificities}
                    />
                </Grid>
            )}
        </>
    )
}
