import React from "react";
import axios from "react-platform-components/dist/axios";
import { handleErrors } from "react-platform-components/dist/Utils";
import { all, put, takeEvery } from "redux-saga/effects";
import {
  BASE_API_URL,
  BASE_URL,
} from "react-platform-shoppingcart/dist/config";
import { IntlMessages } from "react-platform-components/dist/Translations";

export function* makePurchase(action) {
  yield put({ type: "SECONDARY_STRIPE_PURCHASE_START" });
  try {
    // Get purchase
    const purchaseResponse = yield axios.post(
      `${BASE_API_URL}secondary-purchases`
    );
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    const params = action.params || {};

    let paymentResponse;
    // If there is a coupon in the action use it to init the transaction
    // if not init transaction whithout it
    paymentResponse = yield axios.get(
      `${BASE_URL}stripe/init_payment_and_transaction/${purchaseResponse.data.id}`,
      // Have to add `data: {}` for axios to send Content-Type
      { headers, data: {}, params }
    );

    if (paymentResponse.data.success) {
      yield put({ type: "SECONDARY_STRIPE_PURCHASE_SUCCESS" });
      // we added this to refresh the user in the localstorage because some times when we purchase a stripe subscription
      // also we update the user rights and we need them in the frontend side to be updated to use them immediately.
      yield put({ type: "FETCH_USER" });
      yield put({
        type: "REDIRECT",
        to: "/purchase-success",
        history: action.history,
      });
    } else if (paymentResponse.data.url) {
      // handle 3D secure case here
      window.location.href = paymentResponse.data.url;
    } else if (paymentResponse.data.processing) {
      // when we use sepa_debit payment method the payment it take some days for handle it
      yield put({ type: "FETCH_USER" });
      yield put({
        type: "REDIRECT",
        to: "/purchase-pending",
        history: action.history,
      });
    }
  } catch (error) {
    yield put({ type: "SECONDARY_STRIPE_PURCHASE_ERROR" });
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

// Fetch user cart
export function* fethSecondaryCart(action) {
  yield put({ type: "FETCH_SECONDARY_CART_START" });
  try {
    const params = action.params || {};
    const response = yield axios.get(`${BASE_API_URL}secondary-carts`, {
      params,
    });
    yield put({
      type: "FETCH_SECONDARY_CART_SUCCESS",
      payload: response.data[0],
    });
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

// Add item to user cart and show success message
export function* addItemToSecondaryCart(action) {
  yield put({ type: "ADD_TO_SECONDARY_CART_START" });
  try {
    yield axios.post(`${BASE_API_URL}secondary-cartitems`, action.model);
    yield put({ type: "ADD_TO_SECONDARY_CART_SUCCESS" });
    yield put({
      type: "HANDLE_SUCCESS_MESSAGE",
      message: <IntlMessages id="Success!" />,
    });
    yield put({
      type: "FETCH_SECONDARY_CART",
      params: { _embed: ["cart_items.salable_item"] },
    });
    if (action.redirectTo) {
      yield put({
        type: "REDIRECT",
        to: action.redirectTo,
        history: action.history,
      });
    }
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

// Delete item from user cart and show success message
export function* deleteItemFromSecondaryCart(action) {
  yield put({ type: "DELETE_ITEM_FROM_SECONDARY_CART_START" });
  try {
    yield axios.delete(`${BASE_API_URL}secondary-cartitems/${action.id}`);
    yield put({ type: "DELETE_ITEM_FROM_SECONDARY_CART_SUCCESS" });
    yield put({
      type: "HANDLE_SUCCESS_MESSAGE",
      message: <IntlMessages id="Success!" />,
    });
    yield put({
      type: "FETCH_SECONDARY_CART",
      params: { _embed: ["cart_items.salable_item"] },
    });
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

export function* addCouponSecondaryCartItem(action) {
  yield put({ type: "ADD_COUPON_SECONDARY_CARTITEM_START" });
  try {
    yield axios.post(`${BASE_API_URL}coupon_cartitems`, action.model);
    yield put({ type: "ADD_COUPON_SECONDARY_CARTITEM_SUCCESS" });
    yield put({
      type: "HANDLE_SUCCESS_MESSAGE",
      message: <IntlMessages id="Success!" />,
    });
    action.onSuccess && action.onSuccess();
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

function* fetchLinkedCouponToSecondaryCart() {
  try {
    const response = yield axios.get(`${BASE_API_URL}eshop-coupon-carts`);
    yield put({
      type: "FETCH_LINKED_COUPON_TO_SECONDARY_CART_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

function* linkCouponToSecondaryCart(action) {
  try {
    yield put({ type: "LINK_COUPON_TO_SECONDARY_CART_START" });
    const response = yield axios.post(
      `${BASE_API_URL}eshop-coupon-carts`,
      action.model
    );
    yield put({
      type: "LINK_COUPON_TO_SECONDARY_CART_SUCCESS",
      payload: response.data,
    });
    yield put({
      type: "FETCH_SECONDARY_CART",
      params: { _embed: ["cart_items.salable_item"] },
    });
    action.onSuccess && action.onSuccess();
  } catch (error) {
    yield put({ type: "LINK_COUPON_TO_SECONDARY_CART_ERROR" });
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

function* removeLinkedCouponToSecondaryCart(action) {
  try {
    yield axios.delete(`${BASE_API_URL}eshop-coupon-carts/${action.id}`);
    yield put({ type: "REMOVE_LINKED_COUPON_TO_SECONDARY_CART_SUCCESS" });
    yield put({
      type: "FETCH_SECONDARY_CART",
      params: { _embed: ["cart_items.salable_item"] },
    });
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

// Update cart item
export function* updateItemOfSecondaryCartItem(action) {
  try {
    yield axios.patch(
      `${BASE_API_URL}secondary-cartitems/${action.id}`,
      action.model
    );
    yield put({ type: "UPDATE_ITEM_OF_SECONDARY_CARTITEM_SUCCESS" });
    yield put({
      type: "HANDLE_SUCCESS_MESSAGE",
      message: <IntlMessages id="Success!" defaultMessage="Success!" />,
    });
    yield put({
      type: "FETCH_SECONDARY_CART",
      params: { _embed: ["cart_items.salable_item"] },
    });
  } catch (error) {
    yield put({ type: "UPDATE_ITEM_OF_SECONDARY_CARTITEM_ERROR" });
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

function* clearSecondaryCart(action) {
  try {
    const cart_items = action?.cart?.cart_items;
    if (cart_items) {
      yield axios.all(
        cart_items.map((cart_item) => {
          return axios.delete(
            `${BASE_API_URL}secondary-cartitems/${cart_item.id}`
          );
        })
      );
      yield put({
        type: "FETCH_SECONDARY_CART",
        params: { _embed: ["cart_items.salable_item"] },
      });
    }
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

function* deleteSecondaryCartItems(action) {
  try {
    yield put({ type: "DELETE_SECONDARY_CART_ITEMS_START_" });
    if (action.ids) {
      yield axios.all(
        action?.ids.map((id) => {
          return axios.delete(`${BASE_API_URL}secondary-cartitems/${id}`);
        })
      );

      yield put({ type: "DELETE_SECONDARY_CART_ITEMS_SUCCESS_" });
      action.onConfirm && action.onConfirm()
    }
  } catch (error) {
    yield put({ type: "DELETE_SECONDARY_CART_ITEMS_ERROR_" });
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

export function* secondaryShoppingCartSaga() {
  yield all([
    takeEvery("FETCH_SECONDARY_CART", fethSecondaryCart),
    takeEvery("ADD_TO_SECONDARY_CART", addItemToSecondaryCart),
    takeEvery("DELETE_ITEM_FROM_SECONDARY_CART", deleteItemFromSecondaryCart),
    takeEvery("ADD_COUPON_SECONDARY_CARTITEM", addCouponSecondaryCartItem),
    takeEvery("LINK_COUPON_TO_SECONDARY_CART", linkCouponToSecondaryCart),
    takeEvery(
      "REMOVE_LINKED_COUPON_TO_SECONDARY_CART",
      removeLinkedCouponToSecondaryCart
    ),
    takeEvery(
      "FETCH_LINKED_COUPON_TO_SECONDARY_CART",
      fetchLinkedCouponToSecondaryCart
    ),
    takeEvery(
      "UPDATE_ITEM_OF_SECONDARY_CARTITEM_START",
      updateItemOfSecondaryCartItem
    ),
    takeEvery("SECONDARY_STRIPE_PURCHASE", makePurchase),
    takeEvery("CLEAR_SECONDARY_CART", clearSecondaryCart),
    takeEvery("DELETE_SECONDARY_CART_ITEMS", deleteSecondaryCartItems),
  ]);
}
