import { Card, CardContent, CardHeader, Typography, Grid } from "@material-ui/core"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import DOMPurify from "dompurify"
import { PaymentPoint } from "./PaymentPoint"
import { LoadingPage } from "react-platform-components/dist/components/LoadingPage"
import { IntlMessages } from "react-platform-components/dist/Translations";
import _ from 'lodash'

export const PacketList = (props) => {
    const dispatch = useDispatch()

    const { packets, inProgress } = useSelector(({ purchasePointReducer }) => ({
        packets: purchasePointReducer.packets,
        inProgress: purchasePointReducer.fetchPacketsInProgress,
    }))

    useEffect(() => {
        _.isEmpty(packets) && dispatch({ type: "FETCH_PACKETS" })
    }, [dispatch, packets])

    if (inProgress) return <LoadingPage />
    return (
        <div className='flex justify-center flex-wrap'>
            {packets?.length > 0 &&
                packets.map((item, index) => {
                    const { name, price, roadpoints_number, crossed_out_price, currency, description } = item
                    const informationOfPacket = [
                        { value: item.roadpoints_number, label: "Quantite RP :" },
                        { value: item.crossed_out_price, label: "Prix Barre :" },
                        { value: item.price, label: "Net a Payer :" },
                    ]
                    return (
                        <Grid key={index} lg={3} md={6} sm={6} xs={12} className='flex p-4'>
                            <Card elevation={4} className='w-full relative'>
                                <CardHeader
                                    title={
                                        <Typography color='primary' variant='h5' align='center'>
                                            {name}
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    {description && (
                                        <div
                                            className='flex justify-center items-center mb-2'
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(description),
                                            }}
                                        />
                                    )}
                                    <div className='flex justify-center items-center mb-2'>
                                        <Typography variant='h5'>
                                            <strong>{roadpoints_number} <IntlMessages id='Roadpoint' defaultMes1sage='Roadpoint' /></strong>
                                        </Typography>
                                    </div>

                                    {!!crossed_out_price && (
                                        <div className='flex justify-center items-center mb-2'>
                                            <Typography variant='h5'>
                                                <del>
                                                    {crossed_out_price} {currency === "EUR" ? "€" : "$"}
                                                </del>
                                            </Typography>
                                        </div>
                                    )}
                                    <div className='flex justify-center items-center mb-6'>
                                        <Typography variant='h5'>
                                            <strong>
                                                {price} {currency === "EUR" ? "€" : "$"}
                                            </strong>
                                        </Typography>
                                    </div>
                                    <div className='flex justify-center items-center pb-2 pr-8 absolute bottom-0 w-full'>
                                        <PaymentPoint
                                            {...props}
                                            informationOfPacket={informationOfPacket}
                                            packUUID={item.uuid}
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })}
        </div>
    )
}
