import { all, put, takeEvery } from "redux-saga/effects";
import axios from "react-platform-components/dist/axios";
import { handleErrors } from "react-platform-components/dist/Utils";
import { BASE_API_URL } from "../../configs";

export function* fetchCaution(action) {
  yield put({ type: "FETCH_CAUTION_START" });
  try {
    const params = action.params || {};
    const response = yield axios.get(`${BASE_API_URL}cautions`, { params });
    yield put({ type: "FETCH_CAUTION_SUCCESS", payload: response.data });
  } catch (error) {
    yield put({ type: "HANDLE_ERROR", error: handleErrors(error) });
  }
}

export function* cautionSaga() {
  yield all([takeEvery("FETCH_CAUTION", fetchCaution)]);
}
