import React from "react"
import { CreateProductSalableForm } from "react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalableForm"
import { InformationsStepFields } from "./steps/InformationsStep"
import { AddressStepFields } from "./steps/AddressStep"
import { getFormInitialValuesKeys as defaultGetFormInitialValuesKeys } from "react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalableForm"

export const getFormInitialValuesKeys = () => {
    const initialValuesKeys = [...defaultGetFormInitialValuesKeys(), "address"]
    return initialValuesKeys
}

export const CustomCreateProductSalableForm = () => {
    return (
        <CreateProductSalableForm
            getStepsContent={() => [
                { label: "Informations step", component: InformationsStepFields },
                { label: "Address step", component: AddressStepFields },
            ]}
            getFormInitialValuesKeys={getFormInitialValuesKeys}
            defaultValue = {{ price : 100 }}
        />
    )
}
