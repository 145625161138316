const defaultState = {
    packets: [],
    inProgress: false,
    fetchPacketsInProgress: false
}

export default function purchasePointReducer(state = defaultState, action) {
  switch (action.type) {
    case "PURCHASE_POINT_START": {
      return { ...state, inProgress: true };
    }
    case "PURCHASE_POINT_SUCCESS": {
      return { ...state, inProgress: false };
    }
    case "PURCHASE_POINT_ERROR": {
      return { ...state, inProgress: false };
    }
    case "FETCH_PACKETS_START": {
      return { ...state, fetchPacketsInProgress: true };
    }
    case "FETCH_PACKETS_SUCCESS": {
      return { ...state, fetchPacketsInProgress: false ,packets: action.payload }
    }
    case "FETCH_PACKETS_ERROR": {
      return { ...state, fetchPacketsInProgress: false };
    }

    default:
      return state;
  }
}
